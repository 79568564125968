import React from "react";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadDeliveryMen } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import DeleteIcon from "@material-ui/icons/Delete";
import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";

import { lighten } from "@material-ui/core/styles/colorManipulator";

const style = theme => ({
  container: {
    flexGrow: 1
  },
  card: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3
  }
});


function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "NOM DE FAMILLE"
  },
  { id: "last_name", numeric: true, disablePadding: false, label: "PRÉNOM" },
  { id: "tel", numeric: true, disablePadding: false, label: "TÉLÉPHONE" },
  { id: "is_on_work", numeric: true, disablePadding: false, label: "STATUT" },
  { id: "", numeric: true, disablePadding: false, label: "NOMBRE DE COMMANDE" },
  { id: "", numeric: true, disablePadding: false, label: " " },
  { id: "", numeric: true, disablePadding: false, label: " " },
  { id: "", numeric: true, disablePadding: false, label: " " },
  { id: "", numeric: true, disablePadding: false, label: " " }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
        textTransform:"uppercase",
    },
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class EnhancedTableToolbar extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle">
                    <span style={{ fontFamily: "Montserrat, sans-serif", }} >  Liste des livreurs </span>

                    </Typography>
                </div>
                <div className={classes.spacer}/>
                <div className={classes.actions}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
                                <FilterListIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});

class EnhancedTable extends React.Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 25
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {
    this.props.loadDeliveryMen();

    /*
        window.onscroll =  () => {
            if (Math.abs(window.innerHeight - document.body.offsetHeight) > 50) {
                if ((window.innerHeight + window.scrollY + 50) >= document.body.offsetHeight) {
                    this.setState({...this.state, openFab: false});
                }else {
                    this.setState({...this.state, openFab: true});
                }
            }
        };
        */
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    const deliverymen = this.props.deliverymen.data;
    const { classes, theme } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };
    return (
      <div>
        <Typography color="textSecondary" variant="headline" />
        {this.props.deliverymen.loading &&
          this.props.deliverymen.data.length == 0 && (
            <div className={classes.progressContainer}>
              <Fade>
                <Typography variant="subheading" color="textSecondary">
                  Chargement...
                </Typography>
              </Fade>
            </div>
          )}
        {this.props.deliverymen.data.length > 0 && (
          <Paper className={classes.root}>
            <EnhancedTableToolbar
              refresh={this.props.loadDeliveryMen}
              toggleFilter={this.toggleFilter}
              isFilterShowen={this.state.showFilter}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true} timeout={200}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                  </div>
                
              )}
              <Table className={ccolorlasses.table} aria-labelledby="tableTitle">
                <EnhancedTableHeacolord
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={this.handleRequestSort}
                  rowCount={deliverymen.length}
                />
                <TableBody>
                  {this.props.deliverymen.data
                    /*.sort(getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)*/
                                    .filter(item => this.state.filterText.trim() == "" ? true : item.profile.email.indexOf(this.state.filterText) > -1)
                                    .map((deliveryman, i) => {
                                        return (
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={deliveryman.id}
                                                
                                            >
                                                <TableCell component="th" scope="row" padding="checkbox" style={{ textTransform:"uppercase", color:"#28acb3", fontWeight:"bold", fontFamily: "Montserrat, sans-serif", }} numeric>
                                                
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                       {capitalizeFirstLetter(deliveryman.last_name)}
                                                    </div>
                                                </TableCell>
                                                <TableCell style={{ textAlign:"left", backgroundColor:"#efefef", fontFamily: "Montserrat, sans-serif", }} numeric>
                                                {capitalizeFirstLetter(deliveryman.first_name)}
                                                </TableCell>
                                               
                                                <TableCell numeric>
                                                <div  style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        }}>
                                                      {capitalizeFirstLetter(deliveryman.tel)}
                                                </div>
                                                    
                                                </TableCell>

                                                <TableCell style={{ textAlign:"left", backgroundColor:"#efefef", fontFamily: "Montserrat, sans-serif", }} component="th" scope="row" padding="checkbox" numeric>
                                                <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16, TextAlign :"left",

                                                        
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {capitalizeFirstLetter(deliveryman.is_on_work)} 
                                                </div>
                                                </TableCell>
                                                <TableCell>

                                                    
                                                    </TableCell>
                                                <TableCell>
                                                
                                                <Button variant="contained" style={{ backgroundColor:"rgba(3, 180, 115)", backgroundImage:"linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)", color:"rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", }}  onClick={() => this.props.history.push(`/candidates/commands/content?id= ${deliveryman.id}`)}>
                                                
                                                <div  style={{
                                                        position: "relative",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        
                                                        verticalAlign: "middle",
                                                       
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        fontFamily: "Montserrat, sans-serif",
                                                        
                                                        }}>
                                                            COMMANDES
                                                </div>
                                               </Button>
                                            </TableCell>

                                            <TableCell numeric>
                                            <IconButton className={classes.button} aria-label="AFFICHER" onClick={() => this.props.history.push(`/candidates/content?id= ${deliveryman.id}`)}> 
                                            
                                            <Eye style={{color:"#28abb3"}} viewBox="0 0 24 24"/>
                                            
                                                    <div  style={{
                                                        position: "relative",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        
                                                        verticalAlign: "middle",
                                                       
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        fontFamily: "Montserrat, sans-serif",
                                                        
                                                        }}>
                                                        
                                                    AFFICHER
                                                    </div>
                                                 </IconButton>
                                                </TableCell>
                                                <TableCell numeric> 
                                                <IconButton  aria-label="EDITER"  onClick={() => this.props.history.push(`/candidates/edit/${deliveryman.id}`)} >
                                                <EditIcon  style={{color:"#28abb3"}}/>
                                                    <div  style={{
                                                        position: "relative",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        
                                                        verticalAlign: "middle",
                                                       
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        fontFamily: "Montserrat, sans-serif",
                                                        
                                                        }}>
                                                        
                                                        ÉDITER
                                                    </div>
                                                 </IconButton>
                                                 </TableCell>



                                                 <TableCell numeric> 
                                                <IconButton className={classes.button} aria-label="DÉSACTIVER"  onClick={() => this.props.history.push(`/posts/${deliveryman.id}`)} >
                                                 <DeleteIcon  style={{color:"#28abb3"}}/>
                                                    <div  style={{
                                                        position: "relative",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                       
                                                        
                                                        verticalAlign: "middle",
                                                       
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        fontFamily: "Montserrat, sans-serif",
                                                        
                                                        }}>
                                                        
                                                        DÉSACTIVER
                                                    </div>
                                                 </IconButton>
                                                 </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    

                    <TablePagination
                        component="div"
                        count={deliverymen.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    </div>
                    
                </Paper>
                
            
        )}
        <Zoom
          in={this.state.openFab}
          timeout={transitionDuration}
          style={{
            position: "fixed",
            right: 16,
            bottom: 16,
            transitionDelay: transitionDuration.exit,
            backgroundColor: "rgba(30,180,115)",
            color: "rgba(255,255,255)"
          }}
        >
          <Button
            variant="fab"
            style={{backgroundColor: "rgb(47, 194, 157)"}} 
            onClick={() => this.props.history.push("/candidates/new")}
          >
            <AddIcon />
          </Button>
        </Zoom>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ deliverymen }) => ({ deliverymen });

const mapDispatchToProps = dispatch => ({
  loadDeliveryMen: bindActionCreators(loadDeliveryMen, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles, { withTheme: true })(EnhancedTable));
