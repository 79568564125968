export const AuthTypes = {
    REQUEST_LOGIN: "REQUEST_LOGIN",
    REQUEST_LOGIN_SUCCESS: "REQUEST_LOGIN_SUCCESS",
    REQUEST_LOGIN_ERROR: "REQUEST_LOGIN_ERROR",
    REQUEST_LOGIN_CLEAR: "REQUEST_LOGIN_CLEAR",

    REQUEST_CHECK_TOKEN: "REQUEST_CHECK_TOKEN",
    REQUEST_CHECK_TOKEN_SUCCESS: "REQUEST_CHECK_TOKEN_SUCCESS",
    REQUEST_CHECK_TOKEN_ERROR: "REQUEST_CHECK_TOKEN_ERROR",
    REQUEST_CHECK_TOKEN_CLEAR: "REQUEST_CHECK_TOKEN_CLEAR",

    REQUEST_LOGOUT: "REQUEST_LOGOUT",
    REQUEST_LOGOUT_COMPLETE: "REQUEST_LOGOUT_COMPLETE",
};