import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadCommands, loadCommand } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";


import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import UndoIcon from "@material-ui/icons/Undo";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ListSubheader from '@material-ui/core/ListSubheader';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import MapComponent from "./MapComponent";
import admins from './Admins';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
import Modal from '@material-ui/core/Modal';
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
/******LISTSTYLES************************************* */
const liststyles = theme => ({
    root: {
        width: '100%',
        maxWidth: 360,
        backgroundColor: theme.palette.background.paper,
    },
});






function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function getCmdState(state) {
    let st = ""
    switch (state) {
        case "PENDING": st = "En Attente"; break;
        case "REVOKED": st = "Annulée"; break;
        case "APPROVED": st = "Acceptée"; break;
        case "TO_PAY": st = "Attente de paiement"; break;
        case "PAID": st = "Payée"; break;
        case "DELIVERING": st = "En cours de livraison"; break;
        case "DELIVERED": st = "Livrée"; break;
        case "CANCELED": st = "Annulée"; break;
        case "VOIDED": st = "--"; break;

    }
    return st;
}
const rows = [
    { id: "name", numeric: false, disablePadding: true, label: "NOM" },
    { id: "website", numeric: true, disablePadding: false, label: "SITE WEB" },
    { id: "tel", numeric: true, disablePadding: false, label: "TELEPHONE" },
    { id: "city", numeric: true, disablePadding: false, label: "VILLE" },
    { id: "", numeric: true, disablePadding: false, label: "NB DE COMMANDE" },
    { id: "", numeric: true, disablePadding: false, label: "COMMANDES " },
    { id: "", numeric: true, disablePadding: false, label: "AFFICHER " },
    { id: "", numeric: true, disablePadding: false, label: "EDITER " },
    { id: "", numeric: true, disablePadding: false, label: "DÉSACTIVER " },
];
function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                numeric={row.numeric}
                                padding={i == 0 ? "checkbox" : (row.disablePadding ? "none" : "default")}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    boxFlex: {
        display: "flex",
    },

    '@media screen and (max-width: 991px)': {
        firstTitle: {
            fontSize: "22px !important",
            textAlign: "center !important",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        boxFlex: {
            flexDirection: "column",
        },
        table: {
            padding: "0px !important",

        },

    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
});
// Button Style //
const buttonstyles = theme => ({


    button: {
        margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
    },
    iconSmall: {
        fontSize: 20,
    },
    input: {
        display: 'none',
    },

});

class EnhancedTableToolbar extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { classes, command } = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >

                <div className={classes.title}>
                    <Typography color="title" variant="tableTitle">
                        <span style={{ fontFamily: "Montserrat, sans-serif", fontSize: 18 }} > COMMANDE N° <strong> {command.num}</strong></span>
                    </Typography>
                </div>

                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <div style={{ display: "flex", flexDirection: "row" }}>


                        {
                            command.refunded != true && (command.state == "REVOKED" || command.state == "CANCELLED") && (
                                <Tooltip title="Remboursement">
                                    <IconButton aria-label="Remboursement" onClick={() => this.props.openModal()}>
                                        <UndoIcon />
                                    </IconButton>
                                </Tooltip>
                            )
                        }
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    boxFlex: {
        display: "flex",
    },
    lit: {
        marginBottom: "10px",
        marginTop: "10px;"
    },
    '@media screen and (max-width: 991px)': {
        firstTitle: {
            fontSize: "19px !important",
            textAlign: "center !important",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        boxFlex: {
            flexDirection: "column",
        },
        lit: {
            fontSize: "14px",
            marginBottom: "10px",
            marginTop: "10px;"
        },

        titi: {
            padding: "0 !important"
        },
    },
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    colorSwitchBase: {

    },
    colorBar: {
    },
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500],
            },
        },
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
});
/***************************************************Page PHARMACY************************************************* */
const REFUND_COMMAND_MUTATION = gql`
mutation refundCommand($id: ID!){
    refundCommand(id:$id)
}
`;
class Command extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };


    constructor() {
        super();
        this.state = {
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            command: null,
            rowsPerPage: 5,
        };
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
    }
    openModal = () => {
        this.setState({ open: true });
    };

    handleClose = () => {
        this.setState({ open: false });
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({ showFilter: !this.state.showFilter, filterText: "" });
        } else {
            this.setState({ showFilter: !this.state.showFilter });
        }
    };

    handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

    componentDidMount() {
       
        this.props.loadCommand(this.props.history.location.search.split("=")[1]);

        setTimeout(() => {
            this.setState({ command: this.props.commands.command });
        }, 500)


    }

    componentWillUnmount() {
        window.onscroll = null;
    }


    render() {
        const command = this.state.command;
        const { classes, theme } = this.props;
     
        return (

            <div>
                <NotificationContainer />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.open}
                    onClose={this.handleClose}

                >
                    <div style={getModalStyle()} className={classes.paper}>

                        <Typography variant="title" id="simple-modal-description" style={{ marginBottom: "30px" }}>
                            Êtes-vous sûr de vouloir rembourser cette commande ?
                       </Typography>
                        <div style={{ marginLeft: 10 }}>

                            <Button color="default" className={classes.button} onClick={() => {
                                this.props
                                    .refundCommande({
                                        variables: {
                                            id: command.id
                                        }
                                    })
                                    .then((response) => {
                                     
                                        if (response && response.data && response.data.refundCommand) {
                                            NotificationManager.success("La commande est remboursée avec succès");
                                            setTimeout(() => {
                                                this.props.history.push({ pathname: '/commands', state: { refetch: true } })
                                            }, 2000)
                                        }
                                        else {
                                            NotificationManager.error("La commande est déjà remboursée");
                                        }

                                        this.handleClose();
                                    })
                                    .catch(error => {
                                        NotificationManager.error("Une erreur est survenue");
                                    });
                            }
                            }
                            >
                                Rembourser
                 </Button>
                            <Button color="secondary" className={classes.button} onClick={() => {
                                this.handleClose()
                            }}>
                                Annuler
                 </Button>
                        </div>

                    </div>
                </Modal>

                {this.state.command && this.state.command == null &&
                    <div className={classes.progressContainer}>
                        <Fade in timeout={300}>
                            <Typography variant="subheading" color="textSecondary">
                                Chargement...
                                   </Typography>
                        </Fade>
                    </div>
                }
                {this.state.command &&
                    <Paper className={classes.root}>

                        <EnhancedTableToolbar refresh={this.props.loadCommands} toggleFilter={this.toggleFilter}
                            openModal={this.openModal}
                            command={command}
                            isFilterShowen={this.state.showFilter} />
                        <div className={classes.tableWrapper}>

                            {this.state.showFilter &&
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>

                                    <Zoom in={true}
                                        timeout={200}>
                                        <TextField style={{ marginRight: 20 }}
                                            placeholder={"Chercher..."}
                                            autoFocus
                                            value={this.state.filterText}
                                            onChange={this.handleFilterChange}
                                        />
                                    </Zoom>
                                </div>
                            }



                        </div>


                    </Paper>
                }
                {this.state.command &&

                            <div className={classes.root}>

                                <Typography color="textSecondary" variant="headline">
                                    <div style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",

                                    }}>



                                    </div>

                                </Typography>
                                <Card style={{ marginBottom: 20, backgroundColor: "#f3f6f759" }}>

                                    <CardContent>
                                        <Typography color="textSecondary" variant="headline" className={classNames(classes.firstTitle)} >
                                            Résumé de la commande
                        </Typography>



                                        <div >
                                            <table className="table" className={classNames(classes.titi)}>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Numéro de commande :</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} ><span style={{
                                                        textTransform: "uppercase",
                                                        color: "#28acb3",
                                                        fontWeight: "bold",
                                                        textAlign: "left",
                                                        fontFamily: "Montserrat, sans-serif",

                                                    }} >{command.num}</span></Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Date de commande :</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{moment(command.createdAt).format("LLL")}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Statut :</Typography></td>
                                                    <td style={{
                                                        textTransform: "uppercase",
                                                        color: "#28acb3",
                                                        fontWeight: "bold",
                                                        textAlign: "left",
                                                        fontFamily: "Montserrat, sans-serif",

                                                    }} className={classNames(classes.lit)} >  <Typography color="title" variant="tableTitle"> {getCmdState(command.state)}</Typography></td>
                                                </tr>
                                                {
                                                    (command.state == "REVOKED" || command.state == "CANCELLED") && (
                                                        <tr>
                                                            <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >remboursée :</Typography></td>
                                                            <td style={{
                                                                textTransform: "uppercase",
                                                                color: "#28acb3",
                                                                fontWeight: "bold",
                                                                textAlign: "left",
                                                                fontFamily: "Montserrat, sans-serif",

                                                            }} className={classNames(classes.lit)} >  <Typography color="title" variant="tableTitle"> {command.refunded ? "Oui" : "Non"}</Typography></td>
                                                        </tr>
                                                    )
                                                }
                                                {/* {
                                (command.state=="REVOKED"||command.state=="CANCELLED")&&(
                                    <tr>
                                <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Raison du refus :</Typography></td>
                                <td  className={classNames(classes.lit)} >  <Typography color="title" variant="tableTitle"> {command.reject_description}</Typography></td>
                            </tr>
                                )
                            } */}
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Reste à charge:</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{command.to_pay != null ? command.to_pay + '€' : (command.state == 'PENDING' || command.state == 'APPROVED' ? '' : 'Aucun')}</Typography></td>
                                                </tr>
                                            </table>
                                        </div>
                                    </CardContent>

                                </Card>

                                <Card style={{ marginBottom: 20, backgroundColor: "#f3f6f759" }}>

                                    <CardContent>
                                        <Typography color="textSecondary" variant="headline" className={classNames(classes.firstTitle)} >
                                            Informations de la livraison
                        </Typography>
                                        <div   >
                                            <table className="table" className={classNames(classes.titi)} >
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Mode de livraison :</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{command.delivery_mode == 'FREE' ? 'Click & Collect' : (command.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée')}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Date de livraison:</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{command.delivery_date ? moment(command.delivery_date).format("LL") : "---"}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Nom du Patient:</Typography></td>
                                                    <td><span style={{
                                                        textTransform: "uppercase",
                                                        color: "#28acb3",
                                                        fontWeight: "bold",
                                                        textAlign: "left",
                                                        fontFamily: "Montserrat, sans-serif",
                                                        cursor: "pointer"
                                                    }} className={classNames(classes.lit)} onClick={() => this.props.history.push(`/admins/content?id=${command.client.id}`)}>  <Typography color="title" variant="tableTitle">{command.client.last_name + " " + command.client.first_name}</Typography></span></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Adresse de livraison (patient) :</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{command.address}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle">complément d'adresse (patient):</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle">{command.second_address}</Typography></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Nom de la Pharmacie:</Typography></td>
                                                    <td> <span style={{
                                                        textTransform: "uppercase",
                                                        color: "#28acb3",
                                                        fontWeight: "bold",
                                                        textAlign: "left",
                                                        fontFamily: "Montserrat, sans-serif",
                                                        cursor: "pointer"
                                                    }} className={classNames(classes.lit)} onClick={() => this.props.history.push(`/pharmacies/content?id=${command.pharmacy.id}`)} >  <Typography color="title" variant="tableTitle"> {command.pharmacy.name}</Typography></span></td>
                                                </tr>
                                                <tr>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >Adresse de récupération (pharmacie):</Typography></td>
                                                    <td>  <Typography color="title" variant="tableTitle" className={classNames(classes.lit)} >{command.pharmacy.address}</Typography></td>
                                                </tr>

                                            </table>
                                        </div>
                                    </CardContent>

                                </Card>



                                {command.paraPharmacies &&
                                    <Card style={{ marginBottom: 20, backgroundColor: "#f3f6f759" }}>

                                        <CardContent>
                                            <Typography color="textSecondary" variant="headline" style={{ marginBottom: 20 }}>
                                                Produits commandés
                        </Typography>
                                            <div style={{ float: "right" }}>

                                            </div>
                                            <div>
                                                {
                                                    command.paraPharmacies && command.paraPharmacies.edges.map((p, index) => {
                                                        return (
                                                            <div style={{ width: 400, padding: 10, flexDirection: "row", display: "flex" }}>

                                                                <div >
                                                                    <a href={p.paraPharmacy.img_url}>
                                                                        <img src={p.paraPharmacy.img_url} style={{ height: "100px", width: "100px" }} />

                                                                    </a>
                                                                </div>
                                                                <div>
                                                                    <div style={{ width: "600px" }}>
                                                                        <span style={{ fontFamily: "Roboto,Helvetica,Arial, sans-serif", fontSize: "19px", transform: "uppercase" }}>
                                                                            {p.paraPharmacy.name.toUpperCase()}
                                                                        </span>
                                                                    </div>
                                                                    <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>Prix :</span> <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>{p.price / 100 + ' € '}</span><br />
                                                                    <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>EAN :</span > <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>{p.paraPharmacy.bar_code}</span><br />
                                                                    <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>Nombre :</span > <span style={{ fontSize: "15px", color: "#444141", fontFamily: "Roboto,Helvetica,Arial, sans-serif" }}>{p.amount}</span>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }


                                            </div>
                                        </ CardContent>
                                    </Card >
                                }







                            </div>
                        

                }

            </div>
        );

    }
}

Command.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ commands }) => ({ commands });

const mapDispatchToProps = dispatch => ({
    loadCommands: bindActionCreators(loadCommands, dispatch),
    loadCommand: bindActionCreators(loadCommand, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default graphql(REFUND_COMMAND_MUTATION, {
    name: "refundCommande"
})(connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles, { withTheme: true })(Command))));
