export const AdminTypes = {
    REQUEST_LOAD_ADMINS: "REQUEST_LOAD_ADMINS",
    REQUEST_LOAD_ADMINS_SUCCESS: "REQUEST_LOAD_ADMINS_SUCCESS",
    REQUEST_LOAD_ADMINS_ERROR: "REQUEST_LOAD_ADMINS_ERROR",

    REQUEST_LOAD_ADMIN: "REQUEST_LOAD_ADMINS",
    REQUEST_LOAD_ADMIN_SUCCESS: "REQUEST_LOAD_ADMINS_SUCCESS",
    REQUEST_LOAD_ADMIN_ERROR: "REQUEST_LOAD_ADMINS_ERROR",
    REQUEST_LOAD_ADMIN_CLEAR: "REQUEST_LOAD_ADMIN_CLEAR",

    REQUEST_ADD_ADMIN: "REQUEST_ADD_ADMIN",
    REQUEST_ADD_ADMIN_SUCCESS: "REQUEST_ADD_ADMIN_SUCCESS",
    REQUEST_ADD_ADMIN_ERROR: "REQUEST_ADD_ADMIN_ERROR",
    REQUEST_ADD_ADMIN_CLEAR: "REQUEST_ADD_ADMIN_CLEAR",

    REQUEST_REMOVE_ADMIN: "REQUEST_REMOVE_ADMIN",
    REQUEST_REMOVE_ADMIN_SUCCESS: "REQUEST_REMOVE_ADMIN_SUCCESS",
    REQUEST_REMOVE_ADMIN_ERROR: "REQUEST_REMOVE_ADMIN_ERROR",
    REQUEST_REMOVE_ADMIN_CLEAR: "REQUEST_REMOVE_ADMIN_CLEAR",

    REQUEST_UPDATE_ADMIN: "REQUEST_UPDATE_ADMIN",
    REQUEST_UPDATE_ADMIN_SUCCESS: "REQUEST_UPDATE_ADMIN_SUCCESS",
    REQUEST_UPDATE_ADMIN_ERROR: "REQUEST_UPDATE_ADMIN_ERROR",
    REQUEST_UPDATE_ADMIN_CLEAR: "REQUEST_UPDATE_ADMIN_CLEAR",
};