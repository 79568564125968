import React from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardTitle from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import NbPharmacies from "./Dashboard/NbPharmacies";
import NbClients from "./Dashboard/NbClients";
import NbLivreurs from "./Dashboard/NbLivreurs";
import NbCommandesEnAttente from "./Dashboard/NbCommandesEnAttente";
import NbCommandesEncoursPreparation from "./Dashboard/NbCommandesEncoursPreparation";
import NbCommandesEncoursLivraison from "./Dashboard/NbCommandesEncoursLivraison";
import NbCommandesLivree from "./Dashboard/NbCommandesLivree";
import NbCommandesAnnule from "./Dashboard/NbCommandesAnnule";
import NbCommandes from "./Dashboard/NbCommandes";
import PrixCommandeValidePayPal from"./Dashboard/PrixCommandeValidePayPal";
import PrixCommandeValideCB from "./Dashboard/PrixCommandeValideCB";
import PrixCommandeValidee from "./Dashboard/PrixCommandeValidee";
import PrixCommandeAnnuleePayPal from "./Dashboard/PrixCommandeAnnuleePayPal" ;
import PrixCommandeAnnuleeCB from"./Dashboard/PrixCommandeAnnuleeCB";
import PrixCommandeAnnulee from "./Dashboard/PrixCommandeAnnulee";
import MediaQuery from 'react-responsive';
 
const style = theme => ({
    container: {
        flexGrow: 1,
        fontFamily: "Montserrat, sans-serif",
    },
    firstTitle: {
   
        fontSize : 24 , lineHeight : 2, display:"block", textTransform:"uppercase", fontFamily: "Montserrat, sans-serif",
            },

     secondTitle: {
     
      fontSize : 16 , lineHeight : 2, display:"block", textTransform:"uppercase", fontFamily: "Montserrat, sans-serif",
       },

 boxFlex: {
display:"flex",
marginBottom:"2em",
},

       '@media screen and (max-width: 991px)': {
        firstTitle: {
               fontSize : 22, 
               textAlign: "center",
                },
                boxFlex: {
                    display:"flex",
                    flexDirection:"column",
                    },
                    boxFlex1: {
              margin:"0 !important",
                        marginBottom:"2em !important",
                        },
             
       }
,
    card: {
        marginTop: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 3,
    }
});
const stylesWelcome = {
    welcome: { marginBottom: "1em" , fontFamily: "Montserrat, sans-serif",},
    button: { width: 300, margin: "1.5%",backgroundImage:"linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",color:"rgb(255, 255, 255)" },
    flex: { display: "flex", marginBottom: "2em" },
    leftCol: { flex: 1 , marginRight: "1em" ,display:"block", cursor:"pointer" },
    rightCol: { flex: 1, marginLeft: "1em", cursor:"pointer" },
    singleCol: { flex: 1 , cursor:"pointer" },
    boxFlex: {
        display:"flex",
        marginBottom:"2em",
        },
        boxFlex1: {
            flex: "1 1 0%",
            marginRight:" 1em",
            display: "block",
            cursor: "pointer",
        },
               '@media screen and (max-width: 991px)': {
                firstTitle: {
                       fontSize : 22, 
                       textAlign: "center",
                        },
                        boxFlex: {
                            display:"flex",
                            flexDirection:"column !important",
                            },
                            boxFlex1: {
                      margin:"0 !important",
                                marginBottom:"2em !important",
                                },
                     
               }
        ,
    
};


class Feedback extends React.Component {

    componentWillMount(){
        
        
        
        
        
    }

    render() {
        
        const {classes} = this.props;
        
        return (
            <div className={classes.container}>
                
                <Card style={stylesWelcome.welcome}>
                    
                    </Card>
                      <div style={{padding:16,position:"relative"}}>
                    <div style={{fontSize : 24 , lineHeight : 2,display:"block", textTransform:"uppercase", fontFamily: "Montserrat, sans-serif", }}  className={classes.firstTitle}  > Statistiques de pharmaloop</div>
                    </div>
               



            <div className={classes.boxFlex}>
            <div style={stylesWelcome.leftCol} className={classes.boxFlex1} onClick={() => this.props.history.push("/pharmacies")}>
            
               <NbPharmacies />
            
            </div>
            <div style={stylesWelcome.singleCol} className={classes.boxFlex1} onClick={() => this.props.history.push("/admins")}>
                   <NbClients />
            </div>
            <div style={stylesWelcome.rightCol} className={classes.boxFlex1} onClick={()=>{
                    this.props.history.push({
                        pathname: '/commands',
                        state: { type: "all",toDay:false }
                      })
                }}>
                <NbCommandes type="all" toDay={false} />
                </div>
        </div>
    
        <div className={classes.boxFlex}>
        
                <div style={stylesWelcome.leftCol} className={classes.boxFlex1} onClick={()=>{
                    this.props.history.push({
                        pathname: '/commands',
                        state: { type: "EXPRESS",toDay:false }
                      })
                }}>
                <NbCommandes type="EXPRESS" toDay={false}/>
                </div>
                <div style={stylesWelcome.singleCol} className={classes.boxFlex1} onClick={()=>{
                    this.props.history.push({
                        pathname: '/commands',
                        state: { type: "FREE",toDay:false }
                      })
                }}>
                <NbCommandes type="FREE" toDay={false}/>
                </div>
                <div style={stylesWelcome.rightCol} className={classes.boxFlex1} onClick={()=>{
                    this.props.history.push({
                        pathname: '/commands',
                        state: { type: "IN_DAY",toDay:false }
                      })
                }}>
                <NbCommandes type="IN_DAY" />
                </div>
            </div>
        </div>


       
                



            
       
            
            

        );
    }
}


/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default ((withStyles( style, { withTheme: true })(Feedback)));