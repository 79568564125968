import gql from "graphql-tag";

import apolloClient from "../apolloClient";
import {AdminTypes} from "../types/admin.types";

const LOAD_ADMINS_QUERY = gql`
    query {
        getAdmins {
            id
            profile {
                email
                profile_pic_url
            }
        }
    }
`;



const LOAD_ADMIN_QUERY = gql`
query getAdmin ($id: ID!){ 
        getAdmin(id: $id) {
            id
            profile{id
                email
                profile_pic_url}

            
        }
    }
`;

const loadAdmins = () => dispatch => {
    dispatch({type: AdminTypes.REQUEST_LOAD_ADMINS});
    apolloClient.query({query: LOAD_ADMINS_QUERY})
        .then(response => {
      
            dispatch({type: AdminTypes.REQUEST_LOAD_ADMINS_SUCCESS, data: response.data.getAdmins})
        })
        .catch(error => {
         
            dispatch({type: AdminTypes.REQUEST_LOAD_ADMINS_ERROR, error});
        });
};

const loadAdmin = (id) => dispatch => {
    dispatch({type: AdminTypes.REQUEST_LOAD_ADMIN});
    apolloClient.query({query: LOAD_ADMIN_QUERY})
    .then(response => {
        
        dispatch({type:AdminTypes.REQUEST_LOAD_ADMIN_SUCCESS, data: response.data.getAdmin(id)});
    })
    .catch(error => {
        
        dispatch({type: AdminTypes.REQUEST_LOAD_ADMIN_ERROR, error});
    });
};

const addAdmin = (data) => dispatch => {

};

const removeAdmin = (id) => dispatch => {

};

const updateAdmin = (id, data) => dispatch => {

};

export {
    loadAdmins,
    loadAdmin,
    addAdmin,
    removeAdmin,
    updateAdmin
};