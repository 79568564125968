import { CommandTypes } from "../../types/command.types";

const INITIAL_STATE = {
    loading: false,
    requested: false,
    error: null, 
    data: [],
    command:null,
    selectedCategory:null,
    selectedSubCategory:null,
    selectedNesteSubCategory:null,

};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case CommandTypes.REQUEST_LOAD_COMMANDS:
            return {...state, loading: true, error: null};
        case CommandTypes.REQUEST_LOAD_COMMANDS_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case CommandTypes.REQUEST_LOAD_COMMANDS_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
            case CommandTypes.REQUEST_LOAD_COMMAND:
            return {...state, loading: true, error: null};
        case CommandTypes.REQUEST_LOAD_COMMAND_SUCCESS:

         
            return {...state, loading: false, requested: true, command: action.data};
        case CommandTypes.REQUEST_LOAD_COMMAND_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
            case "SET_CATEGORY_FILTER":
            return {...state,selectedCategory:action.payload};
        case "SET_SUB_CATEGORY_FILTER":
            return {...state,selectedSubCategory:action.payload};
        case "SET_NESTED_SUB_CATEGORY_FILTER":
            return {...state,selectedNesteSubCategory:action.payload};
        default:
            return state;
    }
};