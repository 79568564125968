export const DeliverymanTypes  = {
    REQUEST_LOAD_DELIVERYMEN: "REQUEST_LOAD_DELIVERYMEN",
    REQUEST_LOAD_DELIVERYMEN_SUCCESS: "REQUEST_LOAD_DELIVERYMEN_SUCCESS",
    REQUEST_LOAD_DELIVERYMEN_ERROR: "REQUEST_LOAD_DELIVERYMEN_ERROR",



    REQUEST_LOAD_DELIVERYMAN: "REQUEST_LOAD_DELIVERYMAN",
    REQUEST_LOAD_DELIVERYMAN_SUCCESS: "REQUEST_LOAD_DELIVERYMAN_SUCCESS",
    REQUEST_LOAD_DELIVERYMAN_ERROR: "REQUEST_LOAD_DELIVERYMAN_ERROR",
};