import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadCommands } from "../actions";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import { capitalizeFirstLetter } from "../tools/utils";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import Toolbar from "@material-ui/core/Toolbar";
import MediaQuery from 'react-responsive';

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import LocalShipping from '@material-ui/icons/LocalShipping';
import { lighten } from "@material-ui/core/styles/colorManipulator";
import moment from "moment";
import gql from "graphql-tag";
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden'
import { graphql, compose } from "react-apollo";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

const style = theme => ({
  container: {
    flexGrow: 1
  },
  card: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3
  }
});

/************************************************************TABLE OF COMMANDS************************************************/
function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}
function getCmdState(state){
  let st=""
  switch(state){
      case "PENDING":st="En Attente";break;
      case "REVOKED":st="Annulée";break;
      case "APPROVED":st="Acceptée";break;
      case "TO_PAY":st="Attente de paiement";break;
      case "PAID":st="Payée";break;
      case "DELIVERING":st="En cours de livraison";break;
      case "DELIVERED":st="Livrée";break;
      case "CANCELED":st="Annulée";break;
      case "VOIDED":st="--";break;

  }
  return st;
}
const rows = [
  { id: "", numeric: false, disablePadding: true, label: "NUMÉRO DE COMMANDE" },
  { id: "pharmacy", numeric: false, disablePadding: true, label: "PHARMACIE" },
  { id: "client", numeric: false, disablePadding: true, label: "PATIENT" },
  { id: "createdAt", numeric: false, disablePadding: true, label: "DATE" },
  { id: "state", numeric: false, disablePadding: true, label: "STATUT " },
  { id: "", numeric: false, disablePadding: true, label: "MODE DE LIVRAISON" },

  { id: "", numeric: false, disablePadding: true, label: "" }
];
const columns = (props)=>(
  [ {label:"prim",primary:"primary",key:"id",render:(id)=>{
      let command={};
          if(props.data&&props.data.getCommands)
          props.data.getCommands.forEach(cmd=>{
              if(cmd.id==id){
                  return command=cmd;
              }
          })
         
      return(<span style={{fontSize:13}}>{command.num+"-"+(command.createdAt?moment(command.createdAt).format("LL"):"")+" - "+
      (getCmdState(command.state)
      +" - "+(command.delivery_mode=='FREE'?'Click & Collect':command.delivery_mode=='EXPRESS'?'Express':'Fin de Journée')
  )}</span>)
  }},
      { label: "N° DE COMMANDE",key: "num" },
      { label:"DATE",key: "createdAt",render:(createdAt)=>{
          return(
              createdAt?moment(createdAt).format("LLL"):""
          )
      }},
      { label:"STATUT ",key :"state",render:(state)=>{
          return (
            getCmdState(state)
          )
      }},
      {label:"PHARMACIE",key:"pharmacy",render:(value)=>{
          return (
              <div  style={{
                  textTransform: "uppercase",
                  color: "#28acb3",
                  fontWeight: "bold",
                  textAlign:"left",
                  fontFamily: "Montserrat, sans-serif",
                  cursor:"pointer"
                }}
                onClick={() => props.history.push(`/pharmacies/content?id=${value.id}`)}
                                            ><a >{capitalizeFirstLetter(value.name)}</a></div>)
         
      }
      },
      {label:"PATIENT",key:"client",render:(value)=>{
          return <div  style={{
              textTransform: "uppercase",
              color: "#28acb3",
              fontWeight: "bold",
              textAlign:"left",
              fontFamily: "Montserrat, sans-serif",
              cursor:"pointer"
            }}
                                        onClick={() => props.history.push(`/admins/content?id=${value.id}`)}
                                        >
                                      {capitalizeFirstLetter(value.last_name) +"  "+
                                      capitalizeFirstLetter(value.first_name)}
                                </div>
      }},
      {label:"DATE DE LIVRAISON",key:"delivery_date",render:(delivery_date)=>{
          return(
              delivery_date?moment(delivery_date).format("LLL"):""
          )
      }},
      {label:"MODE DE LIVRAISON",key:"delivery_mode",render:(delivery_mode)=>{
          return (
              delivery_mode=='FREE'?'Click & Collect':delivery_mode=='EXPRESS'?'Express':'Fin de Journée'
          )
      }},
      {label:"",key:"id", render: (value) => {
          let command={};
          if(props.data&&props.data.getCommands)
          props.data.getCommands.forEach(cmd=>{
              if(cmd.id==value){
                  return command=cmd;
              }
          })
          
          return( <div style={{display:"flex"}}>
          <Tooltip title="AFFICHER">
            <IconButton className={props.classes.button} aria-label="AFFICHER" onClick={() => props.history.push(`/commands/content?id=${command.id}`)}> 
       
       <Eye style={{ color: "#2cacb3" }} viewBox="0 0 24 24" />
       
             
            </IconButton></Tooltip>
            {
                command.tracking_url?(<Tooltip title="TRACKING">
                <IconButton className={props.classes.button} aria-label="TRACKING" onClick={() => window.open(command.tracking_url, '_blank')}> 
           
                <DirectionsBike style={{ color: "#2cacb3" }} viewBox="0 0 24 24" />
           
                 
                </IconButton></Tooltip>):""
            }
          </div>)
      }}
  ]
);
class ClientCommandsHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy,classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                style={{
                  textAlign: "left",
                  fontFamily: "Montserrat, sans-serif"
                }}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableHead}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    style={{
                      flexDirection: "column",
                      fontWeight: "bold"
                    }}
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

ClientCommandsHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  boxFlex: {
   display:"flex",
      },

  '@media screen and (max-width: 991px)': {
    firstTitle: {
           fontSize : "22px !important", 
           textAlign:"center !important",
         paddingTop:"20px",
         paddingBottom:"20px",
            },
            boxFlex: {
              flexDirection:"column",
            },
      
         
   },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  },
  button:{
    width:30,
    height:30
},
  tableCell:{
    fontSize:"13px !important"
 },
 tableHead:{
     fontSize:"14px !important"
  },
'@media screen and  (min-width: 991px) and (max-width: 1390px)': {
    tableCell:{
        fontSize:"12px !important"
     },
     tableHead:{
         fontSize:"12px !important"
      },
}
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class ClientCommandsToolbar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classNames(classes.boxFlex)}>
        <div className={classes.title}>
          <Typography
            style={{
              textAlign: "left",
              fontSize: "1.3125rem",
              flexDirection: "column",
              fontFamily: "Montserrat, sans-serif"
            }}
            variant="title"
            id="tableTitle"
            className={classNames(classes.firstTitle)}
          >
           <span className={classNames(classes.firstTitle)}>  Liste des Commandes du Patient </span>
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
              <IconButton
                aria-label="filter"
                onClick={() => this.props.toggleFilter()}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

ClientCommandsToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

ClientCommandsToolbar = withStyles(toolbarStyles)(ClientCommandsToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  tableCell:{
    fontSize:"13px !important"
 },
 tableHead:{
     fontSize:"14px !important"
  },
'@media screen and  (min-width: 991px) and (max-width: 1390px)': {
    tableCell:{
        fontSize:"12px !important"
     },
     tableHead:{
         fontSize:"12px !important"
      },
},
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
const LOAD_COMMANDS_QUERY = gql`
    query {
        getCommands {
            id
            num
            to_pay
            delivery_date
            address
            second_address
            pharmacy {
                id
                num
                name
                address
            }
            client {
                id
                num
                first_name
                last_name
                address
                tel
                
                
            }
            createdAt
            state
            delivery_mode
            tracking_url
        }
    }
`;
class ClientCommands extends React.Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: window.localStorage.getItem('admin_patient_cmd_rows_per_page')?parseInt( window.localStorage.getItem('admin_patient_cmd_rows_per_page')):25

    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    window.localStorage.setItem('admin_patient_cmd_rows_per_page', event.target.value)
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillUnmount() {
    window.onscroll = null;
  }
  componentWillMount() {
  }

  render() {
    let commands = this.props.data&&this.props.data.getCommands?this.props.data.getCommands:[];

    const { classes, theme } = this.props;

    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };
    commands=commands.filter(item => this.state.filterText.trim() == "" ? true :item.client.first_name.indexOf(this.state.filterText) > -1
        ||item.client.last_name.indexOf(this.state.filterText) > -1||item.pharmacy.name.indexOf(this.state.filterText) > -1||
        item.num.indexOf(this.state.filterText) > -1)
        .filter(item => item.client.id ===
          window.location.hash.substring(18))
   
    return (
      <div>
        <Typography color="textSecondary" variant="headline" />
        {this.props.data.loading &&
        (
            <div className={classes.progressContainer}>
              <Fade in timeout={300}>
                <Typography variant="subheading" color="textSecondary">
                  Chargement...
                </Typography>
              </Fade>
            </div>
          )}
        
          <Paper className={classes.root}>
            <ClientCommandsToolbar
              refresh={this.props.data.refetch}
              toggleFilter={this.toggleFilter}
              isFilterShowen={this.state.showFilter}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true} timeout={200}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                </div>
              )}
              <Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
              <Table className={classes.table}>
                <ClientCommandsHead
                 classes={classes}
                />
                <TableBody>
                  {commands
.filter(
  item => this.state.filterText.trim() == "" ? true :item.client.first_name.indexOf(this.state.filterText) > -1
  ||item.client.last_name.indexOf(this.state.filterText) > -1||item.pharmacy.name.indexOf(this.state.filterText) > -1||
  item.num.indexOf(this.state.filterText) > -1
)
.filter(item => item.client.id ===
  window.location.hash.substring(18))
  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map(command => {
                      if (
                        command.client.id ===
                        window.location.hash.substring(18)
                      )
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={command.id}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              padding="checkbox"
                              numeric
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center"
                                }}
                                className={classes.tableCell}
                              >
                                {command.num}
                              </div>
                            </TableCell>
                            <TableCell
                              style={{
                                textAlign: "left",
                                fontFamily: "Montserrat, sans-serif",
                                marginLeft: -20,
                                paddingLeft:0
                              }}
                              className={classes.tableCell}
                            >
                              {capitalizeFirstLetter(command.pharmacy.name)}
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "left",
                                fontFamily: "Montserrat, sans-serif"
                              }}
                              
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: -20,
                                }}
                                className={classes.tableCell}
                              >
                                {capitalizeFirstLetter(
                                  command.client.first_name
                                ) +
                                  "  " +
                                  capitalizeFirstLetter(
                                    command.client.last_name
                                  )}
                              </div>
                            </TableCell>

                            <TableCell >
                              <div
                                style={{
                                  position: "relative",
                                  verticalAlign: "middle",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  paddingLeft: 0,
                                  paddingRight: 16,
                                  TextAlign: "left",
                                  marginLeft: -20,
                                }}
                                className={classes.tableCell}
                              >
                                {moment(command.createdAt).format("LLL")}
                              </div>
                            </TableCell>
                            <TableCell>
                              <div
                                style={{
                                  position: "relative",
                                  verticalAlign: "middle",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  paddingLeft: 0,
                                  paddingRight: 16,
                                  marginLeft: -20,
                                }}
                                className={classes.tableCell}
                              >
                                 {getCmdState(command.state)} 

                              </div>
                            </TableCell>


                            <TableCell >
                              <div
                                style={{
                                  position: "relative",
                                  verticalAlign: "middle",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  paddingLeft: 0,
                                  paddingRight: 16,
                                  TextAlign: "left",
                                  marginLeft: -20,
                                }}
                                className={classes.tableCell}
                              >
                                {command.delivery_mode=='FREE'?'Click & Collect':(command.delivery_mode=='EXPRESS'?'Express':'Fin de Journée')}
                              </div>
                            </TableCell>
              

                            <TableCell >
                            <div style={{display:"flex",textAlign:"left"}}
                            
                            >
                                               <Tooltip title="AFFICHER">
                                                 <IconButton className={classes.button} aria-label="AFFICHER" onClick={() => this.props.history.push(`/commands/content?id=${command.id}`)}> 
                                            
                                            <Eye style={{ color: "#2cacb3" }} viewBox="0 0 24 24" />
                                            
                                                  
                                                 </IconButton></Tooltip>
                                                 {
                                                     command.tracking_url?(<Tooltip title="TRACKING">
                                                     <IconButton className={classes.button} aria-label="TRACKING" onClick={() => window.open(command.tracking_url, '_blank')}> 
                                                
                                                     <DirectionsBike style={{ color: "#2cacb3" }} viewBox="0 0 24 24" />
                                                
                                                      
                                                     </IconButton></Tooltip>):""
                                                 }
                                               </div>
                            </TableCell>
                          </TableRow>
                        )
                    
                  })
                      
                    }
                   
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 49 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              </Hidden >
                        <Hidden only={['lg', 'xl']} implementation={'js'}>
                        <ResponsiveTable
                            columns={columns(this.props)}
                            data={commands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                            showPagination={true}
                            count={commands.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            excludePrimaryFromDetails={true}
                            onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                        />
                        </Hidden>
                    </div>
                    <Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
                    <TablePagination
                        component="div"
                        count={commands.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        backIconButtonProps={{
                            "aria-label": "Previous Page",
                        }}
                        nextIconButtonProps={{
                            "aria-label": "Next Page",
                        }}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                    </Hidden>
          </Paper>
        
      </div>
    );
  }
}

ClientCommands.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ commands }) => ({ commands });

const mapDispatchToProps = dispatch => ({
  loadCommands: bindActionCreators(loadCommands, dispatch)
});

// export default connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(withStyles(styles, { withTheme: true })(ClientCommands));
export default compose(
    graphql(LOAD_COMMANDS_QUERY)
)(withStyles(styles, { withTheme: true })(ClientCommands))