import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import MediaQuery from 'react-responsive';
import CardMedia from '@material-ui/core/CardMedia';
import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";
import InputLabel from "@material-ui/core/InputLabel";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import Badge from '@material-ui/core/Badge';
import Assignment from '@material-ui/icons/Assignment';
import { lighten } from "@material-ui/core/styles/colorManipulator";

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { graphql, compose } from "react-apollo";

import { loadCommands, loadPharmacies } from "../actions";
import moment from "moment";
import Excel from './Excel';
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden';
import Modal from '@material-ui/core/Modal';
import {
    NotificationContainer,
    NotificationManager
} from "react-notifications";
//**********************table************/
const style = theme => ({
    container: {
        flexGrow: 1
    },
    card: {
        marginTop: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 3
    }
});

function desc(a, b, orderBy) {

    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === "desc"
        ? (a, b) => desc(a, b, orderBy)
        : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: "name", numeric: false, disablePadding: true, label: "NOM" },
    { id: "para_count", numeric: false, disablePadding: true, label: "Nombre de produits" },
    { id: "createdAt", numeric: false, disablePadding: true, label: "CRÉER LE" },
    { id: "state", numeric: true, disablePadding: false, label: "STATUT" },
    { id: "", numeric: true, disablePadding: false }
];

function getModalStyle() {
    const top = 40
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const LOAD_PAHRMACY_QUERY = gql`
query {
  getPharmacies {
    id
    is_pill_maker
    description
    num
    tel
    name
    website
    commands {
      id
    }

    profile {
      id
      email
      profile_pic_url
      is_blocked
      createdAt
    }
    location {
      latitude
      longitude
    }

    country
    address
    city
    zip_code 
    work_schedule 
    {
      is_always_on_work
      planing {
        monday {
          open_at
          close_at
        }
        tuesday {
          open_at
          close_at
        }
        wednesday {
          open_at
          close_at
        }
        thursday {
          open_at
          close_at
        }
        friday {
          open_at
          close_at
        }
        saturday {
          open_at
          close_at
        }
        sunday {
          open_at
          close_at
        }
      }
    }
  }
}
`;
const columns = (props) => (
    [{
        label: "prim", primary: "primary", key: "id", render: (id) => {
            let pharmacy = {};
            if (props.data && props.data.getPharmacies)
                props.data.getPharmacies.forEach(cl => {
                    if (cl.id == id) {
                        return pharmacy = cl;
                    }
                })
            return (<span style={{ fontSize: 13 }}>{pharmacy.num + " - " + (capitalizeFirstLetter(pharmacy.name))}</span>)
        }
    },
    { label: "NUMÉRO", key: "num" },
    {
        label: "NOM COMPLET", key: "name", render: (name) => {
            return (
                capitalizeFirstLetter(name)
            )
        }
    },
    {
        label: "INSCRIT LE", key: "profile", render: (profile) => {
            return (
                profile.createdAt ? moment(profile.createdAt).format("LLL") : ""
            )
        }
    },
    {
        label: "STATUT", key: "profile", render: (profile) => {
            return (
                profile.is_blocked ? "Désactivée" : "Activée"
            )
        }
    },
    {
        label: "VILLE", key: "city", render: (city) => {
            return (
                city
            )
        }
    },
    {
        label: "", key: "id", render: (id) => {
            let pharmacy = {};
            if (props.data && props.data.getPharmacies)
                props.data.getPharmacies.forEach(cl => {
                    if (cl.id == id) {
                        return pharmacy = cl;
                    }
                })
            return <div>
                <Tooltip title={"COMMANDES"}>
                    <IconButton disabled={pharmacy.commands && pharmacy.commands.length === 0} variant="contained" onClick={() => props.history.push(`/pharmacies/commands/${pharmacy.id}`)}>
                        <Badge badgeContent={pharmacy.commands && pharmacy.commands.length} classes={{ badge: props.classes.badge }}>
                            <Assignment style={{ fill: "#28abb3" }} />
                        </Badge>

                    </IconButton>
                </Tooltip>
                <Tooltip title={"AFFICHER"}>
                    <IconButton style={{ fill: "#28abb3", marginLeft: '10px' }} className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/content?id=${pharmacy.id}`)}>

                        <Eye style={{ color: "#28abb3" }} viewBox="0 0 24 24" />


                    </IconButton>
                </Tooltip>
                <Tooltip title={"EDITER"}>
                    <IconButton style={{ fill: "#28abb3" }} aria-label="EDITER" onClick={() => props.history.push(`/pharmacies/edit/${pharmacy.id}`)}>

                        <EditIcon style={{ color: "#28abb3" }} />


                    </IconButton>
                </Tooltip>
                {pharmacy.profile && pharmacy.profile.is_blocked ? (<IconButton className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/unblock/${pharmacy.id}`)} >
                    <BlockIcon style={{ color: "rgba(232,27,12)" }} />

                </IconButton>) : <IconButton className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/delete/${pharmacy.id}`)} >
                        <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />

                    </IconButton>}
            </div>



        }
    }
    ]
);

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy, classes } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                style={{
                                    textAlign: "left",
                                    fontSize: 16,
                                    flexDirection: "column",
                                    fontFamily: "Montserrat, sans-serif"
                                }}
                                numeric={row.numeric}
                                padding={
                                    i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                                }
                                sortDirection={orderBy === row.id ? order : false}
                                className={classes.tableHead}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit
    },
    boxFlex: {
        display: "flex",
    },

    '@media screen and (max-width: 991px)': {
        firstTitle: {
            fontSize: "22px !important",
            textAlign: "center !important",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        boxFlex: {
            flexDirection: "column",
        },


    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85)
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark
            },
    spacer: {
        flex: "1 1 100%"
    },
    actions: {
        color: theme.palette.text.secondary
    },
    title: {
        flex: "0 0 auto",
        textTransform: "uppercase"
    }
});
// Button Style //
const buttonstyles = theme => ({
    button: {
        margin: theme.spacing.unit
    },
    leftIcon: {
        marginRight: theme.spacing.unit
    },
    iconSmall: {
        fontSize: 20
    },
    input: {
        display: "none"
    }
});

class EnhancedTableToolbar extends React.Component {
    render() {
        const { classes } = this.props;

        return (
            <Toolbar className={classNames(classes.boxFlex)}>
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle" className={classNames(classes.firstTitle)} >
                        <span style={{ fontFamily: "Montserrat, sans-serif" }} className={classNames(classes.firstTitle)} >
                            {" "}
                            Liste des catégories{" "}
                        </span>
                    </Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        {this.props.excelProps ? <Excel {...this.props.excelProps} /> : ""}
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton
                                aria-label="filter"
                                onClick={() => this.props.toggleFilter()}
                            >
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton
                                aria-label="refresh"
                                onClick={() => this.props.refresh()}
                            >
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        '&:parent':{display:'block !important'
        }
    },
    boxFlex: {
        display: "flex",
    },
  
    '@media screen and (max-width: 991px)': {
        firstTitle: {
            fontSize: "22px !important",
            textAlign: "center !important",
            paddingTop: "20px",
            paddingBottom: "20px",
        },
        boxFlex: {
            flexDirection: "column",
        },


    },
    table: {
        minWidth: 900
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    rou: {
        textAlign: "left"
    },
   colorSwitchBase: {
        color:"#00adac",
        '&$colorChecked': {
          color: "#00adac",
          '& + $colorBar': {
            backgroundColor: "#00adac",
          },
        },
      },
    colorBar: {},
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500]
            }
        }
    },

    avatar: {
        width: 30,
        height: 30,
        margin: 10
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center"
    },
    badge: {

        top: -9,
        right: -11,
        color: `white`,
        fontSize: 10,
        fontWeight: 800,
        width: 17,
        height: 17,

        // The border color match the background color.
        border: `2px solid white`,
        backgroundColor: teal[400]

    },
    button: {

    },
    tableCell: {
        fontSize: "13px !important"
    },
    tableHead: {
        fontSize: "14px !important"
    },
    '@media screen and  (min-width: 991px) and (max-width: 1390px)': {
        tableCell: {
            fontSize: "12px !important"
        },
        tableHead: {
            fontSize: "12px !important"
        },
    },
    paper: {
        position: 'absolute',
        width: theme.spacing.unit * 50,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing.unit * 4,
    },
});

const LOAD_CATEGORIES_QUERY = gql`
  query getCategories {
    getCategories {
        id
        name
        para_count
        state
        createdAt
        img_url
        subCategories{
            id
            name
            para_count
            state
            createdAt
            nestedSubCategories{
                id
                name
                para_count
                state
                createdAt
            }
           
        }
    }
  }
`;



const UPDATE_CATEGORIE_MUTATION = gql`
  mutation updateParaPharmacyCategory ($id: ID! , $name: String!, $file: Upload ) {
    updateParaPharmacyCategory(
        id:$id
        name: $name
        file: $file

    ) {
      id
      name
    }
  }
`;
const ADD_CATEGORIE_MUTATION = gql`
  mutation addParaPharmacyCategory(
    $name: String! 
    $file: Upload!   
  ) {
    addParaPharmacyCategory(
        name: $name
        file: $file
    ) {
      id
      name
    }
  }
`;
const ENABLED_CATEGORIE_MUTATION = gql`
  mutation enableParaPharmacyCategory(
    $id: ID!    
  ) {
    enableParaPharmacyCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const DISABLED_CATEGORIE_MUTATION = gql`
  mutation disableParaPharmacyCategory(
    $id: ID!    
  ) {
    disableParaPharmacyCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const REMOVE_CATEGORIE_MUTATION = gql`
  mutation removeParaPharmacyCategory(
    $id: ID!    
  ) {
    removeParaPharmacyCategory(
        id: $id
    ) {
      id
      name
    }
  }
`;

/*Sub categories */

const UPDATE_SUB_CATEGORIE_MUTATION = gql`
  mutation updateParaPharmacySubCategory ($id: ID! , $name: String!) {
    updateParaPharmacySubCategory(
        id:$id
        name: $name
    ) {
      id
      name
    }
  }
`;
const ADD_SUB_CATEGORIE_MUTATION = gql`
  mutation addParaPharmacySubCategory(
    $category_id:ID!
    $name: String!    
  ) {
    addParaPharmacySubCategory(
        category_id:$category_id
        name: $name
    ) {
      id
      name
    }
  }
`;
const ENABLED_SUB_CATEGORIE_MUTATION = gql`
  mutation enableParaPharmacySubCategory(
    $id: ID!    
  ) {
    enableParaPharmacySubCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const DISABLED_SUB_CATEGORIE_MUTATION = gql`
  mutation disableParaPharmacySubCategory(
    $id: ID!    
  ) {
    disableParaPharmacySubCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const REMOVE_SUB_CATEGORIE_MUTATION = gql`
  mutation removeParaPharmacySubCategory(
    $id: ID!    
  ) {
    removeParaPharmacySubCategory(
        id: $id
    ) {
      id
      name
    }
  }
`;

/*Sub categories */

const UPDATE_NESTED_SUB_CATEGORIE_MUTATION = gql`
  mutation updateParaPharmacyNestedSubCategory ($id: ID! , $name: String!) {
    updateParaPharmacyNestedSubCategory(
        id:$id
        name: $name
    ) {
      id
      name
    }
  }
`;
const ADD_NESTED_SUB_CATEGORIE_MUTATION = gql`
  mutation addParaPharmacyNestedSubCategory(
    $sub_category_id:ID!
    $name: String!    
  ) {
    addParaPharmacyNestedSubCategory(
        sub_category_id:$sub_category_id
        name: $name
    ) {
      id
      name
    }
  }
`;
const ENABLED_NESTED_SUB_CATEGORIE_MUTATION = gql`
  mutation enableParaPharmacyNestedSubCategory(
    $id: ID!    
  ) {
    enableParaPharmacyNestedSubCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const DISABLED_NESTED_SUB_CATEGORIE_MUTATION = gql`
  mutation disableParaPharmacyNestedSubCategory(
    $id: ID!    
  ) {
    disableParaPharmacyNestedSubCategory(
        id: $id
    ) {
      id
      name
      state
    }
  }
`;
const REMOVE_NESTED_SUB_CATEGORIE_MUTATION = gql`
  mutation removeParaPharmacyNestedSubCategory(
    $id: ID!    
  ) {
    removeParaPharmacyNestedSubCategory(
        id: $id
    ) {
      id
      name
    }
  }
`;
class Categories extends React.Component {
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor() {
        super();
        this.state = {
            image: "",
            file: null,
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            categorie: "",
            id: "",
            mode: 'create',
            openModal: false,
            level: 1,
            active: true,
            parentCat: "",
            rowsPerPage: window.localStorage.getItem('admin_categories_rows_per_page') ? parseInt(window.localStorage.getItem('admin_categories_rows_per_page')) : 25
        };
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
    }
    handleChangeAvatar = ({ target: { files } }) => {
        let reader = new FileReader();
        reader.onload = e => {
          this.setState({ image: e.target.result });
        };
    
        reader.readAsDataURL(files[0]);
   
        this.setState({ file: files.length ? files[0] : null });
     
      };
    handleOpenModal = () => {
        this.setState({ openModal: true });
    };
    handleSwitchChange = (event, id, state, level) => {
       
        let action;
        if (level == 1) {
            action = state ? 'enabledCategory' : 'disabledCategory';
        }
        else if (level == 2) {
            action = state ? 'enabledSubCategory' : 'disabledSubCategory';
        }
        else {
            action = state ? 'enabledNestedSubCategory' : 'disabledNestedSubCategory';
        }
       
        this.props[action]({
            variables: {
                id
            }
        })
            .then((response) => {
           
              
            })
            .catch(error => {
                NotificationManager.error("Une erreur est survenue");
            });
    }
    handleActionClick = (idCat) => {
        if(this.state.mode=="create"&&this.state.level == 1&&!this.state.file){
           return  NotificationManager.error("L'image est obligatoire !");
        }
        let action, data;
        switch (this.state.mode) {
            case 'create': if (this.state.level == 1) {
                action = 'addCategory'; data = { name: this.state.categorie,file:this.state.file };
                
            } else if (this.state.level == 2) {
                action = 'addSubCategory'; data = { name: this.state.categorie, category_id: idCat };
            } else {
                action = 'addNestedSubCategory'; data = { name: this.state.categorie, sub_category_id: idCat };
            }; break;

            case 'edit':
                if (this.state.level == 1) {
                    action = 'updateCategory'; data = { id: this.state.id, name: this.state.categorie };
                    if(this.state.file){
                        data['file']=this.state.file;
                    }
                } else if (this.state.level == 2) {
                    action = 'updateSubCategory'; data = { id: this.state.id, name: this.state.categorie };
                } else {
                    action = 'updateNestedSubCategory'; data = { id: this.state.id, name: this.state.categorie };
                }; break;
            case 'delete':
                if (this.state.level == 1) {
                    action = 'removeCategory'; data = { id: this.state.id, name: this.state.categorie };
                } else if (this.state.level == 2) {
                    action = 'removeSubCategory'; data = { id: this.state.id };
                } else {
                    action = 'removeNestedSubCategory'; data = { id: this.state.id };
                }; break;

        }
       
        this.props
        [action]({
            variables: {
                ...data
            }
        })
            .then((response) => {
                this.setState({file:null});
          
                let type=this.state.level==1?'catégorie':(this.state.level==2?'sous catégorie':'sous sous catégorie');
                if (response && response.data && response.data.errors && action == 'create') {
                    NotificationManager.error("La "+type+" existe  déjà !");

                }
                else {
                    NotificationManager.success("Action effectuée avec succès");
                    this.props.getCategories.refetch();
                    this.handleClose();
                }

            })
            .catch(error => {
 
                let type=this.state.level==1?'catégorie':(this.state.level==2?'sous catégorie':'sous sous catégorie');
                if (/CATEGORY_EXISTS/.test(error)) {
                    NotificationManager.error("La "+type+" existe  déjà !");
                  }
                  else if(/PARA_PHARMACIES_EXISTS/.test(error)){
                    NotificationManager.error("Impossible de supprimer cette "+type+ " car elle contient des produits parapharmaceutiques.");
                  }
                else
                NotificationManager.error("Une erreur est survenue");
            });
    }
    handleClose = () => {
        this.setState({ openModal: false });
    };

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => {
        window.localStorage.setItem('admin_categories_rows_per_page', event.target.value)
        this.setState({ rowsPerPage: event.target.value });
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({ showFilter: !this.state.showFilter, filterText: "" });
        } else {
            this.setState({ showFilter: !this.state.showFilter });
        }
    };

    handleFilterChange = ({ target: { value } }) =>
        this.setState({ filterText: value });

    componentWillMount() {

    }

    componentWillUnmount() {
        window.onscroll = null;
    }


    render() {
   
        if (this.props.location && this.props.location.state && this.props.location.state.refetch) {
            this.props.data.refetch();
        }
        const cat=this.state.level==1?"Catégorie":(this.state.level==2?"Sous Catégorie":"Sous Sous Catégorie");
        let categories = this.props.getCategories && this.props.getCategories.getCategories ? this.props.getCategories.getCategories : [];
    
        const { classes, theme } = this.props;
        const { order, orderBy, rowsPerPage, page } = this.state;
        const emptyRows =
            rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen
        };
        categories = categories && categories.filter(item => {
            return this.state.filterText.trim() == ""
                ? true
                : item.name.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        });
        //     let excelProps={columns:[{label:"Numéro de la pharmacie",value:"num"},
        //     {label:"Pharmacie",value:"pharmacy"},
        //     {label:"Date d'inscription",value:"date"},
        //     {label:"Statut",value:"state"},
        //     {label:"Ville",value:"city"},
        //     {label:"Nombre de commandes",value:"commandes"
        //   }

        //   ],dataSets:[],sheetName:"Pharmacies"}
        //     const { match, location, history } = this.props;
        //     pharmacies.forEach(pharmacie => {
        //       let pharmacie1={"num":pharmacie.num,
        //       "pharmacy":pharmacie.name,
        //       "date":moment(pharmacie.profile.createdAt).format("LLL"),
        //       "state":pharmacie.profile.is_blocked?"DESACTIVÉ":"ACTIVÉ",
        //       "city":pharmacie.city,
        //       "commandes":pharmacie.commands.length,

        //   }
        //       excelProps.dataSets.push(pharmacie1);           
        //   });
        return (
            <div>
                <NotificationContainer />
                <Modal
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    open={this.state.openModal}
                    onClose={this.handleClose}

                >
                    <div style={getModalStyle()} className={classes.paper}>

                        <Typography variant="title" id="simple-modal-description" style={{ marginBottom: "30px" }}>

                            {this.state.mode == 'create' ? 'Nouvelle '+cat : (this.state.mode == 'delete' ? 'Supprimer '+cat : 'Edition '+cat)}
                        </Typography>
                        <div style={{ marginLeft: 10 }}>
                            {this.state.mode != 'delete' ? (
                                <div >
                                    <InputLabel
                                        required
                                        style={{ position: "relative", lineHeight: "1px" }}
                                    >
                                        Nom
                  </InputLabel>
                                    <TextField
                                        required
                                        type="text"
                                        value={this.state.categorie}
                                        label=""
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                        onChange={e => this.setState({ categorie: e.target.value })}
                                        fullWidth={true}
                                    />
                                         {
                                             this.state.level==1&&(
                                                <div
                                                style={{
                                                  background: "rgb(239, 239, 239)",
                                                  cursor: "pointer",
                                                  padding: "1rem",
                                                  textAlign: "center",
                                                  color: "rgb(153, 153, 153)",
                                                  "margin-top": 20
                                                }}
                                              >
                                                <p>
                                                  Déposez une photo à télécharger ou cliquez pour la
                                                  sélectionner.
                                                </p>
                                                <input
                                                  type="file"
                                                  accept="image/*"
                                                  autoComplete="off"
                                                  onChange={this.handleChangeAvatar}
                                                  className="form-control"
                                                />
                                                <div>
                                                  <img
                                                    src={this.state.image}
                                                    style={{
                                                      maxWidth: "250px",
                                                      maxHeight: "250px",
                                                      marginTop: "10px"
                                                    }}
                                                  />
                                                </div>
                                              </div>
                                             )
                                         }
                                </div>
                            ) : ''}
                            <div style={
                                {
                                    "padding": 10,
                                    "display": "flex",
                                    "justify-content": "space-between"
                                }
                            }>
                                <Button color="default" style={{
                                    background: "#00B0AB",
                                    color: "white"
                                }} disabled={this.state.categorie.length == 0 && (this.state.mode == 'create' || this.state.mode == 'edit')} className={classes.button} onClick={() => {
                                    this.handleActionClick(this.state.parentCat);
                                }
                                }
                                >
                                    {this.state.mode == 'create' ? 'Créer' : (this.state.mode == 'delete' ? 'Supprimer' : 'Mettre à jour ')}
                                </Button>
                                <Button color="secondary" className={classes.button} onClick={() => {
                                    this.handleClose()
                                }}>
                                    Annuler
      </Button>
                            </div>
                        </div>

                    </div>
                </Modal>
                <EnhancedTableToolbar
                            refresh={this.props.getCategories.refetch}
                            toggleFilter={this.toggleFilter}
                            isFilterShowen={this.state.showFilter}
                        // excelProps={excelProps}
                        />
                           {this.state.showFilter && (
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Zoom in={true} timeout={200}>
                                        <TextField
                                            style={{ marginRight: 20 }}
                                            placeholder={"Chercher..."}
                                            autoFocus
                                            value={this.state.filterText}
                                            onChange={this.handleFilterChange}
                                        />
                                    </Zoom>
                                </div>
                            )}
                <Typography color="textSecondary" variant="headline" />
                {this.props.getCategories && this.props.getCategories.loading && (
                    <div className={classes.progressContainer}>
                        <Fade in timeout={300}>
                            <Typography variant="subheading" color="textSecondary">
                                Chargement...
                </Typography>
                        </Fade>
                    </div>
                )}
                {this.props.getCategories && this.props.getCategories.loading == false && (
                    <div>
                        {categories && categories
                            // .sort(getSorting(order, orderBy))

                            // .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            // .reverse()
                            .map((categorie, i) => {
                                return (
                                    <div className={classes.root}>
                                        <ExpansionPanel>
                                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                <Typography className={classes.heading}><span style={{"text-transform": "capitalize"}}>{categorie.name}</span></Typography>

                                            </ExpansionPanelSummary>

                                            <ExpansionPanelDetails style={{ flexDirection: "column" }} >
                                            {
                                                    categorie.img_url&&( <CardMedia
                                                        style={{ height: "100" }}
                                                        image={categorie.img_url}
                                                        title={capitalizeFirstLetter(categorie.name)}
                                                        style={{ height: "200px",    width: "200px",border: "1px solid #00B6A9",backgroundSize: "contain" }}
                                                    />)
                                                }
                                                <div style={{
                                                    position: "absolute",
                                                    right: 80,
                                                    top: 7
                                                }}>
                                                    <Tooltip title={"Sous catégorie"}>
                                                        <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="Sous catégorie" onClick={() => {
                                                            this.setState({ id: "", categorie: "", mode: 'create', parentCat: categorie.id, level: 2 });
                                                            this.handleOpenModal()
                                                        }}>

                                                            <AddIcon style={{ color: "#28abb3" }} />


                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title={"EDITER"}>
                                                        <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="EDITER" onClick={() => {
                                                            this.setState({ id: categorie.id, categorie: categorie.name, mode: 'edit', level: 1,image:categorie.img_url });
                                                            this.handleOpenModal()
                                                        }}>

                                                            <EditIcon style={{ color: "#28abb3" }} />


                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={"Supprimer"}>
                                                        <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="SUPPRIMER" o onClick={() => {
                                                            this.setState({ id: categorie.id, categorie: categorie.name, mode: 'delete', level: 1 });
                                                            this.handleOpenModal();
                                                        }}>

                                                            <DeleteIcon style={{ color: "#28abb3" }} />


                                                        </IconButton>
                                                    </Tooltip>

                                                    <Tooltip title={"Activation"}>
                                                        <Switch
                                                            classes={{
                                                                switchBase: classes.colorSwitchBase,
                                                                checked: classes.colorChecked,
                                                                bar: classes.colorBar,
                                                              }}
                                                            onChange={(event) => {
                                                                this.handleSwitchChange(event, categorie.id, !categorie.state,1)
                                                            }}
                                                            checked={categorie.state}
                                                        />
                                                    </Tooltip>
                                                </div>
                                                {
                                                    categorie.subCategories && categorie.subCategories.map(sub => {
                                                        return (
                                                            <div className={classes.root} >
                                                            <ExpansionPanel style={{ width: "100%" }}>
                                                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                    <Typography className={classes.heading}>
                                                                    <span style={{"text-transform": "capitalize"}}>{sub.name}</span>
                                                                   </Typography>
                                                                </ExpansionPanelSummary>
                                                                <ExpansionPanelDetails style={{ flexDirection: "column" }} 
                                                                >
                                                                    <div style={{
                                                                        position: "absolute",
                                                                        right: 80,
                                                                        top: 7
                                                                    }}>
                                                                        <Tooltip title={"Sous catégorie"}>
                                                                            <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="Sous catégorie" onClick={() => {
                                                                                this.setState({ id: "", categorie: "", mode: 'create', parentCat: sub.id, level: 3 });
                                                                                this.handleOpenModal()
                                                                            }}>

                                                                                <AddIcon style={{ color: "#28abb3" }} />


                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        <Tooltip title={"EDITER"}>
                                                                            <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="EDITER" onClick={() => {
                                                                                this.setState({ id: sub.id, categorie: sub.name, mode: 'edit', level: 2 });
                                                                                this.handleOpenModal()
                                                                            }}>

                                                                                <EditIcon style={{ color: "#28abb3" }} />


                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title={"Supprimer"}>
                                                                            <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="SUPPRIMER" o onClick={() => {
                                                                                this.setState({ id: sub.id, categorie: sub.name, mode: 'delete', level: 2 });
                                                                                this.handleOpenModal();
                                                                            }}>

                                                                                <DeleteIcon style={{ color: "#28abb3" }} />


                                                                            </IconButton>
                                                                        </Tooltip>

                                                                        <Tooltip title={"Activation"}>
                                                                            <Switch
                                                                            classes={{
                                                                                switchBase: classes.colorSwitchBase,
                                                                                checked: classes.colorChecked,
                                                                                bar: classes.colorBar,
                                                                              }}
                                                                                onChange={(event) => {
                                                                                    this.handleSwitchChange(event, sub.id, !sub.state,2)
                                                                                }}
                                                                                checked={sub.state}
                                                                            />
                                                                        </Tooltip>
                                                                    </div>
                                                                    {
                                                                        sub.nestedSubCategories && sub.nestedSubCategories.map(nest => {
                                                                            return (
                                                                                <div className={classes.root}>
                                                                                <ExpansionPanel style={{ width: "100%" }}>
                                                                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                                                                        <Typography className={classes.heading}><span style={{"text-transform": "capitalize"}}>{nest.name}</span></Typography>
                                                                                    </ExpansionPanelSummary>
                                                                                    <ExpansionPanelDetails>
                                                                                        <div style={{
                                                                                            position: "absolute",
                                                                                            right: 80,
                                                                                            top: 7
                                                                                        }}>
                                                                                            <Tooltip title={"EDITER"}>
                                                                                                <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="EDITER" onClick={() => {
                                                                                                    this.setState({ id: nest.id, categorie: nest.name, mode: 'edit', level: 3 });
                                                                                                    this.handleOpenModal()
                                                                                                }}>

                                                                                                    <EditIcon style={{ color: "#28abb3" }} />


                                                                                                </IconButton>
                                                                                            </Tooltip>

                                                                                            <Tooltip title={"Supprimer"}>
                                                                                                <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="SUPPRIMER" o onClick={() => {
                                                                                                    this.setState({ id: nest.id, categorie: nest.name, mode: 'delete', level: 3 });
                                                                                                    this.handleOpenModal();
                                                                                                }}>

                                                                                                    <DeleteIcon style={{ color: "#28abb3" }} />


                                                                                                </IconButton>
                                                                                            </Tooltip>

                                                                                            <Tooltip title={"Activation"}>
                                                                                                <Switch
                                                                                                classes={{
                                                                                                    switchBase: classes.colorSwitchBase,
                                                                                                    checked: classes.colorChecked,
                                                                                                    bar: classes.colorBar,
                                                                                                  }}
                                                                                                    onChange={(event) => {
                                                                                                        this.handleSwitchChange(event, nest.id, !nest.state,3)
                                                                                                    }}
                                                                                                    checked={nest.state}
                                                                                                />
                                                                                            </Tooltip>
                                                                                        </div>
                                                                                    </ExpansionPanelDetails>
                                                                                </ExpansionPanel>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </ExpansionPanelDetails>
                                                            </ExpansionPanel>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </ExpansionPanelDetails>
                                        </ExpansionPanel>
                                    </div>
                                )
                            })
                        }
                    </div>

                )}

                <Zoom
                    in={this.state.openFab}
                    timeout={transitionDuration}
                    style={{
                        position: "fixed",
                        right: 16,
                        bottom: 100,
                        transitionDelay: transitionDuration.exit,
                        backgroundColor: "rgba(30,180,115)",
                        color: "rgba(255,255,255)"
                    }}
                >
                    <Button
                        style={{ backgroundColor: "rgb(47, 194, 157)" }}
                        variant="fab"
                        onClick={() => {
                            this.setState({ id: "", categorie: "", mode: 'create', parentCat: "", level: 1 });
                            this.handleOpenModal();
                        }}
                    >
                        <AddIcon />
                    </Button>
                </Zoom>

            </div>
        );
    }
}


Categories.propTypes = {
    classes: PropTypes.object.isRequired
};

export default compose(graphql(LOAD_CATEGORIES_QUERY, {
    name: "getCategories"
}),
    graphql(ADD_CATEGORIE_MUTATION, {
        name: "addCategory"
    }),
    graphql(ENABLED_CATEGORIE_MUTATION, {
        name: "enabledCategory"
    }),
    graphql(DISABLED_CATEGORIE_MUTATION, {
        name: "disabledCategory"
    }),
    graphql(REMOVE_CATEGORIE_MUTATION, {
        name: "removeCategory"
    }),
    graphql(UPDATE_CATEGORIE_MUTATION, {
        name: "updateCategory"
    }),


    graphql(ADD_SUB_CATEGORIE_MUTATION, {
        name: "addSubCategory"
    }),
    graphql(ENABLED_SUB_CATEGORIE_MUTATION, {
        name: "enabledSubCategory"
    }),
    graphql(DISABLED_SUB_CATEGORIE_MUTATION, {
        name: "disabledSubCategory"
    }),
    graphql(REMOVE_SUB_CATEGORIE_MUTATION, {
        name: "removeSubCategory"
    }),
    graphql(UPDATE_SUB_CATEGORIE_MUTATION, {
        name: "updateSubCategory"
    }),


    graphql(ADD_NESTED_SUB_CATEGORIE_MUTATION, {
        name: "addNestedSubCategory"
    }),
    graphql(ENABLED_NESTED_SUB_CATEGORIE_MUTATION, {
        name: "enabledNestedSubCategory"
    }),
    graphql(DISABLED_NESTED_SUB_CATEGORIE_MUTATION, {
        name: "disabledNestedSubCategory"
    }),
    graphql(REMOVE_NESTED_SUB_CATEGORIE_MUTATION, {
        name: "removeNestedSubCategory"
    }),
    graphql(UPDATE_NESTED_SUB_CATEGORIE_MUTATION, {
        name: "updateNestedSubCategory"
    })

)(withRouter(withStyles(styles, { withTheme: true })(Categories)));
