import { DeliverymanTypes } from "../../types/deliveryman.types";

const INITIAL_STATE = {
    loading: false,
    requested: false,
    error: null, 
    data: []
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN:
            return {...state, loading: true, error: null};
        case DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN_ERROR:
            return {...state, loading: false, requested: true, error: action.error};


            case DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN:
            return {...state, loading: true, error: null};
        case DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
        default:
            return state;
    }
};