import gql from "graphql-tag";

import apolloClient from "../apolloClient";
import {ClientTypes} from "../types/client.types";

const LOAD_CLIENTS_QUERY = gql`
    query {
        getClients{
            id
            num
            profile{
                id
                email
                profile_pic_url
                is_verified
                is_blocked
                createdAt
            }
            commands{
                id
                num
            }
            
            
            
            first_name
            last_name
            tel
            address
            
        }
    }
`;


const LOAD_CLIENT_QUERY = gql`
query getClient ($id: ID!){ 
        getClient(id: $id) {
            id
            num
            first_name
            last_name
             tel
            
            address
            
        }
    }
`;
const loadClients = () => dispatch => {
    dispatch({type: ClientTypes.REQUEST_LOAD_CLIENTS});
    apolloClient.query({query: LOAD_CLIENTS_QUERY})
    .then(response => {
    
        dispatch({type: ClientTypes.REQUEST_LOAD_CLIENTS_SUCCESS, data: response.data.getClients});
    })
    .catch(error => {
  
        dispatch({type: ClientTypes.REQUEST_LOAD_CLIENTS_ERROR, error});
    });
};


const loadClient = (id) => dispatch => {
    dispatch({type: ClientTypes.REQUEST_LOAD_CLIENT});
    apolloClient.query({query: LOAD_CLIENT_QUERY})
    .then(response => {
      
        dispatch({type: ClientTypes.REQUEST_LOAD_CLIENT_SUCCESS, data: response.data.getClient(id)});
    })
    .catch(error => {
 
        dispatch({type: ClientTypes.REQUEST_LOAD_CLIENT_ERROR, error});
    });
};


export {
    loadClients,
    loadClient
};