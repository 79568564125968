import {AuthTypes} from "../../types/auth.types";

const INITIAL_STATE = {loading: false, requested: false, error: false, success: false};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthTypes.REQUEST_CHECK_TOKEN:
            return {...state, loading: true};
        case AuthTypes.REQUEST_CHECK_TOKEN_SUCCESS:
            return {...state, loading: false, requested: true, success: true};
        case AuthTypes.REQUEST_CHECK_TOKEN_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
        default:
            return state;
    }
};