const capitalizeFirstLetter = (s: String) => {
    if (!s) {
        return s
    }
    if (s.length == 1) {
        return s.toUpperCase;
    }
    return s.substr(0, 1).toUpperCase() + s.substr(1);
}

export {
    capitalizeFirstLetter
};