import React from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";

import Typography from "@material-ui/core/Typography";

import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";


import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList"; 
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import {lighten} from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ListSubheader from '@material-ui/core/ListSubheader';

import Input from "@material-ui/core/Input";
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';

import MenuItem from '@material-ui/core/MenuItem';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import gql from "graphql-tag";


import { Mutation } from "react-apollo";
import { graphql, compose } from "react-apollo";
import { NotificationContainer, NotificationManager } from 'react-notifications';






/******LISTSTYLES************************************* */
const liststyles = theme => ({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
  });
  





function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}





class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                numeric={row.numeric}
                                padding={i == 0 ? "checkbox" : (row.disablePadding ? "none" : "default")}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
        padding:"50px"
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
});
// Button Style //
const buttonstyles = theme => ({

    
    button: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
    input: {
      display: 'none',
    },
    
  });

class EnhancedTableToolbar extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle">
                    
                    </Typography>
                </div>
                <div className={classes.spacer}/>
                <div className={classes.actions}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
                                <FilterListIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    colorSwitchBase: {

    },
    colorBar: {
    },
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500],
            },
        },
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    }
});
/***************************************************Page mettre à jour l' admin*************************************** */
const UPDATE_ADMIN_MUTATION = gql`
    mutation updateUserDetails($id:ID!,$password:String!,$profile_pic_url:Upload!) {
        updateUserDetails(id:$id,input: {password:$password,profile_pic_url:$profile_pic_url}) 
            {
            id 
            email
            
            
        }
    }
`;
const UPDATE_PASSWORD_QUERY = gql`
    mutation changePassword($oldPassword: String!, $password: String!) {
        changePassword(oldPassword: $oldPassword, password: $password)
    }
`;

function validate(email, password) {
    // true means invalid, so our conditions got reversed
    return {
      email: email.length === 0,
      password: password.length === 0,
    };
  }



class Profile extends React.Component{
    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
      };
    

    constructor() {
        super();
        this.state = {
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 5,
            email:'',
            password:'',
            oldPassword:'',
            everFocusedEmail: false,
      everFocusedPassword: false,
      inFocus: '',
      touched: {
        email: false,
        password: false,
        oldPassword: false,
      },

        };
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({order, orderBy});
    };

    handleChangePage = (event, page) => this.setState({page});

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({showFilter: !this.state.showFilter, filterText: ""});
        } else {
            this.setState({showFilter: !this.state.showFilter});
        }
    };

    handleFilterChange = ({target: {value}}) => this.setState({filterText: value});

    handleChange = prop => event => {
        this.setState({ [prop]: event.target.value });
      };
    
      handleMouseDownPassword = event => {
        event.preventDefault();
      };
    
      handleClickShowOldPassword = () => {
        this.setState(state => ({ showOldPassword: !state.showOldPassword }));
      };
      handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
      };

    componentWillMount() {
        
        
     
    }

    componentWillUnmount() {
        window.onscroll = null;
    }
    handleSubmit = () => {

        let { oldPassword,password } = this.state;

        this.props.mutate({
            variables: {oldPassword: this.state.oldPassword, password: this.state.password}
        })
            .then(response => {
                if (response.data.changePassword) {
                    NotificationManager.success('Le mot de passe a été mis à jour', 'Succès');
                    setTimeout(() => {
                        this.props.history.push('/')
                    }, 2000)
                } else {
                    NotificationManager.error("L'ancien mot de passe est incorrect", 'Erreur')
                }
                this.setState({loading: false});
            })
            .catch(error => {
           
                NotificationManager.error('La modification n\'a pas eu lieu', 'Erreur')
            });
    }
    
    
    render() {

        const shouldMarkError = (field) => {
            const hasError = errors[field];
            const shouldShow = this.state.touched[field];
            return hasError ? shouldShow : false;
          };
        const errors = validate(this.state.oldPassword, this.state.password);
        const isDisabled = Object.keys(errors).some(x => errors[x]);
        
        const {classes, theme} = this.props;
        const {order, orderBy, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

       


        const { match, location, history } = this.props;
        
        
         
        
        
        return (
            
            <div >
                
                <Typography color="textSecondary" variant="headline">
                
                    
                </Typography>
           
                
               
                
                <Paper className={classes.root}>

                    
                    <div className={classes.tableWrapper}>
                        

                        
  
                            <div className={classes.root}>  

                             <Typography color="textSecondary" variant="headline">
                             <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        fontFamily: "Montserrat, sans-serif",
                                                        textTransform:"uppercase",
                                                        padding:"50px"
                                                    }}>
                                                        
                                                                
                                                        Paramètres de connexion

                                                    </div>


                                                    
        
                            </Typography> 
                            
                                            <form  style={{margin:"40px", width:"600px", marginLeft:"0", fontFamily: "Montserrat, sans-serif", padding:"50px"}} >
                                        
                                            <div>
                                            <Input
                                            required
                                             className={shouldMarkError('oldPassword') ? "error" : ""}
                                           
                                            value={this.state.oldPassword}
                                            onChange={e => this.setState({ oldPassword: e.target.value })}
                                                     id="adornment-password"
                                                     type={this.state.showOldPassword ? 'text' : 'password'}
                                                     value={this.state.oldPassword}
                                                    
                                                   placeholder="Ancien mot de passe"
                                                   className={classes.input}
                                                   fullWidth={true}
                                                   style={{    FontSize: "16px",
                                                    lineHeight: "24px",
                                                    Width: "100% ",
                                                    Height: "48px",
                                                    marginBottom: "20px",
                                                    
                                                    
                                                    MarginTop: "15px"}}
                                                   endAdornment={
                                                    <InputAdornment position="end">
                                                     <IconButton
                                                         aria-label="Toggle password visibility"
                                                         onClick={this.handleClickShowOldPassword}
                                                           onMouseDown={this.handleMouseDownOldPassword}>
                                                       {this.state.showOldPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                      </InputAdornment>
                                                  }
                                             inputProps={{'aria-label': 'Description',}}/>
                                             </div>
                                             <div>
                                            <Input
                                            required
                                             className={shouldMarkError('password') ? "error" : ""}
                                           
                                             value={this.state.password}
                                             onChange={e => {
                                                this.setState({ password:e.target.value });
                                            }}
                                                     id="adornment-password"
                                                     type={this.state.showPassword ? 'text' : 'password'}
                                                     
                                                    
                                                   placeholder="Nouveau mot de passe"
                                                   className={classes.input}
                                                   fullWidth={true}
                                                   style={{    FontSize: "16px",
                                                    lineHeight: "24px",
                                                    
                                                    Width: "100% ",
                                                    Height: "48px",
                                                    
                                                    
                                                    MarginTop: "15px"}}
                                                   endAdornment={
                                                    <InputAdornment position="end">
                                                     <IconButton
                                                         aria-label="Toggle password visibility"
                                                         onClick={this.handleClickShowPassword}
                                                           onMouseDown={this.handleMouseDownPassword}>
                                                       {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                                                                </IconButton>
                                                      </InputAdornment>
                                                  }
                                             inputProps={{'aria-label': 'Description',}}/>
                                             </div>
                                             <NotificationContainer/>
                                             <Button disabled={isDisabled} variant="contained"  primary
                                                             style={ {height:"36px", marginTop: 40, width:300,
                                                                marginBottom: 15,backgroundColor:"rgba(3, 180, 115)", backgroundImage:"linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",  color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color:"rgb(255, 255, 255)"}}
                                                               fullWidth={true}
                                                                label="Ajout"
                                                                onClick={()=> this.handleSubmit()}
                                                              >
                                                              <div>
                                                                  Mettre à jour
                                                                  </div>
                                                              </Button>

                                                              

                                                             

                                                                
                                        </form>     
                                                      
                                               
                                        
                                    
                               
                            
                            </div>
                            
                        
                        
                    </div>
                    
                    
                </Paper>
                
                
            </div>
        );
    
    }
}
export default compose(
    graphql(UPDATE_PASSWORD_QUERY),
)(((withStyles()
(Profile))))