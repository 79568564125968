import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { loadPharmacy } from "../actions";
import gql from "graphql-tag";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { graphql, compose } from "react-apollo";

const styles = theme => ({
  root: {
    width: '90%',
  },
  button: {
    marginRight: theme.spacing.unit,
  },
  instructions: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});


const LOAD_PAHRMACY_QUERY = gql`
    query getPharmacy($id: ID!){
        getPharmacy(id: $id) {
            id
            is_pill_maker
            tel
            name
            website
            commands{
                id
                
            }
            
            profile {
                id
                email
                profile_pic_url
                
            }
          
            country
            address
            city
            zip_code

            work_schedule{planing{monday{open_at
              close_at}}}
            
            
        }
    }
`;
class HorizontalNonLinearStepperWithError extends React.Component {
  state = {
    activeStep: 0,
    skipped: new Set(),
  };

  constructor() {
    super();
    this.state = {
      
        
        
    };
   
}


  componentWillReceiveProps(nextProps) {
    if (nextProps.data !== this.props.data) {

        this.setState({ pharmacy: nextProps.data.getPharmacy ? nextProps.data.getPharmacy : {} });

    }
    
}


 

  isStepOptional = step => {
    return step === 1;
  };

  isStepFailed = step => {
    return step === 1;
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    this.setState({
      activeStep: activeStep - 1,
    });
  };

  handleSkip = () => {
    const { activeStep } = this.state;
    if (!this.isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    this.setState(state => {
      const skipped = new Set(state.skipped.values());
      skipped.add(activeStep);
      return {
        activeStep: state.activeStep + 1,
        skipped,
      };
    });
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  isStepSkipped(step) {
    return this.state.skipped.has(step);
  }

  render() {



    function getSteps  () {
     // return [pharmacy.work_schedule.planing.monday.open_at, 'PAUSE', pharmacy.work_schedule.planing.monday.close_at];
    }
     
    
    
    
    function
      getStepContent  (step) {
      switch (step) {
        case 0:
          return '';
        case 1:
          return '';
        case 2:
          return '';
        default:
          return '';
      }
    }
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (

      <div className={classes.root}>
       { /*<Stepper >
          {steps.map((label, index) => {
            const props = {};
            const labelProps = {};
            
              
            
        
            
           
              props.completed = false;
            
            return (
              <Step key={label} {...props}>
                <StepLabel {...labelProps}>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
         */} 
      </div>
    );
  }
}

HorizontalNonLinearStepperWithError.propTypes = {
  classes: PropTypes.object,
};
const mapStateToProps = ({ pharmacy }) => ({ pharmacy });

const mapDispatchToProps = dispatch => ({
    loadPharmacy: bindActionCreators(loadPharmacy, dispatch),

});


export default 
    graphql(LOAD_PAHRMACY_QUERY, {
        options: props => {
       
            return ({
            variables: {
                id: window.location.search.substring(7)
            }
        })}
    })
    
(connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(HorizontalNonLinearStepperWithError))))

