import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadClients } from '../actions';
import { capitalizeFirstLetter } from '../tools/utils';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Eye from '@material-ui/icons/RemoveRedEye';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Modal from '@material-ui/core/Modal';
import Toolbar from '@material-ui/core/Toolbar';
import CardMedia from '@material-ui/core/CardMedia';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/Inbox';
import ArrowBack from '@material-ui/icons/ArrowBack';
import DraftsIcon from '@material-ui/icons/Drafts';
import ListSubheader from '@material-ui/core/ListSubheader';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import MapComponent from './MapComponent';
import admins from './Admins';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Badge from '@material-ui/core/Badge';
import Assignment from '@material-ui/icons/Assignment';
import BlockIcon from '@material-ui/icons/Block';
import MediaQuery from 'react-responsive';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
/******LISTSTYLES************************************* */
const liststyles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	},
	boxFlex: {
		display: 'flex'
	},

	'@media screen and (max-width: 991px)': {
		firstTitle: {
			fontSize: '22px !important',
			textAlign: 'center !important',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		boxFlex: {
			flexDirection: 'column'
		}
	}
});

function getModalStyle() {
	const top = 40;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'NOM' },
	{ id: 'website', numeric: true, disablePadding: false, label: 'SITE WEB' },
	{ id: 'tel', numeric: true, disablePadding: false, label: 'TELEPHONE' },
	{ id: 'city', numeric: true, disablePadding: false, label: 'VILLE' },
	{ id: '', numeric: true, disablePadding: false, label: 'NB DE COMMANDE' },
	{ id: '', numeric: true, disablePadding: false, label: 'COMMANDES ' },
	{ id: '', numeric: true, disablePadding: false, label: 'AFFICHER ' },
	{ id: '', numeric: true, disablePadding: false, label: 'EDITER ' },
	{ id: '', numeric: true, disablePadding: false, label: 'DÉSACTIVER ' }
];

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	boxFlex: {
		display: 'flex'
	},

	'@media screen and (max-width: 991px)': {
		firstTitle: {
			fontSize: '22px !important',
			textAlign: 'center !important',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		boxFlex: {
			flexDirection: 'column'
		}
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //
const buttonstyles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	input: {
		display: 'none'
	}
});

class EnhancedTableToolbar extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	render() {
		const { classes, produit, history, handleModalOpen, location } = this.props;

		return (
			<Toolbar className={classNames(classes.boxFlex)}>
				<div className={classes.title}>
					<Typography
						style={{
							textAlign: 'center',
							fontSize: 20,
							marginTop: 10,
							fontWeight: 'bold',
							fontFamily: 'Montserrat, sans-serif'
						}}
						variant="title"
						id="tableTitle"
					>
						Detail du produit
					</Typography>
				</div>
				<div className={classes.spacer} />
				<div className={classes.actions}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Tooltip title={'Retour'}>
							<IconButton
								style={{ fill: '#28abb3' }}
								aria-label="Retour"
								onClick={() => history.push({ pathname: `/produits`, state: location.state })}
							>
								<ArrowBack />
							</IconButton>
						</Tooltip>
						<Tooltip title={'EDITER'}>
							<IconButton
								style={{ fill: '#28abb3' }}
								aria-label="EDITER"
								onClick={() => this.props.history.push(`/produits/produit?id=${produit.id}`)}
							>
								<EditIcon style={{ color: '#28abb3' }} />
							</IconButton>
						</Tooltip>
						{!produit.state ? (
							<IconButton className={classes.button} aria-label="" onClick={() => handleModalOpen(1)}>
								<BlockIcon style={{ color: 'rgba(232,27,12)' }} />
							</IconButton>
						) : (
							<IconButton className={classes.button} aria-label="" onClick={() => handleModalOpen(0)}>
								<CheckCircleOutlineIcon style={{ color: '#28abb3' }} />
							</IconButton>
						)}

						<Tooltip title="Rafraîchir">
							<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title={'Supprimer'}>
							<IconButton style={{ fill: '#28abb3' }} aria-label="SUPPRIMER" onClick={() => handleModalOpen(2)}>
								<DeleteIcon style={{ color: '#28abb3' }} />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</Toolbar>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 70,
		height: 70,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	paper: {
		position: 'absolute',
		width: theme.spacing.unit * 50,
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4
	}
});
/***************************************************Page PHARMACY************************************************* */
const LOAD_PRODUIT_QUERY = gql`
	query getParaPharmacyById($id: ID!) {
		getParaPharmacyById(id: $id) {
			id
			name
			laboratory
			description
			img_url
			price
			bar_code
			indications
			usage_tips
			composition
			precautions
			state
			category_id
			sub_category_id
			nested_sub_category_id
			category {
				id
				name
			}
			sub_category {
				id
				name
			}
			nested_sub_category {
				id
				name
			}
		}
	}
`;
const ENABLED_PRODUIT_MUTATION = gql`
	mutation enableParaPharmacy($id: ID!) {
		enableParaPharmacy(id: $id) {
			id
			name
			laboratory
			description
			img_url
			price
			indications
			usage_tips
			composition
			precautions
			state
		}
	}
`;
const DISABLED_PRODUIT_MUTATION = gql`
	mutation disableParaPharmacy($id: ID!) {
		disableParaPharmacy(id: $id) {
			id
			name
			laboratory
			description
			img_url
			price
			indications
			usage_tips
			composition
			precautions
			state
		}
	}
`;
const REMOVE_PRODUIT_MUTATION = gql`
	mutation removeParaPharmacy($id: ID!) {
		removeParaPharmacy(id: $id) {
			id
		}
	}
`;

class ProduitDetail extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor() {
		super();
		this.state = {
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: 5,
			openModal: false,
			type: 0
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		handleChangePage = (event, page) => this.setState({ page });

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}
	handleModalOpen = type => {
		this.setState({ openModal: true, type });
	};
	handleClose = () => {
		this.setState({ openModal: false });
	};

	render() {
		const produit = this.props.getProduit && this.props.getProduit.getParaPharmacyById;
		const categorie = produit && produit.category;
		const subCategorie = produit && produit.sub_category;
		const nestedSubCategorie = produit && produit.nested_sub_category;

		const { classes, theme } = this.props;
		const { order, orderBy, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

		const { match, location, history } = this.props;


		return (
			<div>
				<Modal
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
					open={this.state.openModal}
					onClose={this.handleClose}
				>
					<div style={getModalStyle()} className={classes.paper}>
						<Typography variant="title" id="simple-modal-description" style={{ marginBottom: '30px' }}>
							{this.state.type == 0 ? 'Désactiver' : this.state.type == 1 ? 'Activer' : 'Supprimer'}
						</Typography>
						<div style={{ marginLeft: 10 }}>
							<div
								style={{
									padding: 10,
									display: 'flex',
									'justify-content': 'space-between'
								}}
							>
								<Button
									color="default"
									style={{
										background: '#00B0AB',
										color: 'white'
									}}
									className={classes.button}
									onClick={() => {
										let action = this.state.type == 1 ? 'enabled' : this.state.type == 0 ? 'disabled' : 'delete';
										this.props
											[action]({
												variables: {
													id: produit.id
												}
											})
											.then(response => {
												this.handleClose();
												if (this.state.type == 2) {
													this.props.history.push({ pathname: '/produits', state: { refetch: true } });
												} else {
													this.props.getProduit && this.props.getProduit.refetch();
												}
											
											});
									}}
								>
									{this.state.type == 0 ? 'Désactiver' : this.state.type == 1 ? 'Activer' : 'Supprimer'}
								</Button>
								<Button
									color="secondary"
									className={classes.button}
									onClick={() => {
										this.handleClose();
									}}
								>
									Annuler
								</Button>
							</div>
						</div>
					</div>
				</Modal>

				{this.props.getProduit &&
					this.props.getProduit.loading && (
						<div className={classes.progressContainer}>
							<Fade in timeout={300}>
								<Typography variant="subheading" color="textSecondary">
									Chargement...
								</Typography>
							</Fade>
						</div>
					)}
				{this.props.getProduit &&
					this.props.getProduit.getParaPharmacyById && (
						<Paper className={classes.root}>
							<EnhancedTableToolbar
								refresh={this.props.getProduit.refresh}
								toggleFilter={this.toggleFilter}
								produit={produit}
								history={this.props.history}
								location={this.props.location}
								handleModalOpen={this.handleModalOpen}
								isFilterShowen={this.state.showFilter}
							/>
							<div className={classes.tableWrapper}>
								{this.state.showFilter && (
									<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<Zoom in={true} timeout={200}>
											<TextField
												style={{ marginRight: 20 }}
												placeholder={'Chercher...'}
												autoFocus
												value={this.state.filterText}
												onChange={this.handleFilterChange}
											/>
										</Zoom>
									</div>
								)}

								<div className={classes.root}>
									<Typography color="textSecondary" variant="headline">
										<div
											style={{
												display: 'flex',
												flexDirection: 'row',
												alignItems: 'center',
												color: '#27aab3',
												marginLeft: '20px',
												fontFamily: 'Montserrat, sans-serif'
											}}
										>
											{/* <Avatar alt="avatar"
                                                        src={produit.img_url?produit.img_url : "http://pharmaloop.fr/img/profile-icon.png"}
                                                        style={{ marginRight: "20px", width: 70, height: 70 }}
                                                    /> */}
										</div>
									</Typography>
									<div>
										<List component="nav">
											<ListItem>
												<ListItemText
													primary={
														<span
															style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold', color: '#00C0A6' }}
														>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{
																	fontFamily: 'Montserrat, sans-serif',
																	'font-weight': 'bold',
																	color: '#00C0A6',
																	textTransform: 'capitalize'
																}}
															>
																{categorie.name + ' > ' + subCategorie.name + ' > ' + nestedSubCategorie.name}
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
												/>
											</ListItem>
											{produit.img_url && (
												<ListItem>
													<CardMedia
														style={{ height: '100' }}
														image={produit.img_url}
														title={capitalizeFirstLetter(produit.name)}
														style={{ height: '200px', width: '200px', border: '1px solid #00B6A9',    backgroundPosition:"center",
														backgroundSize:"contain", }}
													/>
												</ListItem>
											)}
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Nom
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.name}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Code EAN
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.bar_code}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Laboratoire
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.laboratory}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Description
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.description}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Indications
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.indications}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Usage
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.usage_tips}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Composition
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.composition}
												/>
											</ListItem>
											<ListItem>
												<ListItemText
													primary={
														<span style={{ fontFamily: 'Montserrat, sans-serif' }}>
															{' '}
															<Typography
																variant="subheading"
																color="textSecondary"
																style={{ fontFamily: 'Montserrat, sans-serif', 'font-weight': 'bold' }}
															>
																Précautions
															</Typography>
														</span>
													}
													style={{ fontFamily: 'Montserrat, sans-serif' }}
													secondary={produit.precautions}
												/>
											</ListItem>
										</List>
									</div>
								</div>
							</div>
						</Paper>
					)}
			</div>
		);
	}
}

ProduitDetail.propTypes = {
	classes: PropTypes.object.isRequired
};

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default compose(
	graphql(LOAD_PRODUIT_QUERY, {
		name: 'getProduit',
		options: props => {
			return {
				variables: {
					id: window.location.hash.substring(window.location.hash.indexOf('id=') + 3)
					// id:"5c17c74d6a701e65b4994545"
				}
			};
		}
	}),
	graphql(ENABLED_PRODUIT_MUTATION, { name: 'enabled' }),
	graphql(DISABLED_PRODUIT_MUTATION, { name: 'disabled' }),
	graphql(REMOVE_PRODUIT_MUTATION, { name: 'delete' })
)(withRouter(withStyles(styles, { withTheme: true })(ProduitDetail)));
