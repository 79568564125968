import {AdminTypes} from "../../types/admin.types";

const INITIAL_STATE = {
    loading: false,
    requested: false,
    data: [],
    error: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AdminTypes.REQUEST_LOAD_ADMINS:
            return {...state, loading: true, requested: false, error: null};
        case AdminTypes.REQUEST_LOAD_ADMINS_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case AdminTypes.REQUEST_LOAD_ADMINS_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
            case AdminTypes.REQUEST_LOAD_ADMIN:
            return {...state, loading: true, requested: false, error: null};
        case AdminTypes.REQUEST_LOAD_ADMIN_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case AdminTypes.REQUEST_LOAD_ADMIN_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
        default:
            return state;
    }
};