import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { login } from "../actions";
import LockIcon from "@material-ui/icons/Lock";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";


const styles = theme => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    textField: {
      marginLeft: theme.spacing.unit,
      marginRight: theme.spacing.unit,
      width: 200,
    },
    dense: {
      marginTop: 19,
    },
    menu: {
      width: 200,
    },
  });

class Login extends React.Component {

    constructor() {
        super();
        this.state = { email: "", password: "" };
    }

    handleTextChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    };
    
    

    render() {
        
        return (
            <div style={{
                display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh",

                justifyContent: "center",
                backgroundImage: " url(http://pharmaloop.fr/img/login-bg.jpg)",
                backgroundSize: "cover"
            }}>
             
                <div style={{
                    color: "rgba(0, 0, 0, 0.87)",
                    backgroundColor: "rgb(255, 255, 255)",
                    transition: "all 450ms",

                    cubicBezier: "(0.23, 1, 0.32, 1) 0ms",
                    padding: "15px",
                    width: "320px",

                    boxSizing: "border-box",
                    fontFamily: "sans-serif",


                    borderRadius: "2px",
                    zIndex: "1",
                    miWidth: "300px"
                }}>
             
                    <div style={{
                        paddingBottom: "0px",
                    }}>
                    
                        <div style={{
                            textAlign: "center"
                        }}>
                           
                            <img style={{
                                    display: "inline-block",
                                    justifyContent: "center",
                                  
                                }}
                                src="http://pharmaloop.toolynk-lab.com/images/logo/logo.png"
                                />
                            <h4>Admin Connexion </h4>
                        </div>

                        <form>
                            <div style={{ padding: "0px 1em 1em" }}>
                                <div style={{ display: "flex" }}>
                                    <div style={{
                                        fontSize: "16px",
                                        lineHeight: "24px",
                                        width: "100%",
                                        height: "72px",
                                        display: "inline-block",
                                        position: "relative",
                                        backgroundColor: "transparent",
                                        fontFamily: "sans-serif",

                                        cursor: "auto"
                                    }}>
                                        <InputLabel  required style={{ position: "relative", lineHeight: "1px" }}>
                                                       Nom d'utilisateur
                                                            </InputLabel>
                                        <TextField required type="email" name="email" onChange={this.handleTextChange} disabled={this.props.auth.loading} style={{width: "100%"}}/>
                                       
                                       
                                        <br />
                                        <InputLabel  required style={{ position: "relative", lineHeight: "1px" }}>
                                                       Mot de passe
                                                            </InputLabel>
                                   
                            <TextField required type="password" name="password" onChange={this.handleTextChange} disabled={this.props.auth.loading} style={{width: "100%"}}/>
                            {this.props.auth.error && <h4 style={{ color: "red" }}>{this.props.auth.error}</h4>}
                            <br />
                            </div>
                                </div>
                            </div>
                            <div>
                                <div style={{color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "rgb(255, 255, 255)",
    
    boxSizing: "border-box",
    fontFamily: "sans-serif",
    
    boxShadow: "rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px",
    borderRadius: "2px",
    display: "inline-block",
    minWidth: "100%",
    marginRight: "8px",
    marginTop:"50px"}}>
                                    <Button onClick={() => this.props.login(this.state)} disabled={this.props.auth.loading} style={{
                                        border: "10px",
                                        boxSizing: "border-box",
                                        display: "inline-block",
                                        fontFamily: "sans-serif",
                                            
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    margin: "0px",
                                    padding: "0px",
                                    outline: "none",
                                    fontSize: "inherit",
                                    fontWeight: "inherit",
                                    position: "relative",
                                    height: "36px",
                                    lineHeight: "36px",
                                    width: "100%",
                                    borderRadius: "2px",
                                    
                                    backgroundColor: "rgb(3, 180, 115)",
                                    textAlign: "center"}}>se connecter</Button>
                                    <div>
                                        <div style={{}}>
                                       
                                        </div>
                                        </div>
                            </div>
                            </div>
                        </form>

                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = ({ auth }) => ({ auth });
const mapDispatchToProps = dispatch => ({
    login: bindActionCreators(login, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);