import React from 'react';
import { withStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCommands } from '../actions';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Eye from '@material-ui/icons/RemoveRedEye';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import { capitalizeFirstLetter } from '../tools/utils';
import FormControl from '@material-ui/core/FormControl';
import Toolbar from '@material-ui/core/Toolbar';
import MediaQuery from 'react-responsive';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import LocalShipping from '@material-ui/icons/LocalShipping';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import moment from 'moment';
import 'moment/locale/fr';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import ScheduleOutlined from '@material-ui/icons/ScheduleOutlined';
import Today from '@material-ui/icons/Today';
import Group from '@material-ui/icons/Group';
import Assignment from '@material-ui/icons/Assignment';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import CardTitle from '@material-ui/core/Card';
import LocalPharmacy from '@material-ui/icons/LocalPharmacy';
import AddLocation from '@material-ui/icons/AddLocation';
import Excel from './Excel';
import DatePicker from 'react-datepicker';
import CustIcon from './cust-icon';
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
moment.locale('fr');
const stylesWelcome = {
	welcome: { marginBottom: '1em', fontFamily: 'Montserrat, sans-serif' },
	button: {
		width: 300,
		margin: '1.5%',
		backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
		color: 'rgb(255, 255, 255)'
	},
	flex: { display: 'flex', marginBottom: '2em', padding: 10 },
	leftCol: { flex: 1, marginRight: '1em', display: 'block', cursor: 'pointer' },
	rightCol: { flex: 1, marginLeft: '1em', cursor: 'pointer' },
	singleCol: { flex: 1, cursor: 'pointer' }
};
const stylesIcon = {
	card: { borderLeft: 'solid 4px rgb(40, 172, 179)', flex: 1 },
	icon: { float: 'right', width: 64, height: 64, padding: 16, color: 'rgb(40, 172, 179)' }
};
const style = theme => ({
	container: {
		flexGrow: 1
	},
	card: {
		marginTop: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 3
	}
});

/************************************************************TABLE OF COMMANDS************************************************/
function desc(a, b, orderBy) {
	if (orderBy == 'pharmacy') {
		let aPharmacy = a.pharmacy.name;
		let bPharmacy = b.pharmacy.name;
		if (bPharmacy < aPharmacy) {
			return -1;
		}
		if (bPharmacy > aPharmacy) {
			return 1;
		}
		return 0;
	} else if (orderBy == 'client') {
		let aClient = a.client.last_name + ' ' + a.client.first_name;
		let bClient = b.client.last_name + ' ' + b.client.first_name;
		if (bClient < aClient) {
			return -1;
		}
		if (bClient > aClient) {
			return 1;
		}
		return 0;
	} else if (orderBy == 'delivery_mode') {
		let aDeliveryMode =
			a.delivery_mode == 'FREE' ? 'Click & Collect' : a.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		let bDeliveryMode =
			b.delivery_mode == 'FREE' ? 'Click & Collect' : b.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		if (bDeliveryMode < aDeliveryMode) {
			return -1;
		}
		if (bDeliveryMode > aDeliveryMode) {
			return 1;
		}
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function getCmdState(state) {
	let st = '';
	switch (state) {
		case 'PENDING':
			st = 'En Attente';
			break;
		case 'REVOKED':
			st = 'Annulée';
			break;
		case 'APPROVED':
			st = 'Acceptée';
			break;
		case 'TO_PAY':
			st = 'Attente de paiement';
			break;
		case 'PAID':
			st = 'Payée';
			break;
		case 'DELIVERING':
			st = 'En cours de livraison';
			break;
		case 'DELIVERED':
			st = 'Livrée';
			break;
		case 'CANCELED':
			st = 'Annulée';
			break;
		case 'VOIDED':
			st = '--';
			break;
	}
	return st;
}
const rows = [
	{ id: 'num', numeric: false, disablePadding: true, label: 'N° DE COMMANDE', key: 'num' },
	{ id: 'createdAt', numeric: false, disablePadding: true, label: 'DATE', key: 'createdAt' },
	{ id: 'state', numeric: false, disablePadding: true, label: 'STATUT ', key: 'state' },
	{ id: 'pharmacy', numeric: false, disablePadding: true, label: 'PHARMACIE', key: 'pharmacy' },
	{ id: 'client', numeric: false, disablePadding: true, label: 'PATIENT', key: 'client' },
	{ id: 'delivery_date', numeric: false, disablePadding: true, label: 'DATE DE LIVRAISON', key: 'delivery_date' },
	{ id: '', numeric: false, disablePadding: true, label: 'MODE DE LIVRAISON', key: 'delivery_mode' },
	{ id: '', numeric: false, disablePadding: true, label: '', id: 'actions' }
];
const columns = props => [
	{
		label: 'prim',
		primary: 'primary',
		key: 'id',
		render: id => {
			let command = {};
			if (props.data && props.data.getCommands)
				props.data.getCommands.forEach(cmd => {
					if (cmd.id == id) {
						return (command = cmd);
					}
				});

			return (
				<span style={{ fontSize: 13 }}>
					{command.num +
						'-' +
						(command.createdAt ? moment(command.createdAt).format('LL') : '') +
						' - ' +
						(getCmdState(command.state) +
							' - ' +
							(command.delivery_mode == 'FREE'
								? 'Click & Collect'
								: command.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée'))}
				</span>
			);
		}
	},
	{ label: 'N° DE COMMANDE', key: 'num' },
	{
		label: 'DATE',
		key: 'createdAt',
		render: createdAt => {
			return createdAt ? moment(createdAt).format('LLL') : '';
		}
	},
	{
		label: 'STATUT ',
		key: 'state',
		render: state => {
			return getCmdState(state);
		}
	},
	{
		label: 'PHARMACIE',
		key: 'pharmacy',
		render: value => {
			return (
				<div
					style={{
						textTransform: 'uppercase',
						color: '#28acb3',
						fontWeight: 'bold',
						textAlign: 'left',
						fontFamily: 'Montserrat, sans-serif',
						cursor: 'pointer'
					}}
					onClick={() => props.history.push(`/pharmacies/content?id=${value.id}`)}
				>
					<a>{capitalizeFirstLetter(value.name)}</a>
				</div>
			);
		}
	},
	{
		label: 'PATIENT',
		key: 'client',
		render: value => {
			return (
				<div
					style={{
						textTransform: 'uppercase',
						color: '#28acb3',
						fontWeight: 'bold',
						textAlign: 'left',
						fontFamily: 'Montserrat, sans-serif',
						cursor: 'pointer'
					}}
					onClick={() => props.history.push(`/admins/content?id=${value.id}`)}
				>
					{capitalizeFirstLetter(value.last_name) + '  ' + capitalizeFirstLetter(value.first_name)}
				</div>
			);
		}
	},
	{
		label: 'DATE DE LIVRAISON',
		key: 'delivery_date',
		render: delivery_date => {
			return delivery_date ? moment(delivery_date).format('LLL') : '';
		}
	},
	{
		label: 'MODE DE LIVRAISON',
		key: 'delivery_mode',
		render: delivery_mode => {
			return delivery_mode == 'FREE' ? 'Click & Collect' : delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		}
	},
	{
		label: '',
		key: 'id',
		render: value => {
			let command = {};
			if (props.data && props.data.getCommands)
				props.data.getCommands.forEach(cmd => {
					if (cmd.id == value) {
						return (command = cmd);
					}
				});
		
			return (
				<div style={{ display: 'flex' }}>
					<Tooltip title="AFFICHER">
						<IconButton
							className={props.classes.button}
							aria-label="AFFICHER"
							onClick={() => props.history.push(`/commands/content?id=${command.id}`)}
						>
							<Eye style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
						</IconButton>
					</Tooltip>
					{command.tracking_url ? (
						<Tooltip title="TRACKING">
							<IconButton
								className={props.classes.button}
								aria-label="TRACKING"
								onClick={() => window.open(command.tracking_url, '_blank')}
							>
								<DirectionsBike style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
							</IconButton>
						</Tooltip>
					) : (
						''
					)}
				</div>
			);
		}
	}
];
const buttonNormal = {
	height: '36px',
	marginTop: 15,
	marginRight: 15,
	width: '300px',
	marginBottom: 15,
	backgroundColor: 'rgba(3, 180, 115)',
	backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
	color: 'rgb(255, 255, 255)',
	fontFamily: 'Montserrat, sans-serif'
};
const buttonClicked = {
	height: '36px',
	marginTop: 15,
	marginRight: 15,
	width: '300px',
	marginBottom: 15,
	backgroundColor: 'rgba(3, 180, 115)',
	backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
	color: 'red',
	fontSize: 'bold',
	fontFamily: 'Montserrat, sans-serif'
};

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy, classes } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								style={{ textAlign: 'left', fontFamily: 'Montserrat, sans-serif' }}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
								className={classes.tableHead}
							>
								{row.id == 'state' || row.id == '' ? (
									<TableSortLabel style={{ flexDirection: 'column', fontWeight: 'bold' }}>{row.label}</TableSortLabel>
								) : (
									<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
										<TableSortLabel
											style={{ flexDirection: 'column', fontWeight: 'bold' }}
											active={orderBy === row.id}
											direction={order}
											onClick={this.createSortHandler(row.id)}
										>
											{row.label}
										</TableSortLabel>
									</Tooltip>
								)}
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	firstTitle: {
		fontSize: 16,
		fontWeight: 'bold'
	},
	boxFlex: {
		display: 'flex'
	},

	'@media screen and (max-width: 991px)': {
		boxFlex: {
			flexDirection: 'column'
		}
	},
	'@media screen and (max-width: 1179px)': {
		firstTitle: {
			fontSize: '1rem  !important'
		}
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //
const buttonstyles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	input: {
		display: 'none'
	}
});
const LOAD_COMMANDS_QUERY = gql`
	query {
		getCommands {
			id
			num
			to_pay
			delivery_date
			address
			second_address
			refunded
			pharmacy {
				id
				num
				name
				address
			}
			client {
				id
				num
				first_name
				last_name
				address
				tel
			}
			createdAt
			state
			delivery_mode
			tracking_url
		}
	}
`;
const statuts = [
	{
		name: 'En Attente',
		value: 'PENDING'
	},
	{
		name: 'En Cours',
		value: 'APPROVED'
	},
	{
		name: 'A payer',
		value: 'TO_PAY'
	},
	{
		name: 'Payée',
		value: 'PAID'
	},
	{
		name: 'En Livraison',
		value: 'DELIVERING'
	},
	{
		name: 'Livrée',
		value: 'DELIVERED'
	},
	{
		name: 'Annulée',
		value: 'REVOKED'
	}
];

class EnhancedTableToolbar extends React.Component {
	render() {
		const { classes } = this.props;
		let style = {};
		return (
			<div>
				<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
					<Toolbar className={classNames(classes.boxFlex)}>
						<div className={classes.title}>
							<Typography
								style={{
									textAlign: 'center',
									fontSize: 18,
									marginTop: '-10px',
									fontWeight: 'bold',
									fontFamily: 'Montserrat, sans-serif'
								}}
								variant="title"
								id="tableTitle"
							>
								Liste des Commandes
							</Typography>
						</div>

						<div className={classes.spacer} />
						<div className={classes.actions}>
							<div style={{ display: 'flex', flexDirection: 'row' }}>
								<div style={{ width: 155 }}>
									<FormControl className={classes.formControl}>
										<Select
											style={{
												borderLeft: '1px solid grey ',
												borderTop: '1px solid grey ',
												borderRight: '1px solid grey ',
												paddingLeft: '10px',
												fontSize: '13px'
											}}
											native
											onChange={this.props.handleStatutChange}
											inputProps={{
												name: 'age',
												id: 'age-native-simple'
											}}
										>
											<option value="-1">Tous les Statuts</option>
											{statuts.map(el => {
												return <option value={el.value}>{el.name}</option>;
											})}
										</Select>
									</FormControl>
								</div>
								<div style={{ display: 'flex', flexDirection: 'column', marginRight: 10, width: '125px' }}>
									Rechercher du
								</div>
								<div
									style={{
										height: '50px',
										display: 'flex',
										flexDirection: 'column',
										marginRight: 10
									}}
								>
									<DatePicker
										style={{ height: '24px' }}
										selected={this.props.startDate}
										onChange={this.props.handleChangeDatePicker1}
										placeholderText="Date  "
									/>
								</div>
								<span style={{ marginRight: 10 }}>au</span>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<DatePicker
										selected={this.props.endDate}
										onChange={this.props.handleChangeDatePicker2}
										placeholderText="Date  "
									/>
								</div>
								<Excel {...this.props.excelProps} />
								<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
									<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
										<FilterListIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Rafraîchir">
									<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</div>
							<div />
						</div>
					</Toolbar>
				</Hidden>
				<Hidden only={['xl', 'lg']} implementation={'js'}>
					<Toolbar className={classNames(classes.root)} style={{ display: 'block' }}>
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div className={classes.title}>
								<Typography
									style={{
										textAlign: 'center',
										fontSize: 18,
										fontWeight: 'bold',
										fontFamily: 'Montserrat, sans-serif'
									}}
									variant="title"
									id="tableTitle"
								>
									Liste des Commandes
								</Typography>
							</div>
							<div style={{ marginTop: -16 }}>
								<Excel {...this.props.excelProps} />
								<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
									<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
										<FilterListIcon />
									</IconButton>
								</Tooltip>
								<Tooltip title="Rafraîchir">
									<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
										<RefreshIcon />
									</IconButton>
								</Tooltip>
							</div>
						</div>

						<div className={classes.spacer} />
						<div className={classes.actions}>
							<div style={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
								<div style={{ display: 'flex', margin: 18 }}>
									<div>
										<FormControl className={classes.formControl}>
											<Select
												native
												onChange={this.props.handleStatutChange}
												inputProps={{
													name: 'age',
													id: 'age-native-simple'
												}}
											>
												<option value="-1">Tous les Statuts</option>
												{statuts.map(el => {
													return <option value={el.value}>{el.name}</option>;
												})}
											</Select>
										</FormControl>
									</div>
								</div>

								<div
									style={{
										height: '50px',
										display: 'flex',
										flexDirection: 'column',
										marginRight: 10
									}}
								>
									<div style={{ display: 'flex', justifyContent: 'space-between' }}>
										<div>Rechercher du</div>
										<div>
											<DatePicker
												style={{ height: '24px' }}
												selected={this.props.startDate}
												onChange={this.props.handleChangeDatePicker1}
												placeholderText="Date  "
											/>
										</div>

										<span style={{ marginRight: 10 }}>au</span>
										<div style={{ display: 'flex', flexDirection: 'column' }}>
											<DatePicker
												selected={this.props.endDate}
												onChange={this.props.handleChangeDatePicker2}
												placeholderText="Date  "
											/>
										</div>
									</div>
								</div>
							</div>
							<div />
						</div>
					</Toolbar>
				</Hidden>
			</div>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);
const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	firstTitle: {
		fontSize: 24,
		lineHeight: 2,
		display: 'block',
		textTransform: 'uppercase',
		fontFamily: 'Montserrat, sans-serif'
	},

	secondTitle: {
		fontSize: 16,
		lineHeight: 2,
		display: 'block',
		textTransform: 'uppercase',
		fontFamily: 'Montserrat, sans-serif'
	},

	boxFlex: {
		display: 'flex',
		marginBottom: '2em'
	},

	'@media screen and (max-width: 1094px)': {
		firstTitle: {
			fontSize: 22,
			textAlign: 'center'
		},
		boxFlex: {
			display: 'flex',
			'flex-wrap': 'wrap'
		},
		boxFlex1: {
			margin: '0 !important',
			marginBottom: '2em !important'
		},
		ColXl6: {
			flex: 'inherit !important',
			width: ' 48%',
			margin: '1em 1% !important'
		}
	},

	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	tableCell: {
		fontSize: '14px !important'
	},
	tableHead: {
		fontSize: '14px !important'
	},
	'@media screen and  (min-width: 991px) and (max-width: 1390px)': {
		tableCell: {
			fontSize: '12px !important'
		},
		tableHead: {
			fontSize: '12px !important'
		}
	}
});

class EnhancedTable extends React.Component {
	constructor() {
		super();
		this.state = {
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: window.localStorage.getItem('admin_command_rows_per_page')
				? parseInt(window.localStorage.getItem('admin_command_rows_per_page'))
				: 25,
			type: 'all',
			toDay: false,
			value: 'recents',
			startDate: null,
			deliveryDate: null,
			endDate: null,
			status: -1,
			statuts: [
				{
					name: 'Tous les Statuts',
					value: '-1'
				},
				{
					name: 'En Attente',
					value: 'PENDING'
				},
				{
					name: 'En Cours',
					value: 'APPROVED'
				},
				{
					name: 'A payer',
					value: 'TO_PAY'
				},
				{
					name: 'Payée',
					value: 'PAID'
				},
				{
					name: 'En Livraison',
					value: 'DELIVERING'
				},
				{
					name: 'Livrée',
					value: 'DELIVERED'
				},
				{
					name: 'Annulée',
					value: ['REVOKED', 'CANCELED', 'VOIDED']
				}
			]
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
		this.handleChangeDatePicker1 = this.handleChangeDatePicker1.bind(this);
		this.handleChangeDatePicker2 = this.handleChangeDatePicker2.bind(this);
		this.handleChangeDatePicker3 = this.handleChangeDatePicker3.bind(this);
		this.handleStatutChange = this.handleStatutChange.bind(this.props);
	}
	handleChangeDatePicker1(date) {
		this.setState({ toDay: false });
		this.setState({
			startDate: date,
			deliveryDate: null
		});
	}
	handleChangeDatePicker2(date) {
		this.setState({ toDay: false });
		this.setState({
			endDate: date,
			deliveryDate: null
		});
	}
	handleChangeDatePicker3(date) {
		this.setState({ toDay: false });
		this.setState({
			deliveryDate: date,
			startDate: null,
			endDate: null
		});
	}
	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		window.localStorage.setItem('admin_command_rows_per_page', event.target.value);
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}
	componentWillReceiveProps(props) {
		if (props.location && props.location.state && props.location.state.type) {
			this.setState({ type: props.location.state.type, toDay: props.location.state.toDay });
		}
	}
	filterCommands = type => {
		this.setState({ type: type, toDay: false, page: 0 });
	};
	handleChange = (event, value) => {
		this.setState({ value });
	};
	handleStatutChange = event => {
	
		this.setState({ status: event.target.value });
	};
	setStatuts = cmds => {
		let statuts = [];
		let commands = cmds; //.filter(item => moment().format("MM-DD-YYYY") == moment(item.createdAt).format("MM-DD-YYYY"))
		commands &&
			commands.forEach(item => {
				if (item.state == 'REVOKED' || item.state == 'CANCELED' || item.state == 'VOIDED') {
					if (statuts.indexOf('REVOKED') == -1) {
						statuts.push('REVOKED');
					}
				} else {
					if (statuts.indexOf(item.state) == -1) {
						statuts.push(item.state);
					}
				}
			});

		if (statuts) {
			statuts = this.sortedStatuts(statuts);
		}
		this.setState({ statuts, value: statuts[0] });
	};

	render() {

		if (this.props.location && this.props.location.state && this.props.location.state.refetch) {
			this.props.data.refetch();
		}
		let sheetName = 'Commandes ';
		if (this.state.startDate & this.state.endDate && this.state.startDate.isValid && this.state.endDate.isValid) {
			sheetName +=
				'_Du_' + this.state.startDate.format('DD_MM_YYYY') + '_Au_' + this.state.endDate.format('DD_MM_YYYY');
		}
		let excelProps = {
			columns: [
				{ label: 'Numéro de commande', value: 'num' },
				{ label: 'Date de commande', value: 'date' },
				{ label: 'Mode de livraison', value: 'delivery_mode' },
				{ label: 'Date de livraison', value: 'delivery_date' },
				{ label: 'Statut', value: 'state' },
				{ label: 'Num Pharmacie', value: 'pharmacy_num' },
				{ label: 'Pharmacie', value: 'pharmacy' },
				{ label: 'Num Patient', value: 'patient_num' },
				{ label: 'Patient', value: 'patient' },
				{ label: 'Reste à Charge', value: 'to_pay' }
			],
			dataSets: [],
			sheetName: sheetName
		};
		let commands = this.props.data && this.props.data.getCommands ? this.props.data.getCommands : [];
		commands = commands
			.filter(item => {
				let fullname = item.client.last_name + ' ' + item.client.first_name;
				fullname = fullname.toLowerCase();
				let filter = this.state.filterText.toLowerCase();
				return this.state.filterText.trim() == ''
					? true
					: fullname.indexOf(filter) > -1 ||
							item.pharmacy.name.toLowerCase().indexOf(filter) > -1 ||
							item.num.indexOf(this.state.filterText) > -1;
			})
			.filter(item => {
				if (this.state.deliveryDate && this.state.deliveryDate.isValid && moment(item.delivery_date).isValid) {
					return (
						moment(item.delivery_date).format('DD/MM/YYYY') == moment(this.state.deliveryDate).format('DD/MM/YYYY')
					);
				}
				if (this.state.startDate & this.state.endDate && this.state.startDate.isValid && this.state.endDate.isValid) {
					let startDate = this.state.startDate.format('DD/MM/YYYY');
					let endDate = this.state.endDate.format('DD/MM/YYYY');
					let createdAt = moment(item.createdAt).format('DD/MM/YYYY');
					return moment(createdAt, 'DD/MM/YYYY').isBetween(
						moment(startDate, 'DD/MM/YYYY'),
						moment(endDate, 'DD/MM/YYYY'),
						null,
						true
					);
				} else {
					return true;
				}
			})
			.sort(getSorting(order, orderBy))
			.filter(item => {
			
				if (this.state.type) {
					if (this.state.type == 'EXPRESS') {
						return this.state.toDay
							? item.delivery_mode == 'EXPRESS' &&
									moment().format('DD/MM/YYYY') == moment(item.createdAt).format('DD/MM/YYYY')
							: item.delivery_mode == 'EXPRESS';
					} else if (this.state.type == 'FREE') {
						return this.state.toDay
							? item.delivery_mode == 'FREE' &&
									moment().format('DD/MM/YYYY') == moment(item.createdAt).format('DD/MM/YYYY')
							: item.delivery_mode == 'FREE';
					} else if (this.state.type == 'IN_DAY') {
						return this.state.toDay
							? item.delivery_mode == 'IN_DAY' &&
									moment().format('DD/MM/YYYY') == moment(item.createdAt).format('DD/MM/YYYY')
							: item.delivery_mode == 'IN_DAY';
					} else {
						return this.state.toDay
							? moment().format('DD/MM/YYYY') == moment(item.createdAt).format('DD/MM/YYYY')
							: true;
					}
				} else {
					return true;
				}
			})
			.filter(item => {
				if (this.state.status == -1) {
					return true;
				} else {
					return item.state == this.state.status;
				}
			});

		commands.forEach(element => {
			let command = {
				num: element.num,
				date: moment(element.createdAt).format('LLL'),
				delivery_mode:
					element.delivery_mode == 'FREE'
						? 'Click & Collect'
						: element.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée',
				delivery_date: element.delivery_date ? moment(element.delivery_date).format('LLL') : '',
				state: getCmdState(element.state),
				pharmacy_num: element.pharmacy.num,
				pharmacy: element.pharmacy.name,
				patient_num: element.client.num,
				patient: element.client.last_name + ' ' + element.client.first_name,
				to_pay: element.to_pay ? element.to_pay : ''
			};
			excelProps.dataSets.push(command);
		});

		const { classes, theme } = this.props;
		const { order, orderBy, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);
		const { value } = this.state;
		const status = '';
		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		const btnClick = {
			'box-shadow': '0px 10px rgb(40, 172, 179)'
		};
		return (
			<div>
				<Typography color="textSecondary" variant="headline" />
				{this.props.data.loading && (
					<div className={classes.progressContainer}>
						<Fade in timeout={300}>
							<Typography variant="subheading" color="textSecondary">
								Chargement...
							</Typography>
						</Fade>
					</div>
				)}
				{this.props.data.loading == false && (
					<Paper className={classes.root}>
						{/* <BottomNavigation value={value} onChange={this.handleChange} className={classes.root}>
        <BottomNavigationAction label="Toutes" value="recents" icon={<Group />} />
        <BottomNavigationAction label="Express" value="favorites" icon={<DirectionsBike />} />
        <BottomNavigationAction label="Click & Collect" value="nearby" icon={<ScheduleOutlined />} />
        <BottomNavigationAction label="Nearby" value="nearby" icon={<Today />} />
      </BottomNavigation> */}
						<div className={classNames(classes.boxFlex)}>
							<div
								style={stylesWelcome.leftCol}
								className={classNames(classes.boxFlex1, classes.ColXl6)}
								onClick={() => this.filterCommands('all')}
							>
								<Card style={Object.assign({}, stylesIcon.card, this.state.type == 'all' ? btnClick : {})}>
									<CustIcon name="commande.png" />
									<CardTitle title={'filterCommands'} />
									<div style={{ display: 'block', lineHeight: '60px', padding: '16px' }} className="widget_title">
										TOUTES
									</div>
								</Card>
							</div>
							<div
								style={stylesWelcome.leftCol}
								className={classNames(classes.boxFlex1, classes.ColXl6)}
								onClick={() => this.filterCommands('EXPRESS')}
							>
								<Card style={Object.assign({}, stylesIcon.card, this.state.type == 'EXPRESS' ? btnClick : {})}>
									<CustIcon name="express.png" />
									<CardTitle title={'filterCommands'} />
									<div style={{ display: 'block', lineHeight: '60px', padding: '16px' }} className="widget_title">
										EXPRESS
									</div>
								</Card>
							</div>
							<div
								style={stylesWelcome.singleCol}
								className={classNames(classes.boxFlex1, classes.ColXl6)}
								onClick={() => this.filterCommands('FREE')}
							>
								<Card style={Object.assign({}, stylesIcon.card, this.state.type == 'FREE' ? btnClick : {})}>
									<CustIcon name="cnc.png" />
									<CardTitle title={'filterCommands'} />
									<div style={{ display: 'block', lineHeight: '60px', padding: '16px' }} className="widget_title">
										CLICK & COLLECT
									</div>
								</Card>
							</div>
							<div
								style={stylesWelcome.rightCol}
								className={classNames(classes.boxFlex1, classes.ColXl6)}
								onClick={() => this.filterCommands('IN_DAY')}
							>
								<Card style={Object.assign({}, stylesIcon.card, this.state.type == 'IN_DAY' ? btnClick : {})}>
									<CustIcon name="livraison.png" />
									<CardTitle title={'filterCommands'} />
									<div style={{ display: 'block', lineHeight: '60px', padding: '16px' }} className="widget_title">
										FIN DE JOURNÉE
									</div>
								</Card>
							</div>
						</div>

						<div>
							<Switch
								color="primary"
								checked={this.state.toDay}
								onChange={(e, value) => {
									this.setState({ toDay: value });
								}}
								value=""
							/>{' '}
							Seulement aujourd'hui
						</div>
						<EnhancedTableToolbar
							refresh={this.props.data.refetch}
							toggleFilter={this.toggleFilter}
							isFilterShowen={this.state.showFilter}
							excelProps={excelProps}
							startDate={this.state.startDate}
							endDate={this.state.endDate}
							deliveryDate={this.state.deliveryDate}
							handleChangeDatePicker1={this.handleChangeDatePicker1}
							handleChangeDatePicker2={this.handleChangeDatePicker2}
							handleChangeDatePicker3={this.handleChangeDatePicker3}
							handleStatutChange={this.handleStatutChange}
						/>
						<div className={classes.tableWrapper}>
							{this.state.showFilter && (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Zoom in={true} timeout={200}>
										<TextField
											style={{ marginRight: 20 }}
											placeholder={'Chercher...'}
											autoFocus
											value={this.state.filterText}
											onChange={this.handleFilterChange}
										/>
									</Zoom>
								</div>
							)}
							<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
								<Table className={classes.table} aria-labelledby="tableTitle">
									<EnhancedTableHead
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
										rowCount={commands.length}
										classes={classes}
									/>
									<TableBody>
										{commands
											.sort(getSorting(order, orderBy))
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.map((command, i) => {
												return (
													<TableRow role="checkbox" tabIndex={-1} key={command.id}>
														<TableCell component="th" scope="row" padding="checkbox">
															<div
																style={{
																	textTransform: 'uppercase',
																	color: '#28acb3',
																	fontWeight: 'bold',
																	textAlign: 'left',
																	fontFamily: 'Montserrat, sans-serif',
																	cursor: 'pointer'
																}}
																onClick={() => this.props.history.push(`/commands/content?id=${command.id}`)}
																className={classes.tableCell}
															>
																{command.num}
															</div>
														</TableCell>
														<TableCell>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	marginLeft: -20,
																	TextAlign: 'left'
																}}
																className={classes.tableCell}
															>
																{moment(command.createdAt).format('LLL')}
															</div>
														</TableCell>
														<TableCell>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	marginLeft: -20,
																	TextAlign: 'left',
																	padding: 'unset'
																}}
																className={classes.tableCell}
															>
																{getCmdState(command.state)}
															</div>
														</TableCell>

														<TableCell
															style={{
																textTransform: 'uppercase',
																color: '#28acb3',
																fontWeight: 'bold',
																textAlign: 'left',
																fontFamily: 'Montserrat, sans-serif',
																cursor: 'pointer',
																padding: 'unset'
															}}
															className={classes.tableCell}
														>
															<a
																onClick={() => this.props.history.push(`/pharmacies/content?id=${command.pharmacy.id}`)}
															>
																{capitalizeFirstLetter(command.pharmacy.name)}
															</a>
														</TableCell>

														<TableCell
															style={{ textAlign: 'left', fontFamily: 'Montserrat, sans-serif', padding: 'unset' }}
														>
															<div
																style={{
																	textTransform: 'uppercase',
																	color: '#28acb3',
																	fontWeight: 'bold',
																	textAlign: 'left',
																	fontFamily: 'Montserrat, sans-serif',
																	cursor: 'pointer'
																}}
																onClick={() => this.props.history.push(`/admins/content?id=${command.client.id}`)}
																className={classes.tableCell}
															>
																{capitalizeFirstLetter(command.client.last_name) +
																	'  ' +
																	capitalizeFirstLetter(command.client.first_name)}
															</div>
														</TableCell>
														<TableCell style={{ padding: 'unset' }}>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	TextAlign: 'left',
																	padding: 5
																}}
																className={classes.tableCell}
															>
																{command.delivery_date ? moment(command.delivery_date).format('LLL') : ''}
															</div>
														</TableCell>
														<TableCell style={{ textAlign: 'left', padding: 'unset' }}>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	TextAlign: 'left'
																}}
																className={classes.tableCell}
															>
																{command.delivery_mode == 'FREE'
																	? 'Click & Collect'
																	: command.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée'}
															</div>
														</TableCell>

														<TableCell style={{ padding: 'unset' }}>
															<div style={{ display: 'flex' }} className={classes.tableCell}>
																<Tooltip title="AFFICHER">
																	<IconButton
																		className={classes.button}
																		aria-label="AFFICHER"
																		onClick={() => this.props.history.push(`/commands/content?id=${command.id}`)}
																	>
																		<Eye style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
																	</IconButton>
																</Tooltip>
																{command.tracking_url ? (
																	<Tooltip title="TRACKING">
																		<IconButton
																			className={classes.button}
																			aria-label="TRACKING"
																			onClick={() => window.open(command.tracking_url, '_blank')}
																		>
																			<DirectionsBike style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
																		</IconButton>
																	</Tooltip>
																) : (
																	''
																)}
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										{emptyRows > 0 && (
											<TableRow style={{ height: 49 }}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Hidden>
							<Hidden only={['lg', 'xl']} implementation={'js'}>
								<ResponsiveTable
									columns={columns(this.props)}
									data={commands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
									showPagination={true}
									count={commands.length}
									rowsPerPage={rowsPerPage}
									page={page}
									excludePrimaryFromDetails={true}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</Hidden>
						</div>
						<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
							<TablePagination
								component="div"
								count={commands.length}
								rowsPerPage={rowsPerPage}
								page={page}
								backIconButtonProps={{
									'aria-label': 'Previous Page'
								}}
								nextIconButtonProps={{
									'aria-label': 'Next Page'
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Hidden>
					</Paper>
				)}
			</div>
		);
	}
}

EnhancedTable.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ commands }) => ({ commands });

const mapDispatchToProps = dispatch => ({
	loadCommands: bindActionCreators(loadCommands, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EnhancedTable));
export default compose(graphql(LOAD_COMMANDS_QUERY))(withStyles(styles, { withTheme: true })(EnhancedTable));
