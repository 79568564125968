import React from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardTitle from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import {connect} from "react-redux";
import{bindActionCreators} from "redux";
import Button from "@material-ui/core/Button";
import Assignment from "@material-ui/icons/Assignment";
import Group from"@material-ui/icons/Group";
import Equalizer from "@material-ui/icons/Equalizer";
import IconButton from "@material-ui/core/IconButton";
import DirectionsBike from "@material-ui/icons/DirectionsBike";
import  LocalPharmacy from '@material-ui/icons/LocalPharmacy';
import  AddLocation from '@material-ui/icons/AddLocation';
import {loadCommands} from "../../actions";
import moment from "moment";
import CustIcon from "./../cust-icon"
const stylesIcon = {
    card: { borderLeft: "solid 4px rgb(40, 172, 179)", flex: 1 },
    icon: { float: "right", width: 64, height: 64, padding: 16, color: "rgb(40, 172, 179)" },
};

const style = theme => ({
    container: {
        flexGrow: 1,
    },
    card: {
        marginTop: theme.spacing.unit * 5,
        padding: theme.spacing.unit * 3,
    }
});
const stylesWelcome = {
    welcome: { marginBottom: "1em" },
    button: { width: "97%", margin: "1.5%" },
    flex: { display: "flex", marginBottom: "2em" },
    leftCol: { flex: 1, marginRight: "1em" },
    rightCol: { flex: 1, marginLeft: "1em" },
    singleCol: { flex: 1 }
};



class NbCommandes extends React.Component {

    componentWillMount(){
        this.props.loadCommands();
        
        
        
        
    }

    render() {
        const commands = this.props.commands.data;
        const {classes} = this.props;
        const {type} = this.props;
        const {toDay}=this.props;
        let filterCommands=[];
        if(type=="all"){
            if(toDay)
            {
                filterCommands=commands.filter(item=>moment(item.createdAt).format("DD/MM/YYYY")==moment().format("DD/MM/YYYY"));
            }
            else{
                filterCommands=commands;
            }
        }
        else {
            if(toDay)
           {
            filterCommands=commands.filter(item=>type==item.delivery_mode&&moment(item.createdAt).format("DD/MM/YYYY")==moment().format("DD/MM/YYYY"))
           }
            else{
                filterCommands=commands.filter(item=>type==item.delivery_mode)
            }
        }
        
        return (
            
                    
                
    
            
                <Card style={stylesIcon.card}>
                    {type=="all"? <CustIcon name="commande.png" />:(type=="EXPRESS"? <CustIcon name="express.png" />:(type=="FREE"? <CustIcon name="cnc.png" />:<CustIcon name="livraison.png" />))}
                   
                    <CardTitle title={filterCommands} subtitle={type=="all"?"Commandes":(type=="EXPRESS"?"Express":(type=="FREE"?"Click & Collect":"Fin de Journée"))} />
                      <div style={{fontSize: 24, display: "block", lineHeight: "36px",padding:"16px", fontFamily: "Montserrat, sans-serif"}}> {
                          filterCommands.length
                      } 
                      <div  className="widget_title">
                      {type=="all"?"Commandes":(type=="EXPRESS"?"Express":(type=="FREE"?"Click & Collect":"Fin de Journée"))}
                      </div>
                        </div>
                </Card>
            
            
            
            
            

        );
    }
}


const mapStateToProps = ({commands})=> ({commands});

const mapDispatchToProps = dispatch => ({
    loadCommands: bindActionCreators(loadCommands, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default connect(mapStateToProps, mapDispatchToProps)((withStyles( { withTheme: true })(NbCommandes)));