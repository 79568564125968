import React from "react";
import ReactExport from "react-export-excel";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GetAppIcon from '@material-ui/icons/GetApp';
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
class Excel extends React.Component {
    render() {
        const dataSets = this.props.dataSets;
        const columns = this.props.columns;
        const sheetName=this.props.sheetName;
       
        return (
            <ExcelFile element={
                <Tooltip title="Exporter">
                    <IconButton>
                        <GetAppIcon/>
                        </IconButton>
                    </Tooltip>
            } filename={sheetName}>
                    <ExcelSheet data={dataSets} name={sheetName}>
                     {
                         columns.map(c=>{
                             return(
                                 <ExcelColumn label={c.label} value={c.value} />
                             )
                         })
                     }
                </ExcelSheet>
            </ExcelFile>
        );
    }
}
export default Excel;