import React from "react";
const path="http://pharmaloop.fr/img/"
class CustIcon extends React.Component {
  render(){
      let src=path+this.props.name;
      return(
          <img src={src} className="cust_icon" />
      )
  }
}
export default CustIcon