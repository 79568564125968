import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { capitalizeFirstLetter } from '../tools/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import Horaire from './Horaire';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';

import InputLabel from '@material-ui/core/InputLabel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SaveIcon from '@material-ui/icons/Save';

import gql from 'graphql-tag';

import { Mutation } from 'react-apollo';
import { graphql, compose } from 'react-apollo';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import FormControl from '@material-ui/core/FormControl';
import validator from 'validator';
import Form from 'react-validation/build/form';
import TextInput from 'react-validation/build/input';
import Grid from '@material-ui/core/Grid';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from './../tools/axios';
import MUIPlacesAutocomplete, { geocodeByPlaceID } from 'mui-places-autocomplete';
const CHANGE_AVATAR_QUERY = gql`
	mutation changeAvatar($file: Upload!) {
		changeAvatar(file: $file)
	}
`;

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //

class EnhancedTableToolbar extends React.Component {
	render() {
		const { classes } = this.props;

		return (
			<Toolbar className={classNames(classes.root)}>
				<div className={classes.title}>
					<Typography variant="title" id="tableTitle" />
				</div>
				<div className={classes.spacer} />
				<div className={classes.actions}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
							<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Rafraîchir">
							<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</Toolbar>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	}
});
/***************************************************Page  NewPHARMACY************************************************* */
const ADD_PAHRMACIES_MUTATION = gql`
	mutation addPharmacy(
		$file: Upload!
		$name: String!
		$email: String!
		$website: String!
		$password: String!
		$tel: String!
		$address: String!
		$zip_code: String!
		$city: String!
		$country: String!
		$is_pill_maker: Boolean!
		$longitude: Float!
		$latitude: Float!
		$description: String!
		$work_schedule: ScheduleInput!
	) {
		addPharmacy(
			file: $file
			input: {
				name: $name
				email: $email
				website: $website
				password: $password
				tel: $tel
				address: $address
				zip_code: $zip_code
				city: $city
				country: $country
				is_pill_maker: $is_pill_maker
				location: { latitude: $latitude, longitude: $longitude }
				description: $description
				work_schedule: $work_schedule
			}
		) {
			id
			tel
			name
			website
			is_pill_maker
			work_schedule {
				is_always_on_work
				planing {
					monday {
						open_at
						close_at
					}
					tuesday {
						open_at
						close_at
					}
					wednesday {
						open_at
						close_at
					}
					thursday {
						open_at
						close_at
					}
					friday {
						open_at
						close_at
					}
					saturday {
						open_at
						close_at
					}
					sunday {
						open_at
						open_at
					}
				}
			}
			profile {
				id
				email
				profile_pic_url
			}
			location {
				latitude
				longitude
			}
			address
			city
			zip_code
		}
	}
`;
const required = value => {
	if (!value.toString().trim().length) {
		// We can return string or jsx as the 'error' prop for the validated Component
		return 'require';
	}
};

const email = value => {
	if (!validator.isEmail(value)) {
		return `${value} is not a valid email.`;
	}
};

const lt = (value, props) => {
	// get the maxLength from component's props
	if (!value.toString().trim().length > props.maxLength) {
		// Return jsx
		return <span className="error">The value exceeded {props.maxLength} symbols.</span>;
	}
};

function validate(email, password) {
	// true means invalid, so our conditions got reversed
	return {
		email: email.length === 0,
		password: password.length === 0
	};
}
/**** */

class NewPharmacy extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor() {
		super();
		this.state = {
			filterText: '',
			avatarChangeLoading: false,
			openFab: true,
			showFilter: false,
			selectedFile: null,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: 25,
			name: '',
			email: '',
			website: '',
			password: '',
			tel: '',
			address: '',
			zip_code: '',
			city: '',
			country: '',
			longitude: '',
			latitude: '',
			description: '',
			is_pill_maker: true,
			work_schedule: {
				is_always_on_work: true,
				planing: {
					monday: {
						open_at: '',
						close_at: ''
					},
					tuesday: {
						open_at: '',
						close_at: ''
					},
					wednesday: {
						open_at: '',
						close_at: ''
					},
					thursday: {
						open_at: '',
						close_at: ''
					},
					friday: {
						open_at: '',
						close_at: ''
					},
					saturday: {
						open_at: '',
						close_at: ''
					},
					sunday: {
						open_at: '',
						close_at: ''
					}
				}
			},
			everFocusedEmail: false,
			everFocusedPassword: false,
			inFocus: '',
			file: null,

			checkedC: false,

			image: ''
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		this.setState({ order, orderBy });
	};
	onSuggestionSelected(suggestion) {
		// Add your business logic here. In this case we just log...
	this.setState({ address: suggestion.description });

		geocodeByPlaceID(suggestion.place_id)
			.then(results => {
				// Add your business logic here. In this case we simply set our state with the coordinates of
				// the selected suggestion...

				// Just use the first result in the list to get the geometry coordinates
				const { geometry } = results[0];

				const location = {
					latitude: geometry.location.lat(),
					longitude: geometry.location.lng()
				};
				this.setState({ latitude: location.latitude, longitude: location.longitude });
			})
			.catch(err => {
				// Handle any errors that occurred when we tried to get geospatial data for a selected
				// suggestion...
			});
	}
	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}

	fileChangedHandler = event => {
		this.setState({ selectedFile: event.target.files[0] });
	};
	handleNext = () => {
		this.setState(state => ({
			activeStep: state.activeStep + 1
		}));
	};
	uploadHandler = () => {
		this.setState({ avatarChangeLoading: true });
		this.props
			.mutate({ variables: { file: this.state.selectedFile } })
			.then(response => {
				this.setState({ avatarChangeLoading: false });
				if (response.data.changeAvatar) {
				
				}
			})
			.catch(err => {
		
				this.setState({ avatarChangeLoading: false });
			});
	};
	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0
		});
	};

	canBeSubmitted() {
		const isDisabled = Object.keys(errors).some(x => errors[x]);

		return !isDisabled;
	}

	handleAvatarChange = ({ target: { files } }) => {
		if (files.length) {
			this.setState({ file: files[0] });
			const reader = new FileReader();
			reader.readAsDataURL(files[0]);
			reader.onloadend = () => this.setState({ uri: reader.result });
		}
	};

	handleSubmit = e => {
		NotificationManager.listNotify = [];
		e.preventDefault();
		let findError = false;
		let errorMessage;

		if (this.state.uri == null || this.state.uri == undefined || this.state.uri.length == 0) {
			return NotificationManager.error('Veuillez renseigner le logo');
		}
		if (this.state.name == null || this.state.name == undefined || this.state.name.length == 0) {
			return NotificationManager.error('Veuillez renseigner le nom');
		}
		if (this.state.description == null || this.state.description == undefined || this.state.description.length == 0) {
			return NotificationManager.error('Veuillez renseigner la description');
		}
		if (this.state.email == null || this.state.email == undefined || this.state.email.length == 0) {
			return NotificationManager.error("Veuillez renseigner l'email");
		}
		if (this.state.tel == null || this.state.tel == undefined || this.state.tel.length == 0) {
			return NotificationManager.error('Veuillez renseigner le téléphone');
		}
		if (!this.state.tel.match(/^((\+)33|0)[1-9](\d{2}){4}$/g)) {
			return NotificationManager.error('Le téléphone est invalide');
		}
		if (this.state.address == null || this.state.address == undefined || this.state.address.length == 0) {
			return NotificationManager.error("Veuillez renseigner l'adresse");
		}
		if (this.state.country == null || this.state.country == undefined || this.state.country.length == 0) {
			return NotificationManager.error('Veuillez renseigner le pays');
		}
		if (this.state.city == null || this.state.city == undefined || this.state.city.length == 0) {
			return NotificationManager.error('Veuillez renseigner le la ville');
		}
		if (this.state.zip_code == null || this.state.zip_code == undefined || this.state.zip_code.length == 0) {
			return NotificationManager.error('Veuillez renseigner le code postal');
		}
		if (this.state.longitude.length == 0) {
			return NotificationManager.error('Veuillez renseigner la longitude');
		}
		if (this.state.latitude.length == 0) {
			return NotificationManager.error('Veuillez renseigner la latitude');
		}
		if (
			(Number(parseFloat(this.state.longitude)) === parseFloat(this.state.longitude) &&
				parseFloat(this.state.longitude) % 1 !== 0) == false
		) {
			return NotificationManager.error('Valeur invalide pour la longitude');
		}
		if (
			(Number(parseFloat(this.state.latitude)) === parseFloat(this.state.latitude) &&
				parseFloat(this.state.latitude) % 1 !== 0) == false
		) {
			return NotificationManager.error('Valeur invalide pour la latitude');
		}

		let daysName = [
			{ id: 'monday', name: 'Lundi' },
			{ id: 'tuesday', name: 'Mardi' },
			{ id: 'wednesday', name: 'Mercredi' },
			{ id: 'thursday', name: 'Jeudi' },
			{ id: 'friday', name: 'Vendredi' },
			{ id: 'saturday', name: 'Samedi' },
			{ id: 'sunday', name: 'Dimanche' }
		];
		daysName.forEach(el => {
			if (
				this.state.work_schedule.planing[el.id].open_at.length == 0 &&
				this.state.work_schedule.planing[el.id].close_at.length > 0
			) {
				findError = true;
				errorMessage = "Veuillez renseillez l'heure d'ouverture du " + el.name;
			} else if (
				this.state.work_schedule.planing[el.id].open_at.length > 0 &&
				this.state.work_schedule.planing[el.id].close_at.length == 0
			) {
				findError = true;
				errorMessage = "Veuillez renseillez l'heure de fermeture du " + el.name;
			}
		});

		if (findError) {
			return NotificationManager.error(errorMessage);
		}
		if (this.state.loading) {
			return;
		}

		let {
			name,
			email,
			website,
			password,
			tel,
			address,
			zip_code,
			city,
			country,
			longitude,
			latitude,
			file,
			description,
			is_pill_maker,
			work_schedule
		} = this.state;

		let body = { address: this.state.address, phone: this.state.tel };
		axios.post('/stuart/validAddress?type=delivering', body).then(
			resp => {
			
				if (resp.data && resp.data.success) {
					this.props
						.mutate({
							variables: {
								name,
								email,
								website,
								password,
								tel,
								address,
								zip_code,
								city,
								country,
								longitude,
								latitude,
								file,
								description,
								is_pill_maker,
								work_schedule
							}
						})
						.then(response => {
							NotificationManager.success('La pharmacie a été ajoutée avec succès', 'Succès');
							setTimeout(() => {
								this.props.history.push('/pharmacies');
							}, 2000);
						})
						.catch(err => {
							if (/email_1 dup key/.test(err)) {
								NotificationManager.error("Email déjà existant, merci d'en choisir un autre", 'Erreur');
							} else {
								NotificationManager.error("L'ajout  n' a pas été effectué", 'Erreur');
							}
						
							// NotificationManager.error("L'ajout  n' a pas été effectué", "Erreur")
						});
				} else {
					if (resp.data && resp.data.error == 'ADDRESS_CONTACT_PHONE_REQUIRED') {
						return NotificationManager.error('Le téléphone est invalide');
					}
					return NotificationManager.error("L'adresse est invalide");
				}
			},
			error => {
				if (error.data && error.data.error == 'ADDRESS_CONTACT_PHONE_REQUIRED') {
					return NotificationManager.error('Le téléphone est invalide');
				}
				return NotificationManager.error("L'adresse est invalide");
			}
		);
	};

	render() {
		const { classes, theme } = this.props;

		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreenmonday
		};

		return (
			<div>
				<div className={classes.tableWrapper}>
					<List component="nav">
						<Form>
							<Typography color="textSecondary" variant="headline">
								<p>AJOUTER UNE PHARMACIE</p>
							</Typography>

							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<Card style={{ marginBottom: 20 }}>
								<CardContent>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Logo
									</InputLabel>
									<Grid item xs={12} style={{ marginBottom: 40 }}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="avatar"
											type="file"
											onChange={this.handleAvatarChange}
										/>
										<label htmlFor="avatar">
											{this.state.uri ? (
												<Avatar src={this.state.uri} style={{ width: 100, height: 100, marginLeft: 20 }} />
											) : (
												<Avatar style={{ width: 100, height: 100, marginLeft: 20 }}>
													<AddAPhotoIcon style={{ fontSize: 50 }} />
												</Avatar>
											)}
										</label>
										{this.state.uri && (
											<Typography
												style={{ marginLeft: 40, marginTop: 10, cursor: 'pointer' }}
												variant="caption"
												onClick={() => this.setState({ file: null, uri: null })}
											>
												Supprimer
											</Typography>
										)}
									</Grid>
								</CardContent>
							</Card>
							<Card style={{ marginBottom: 20 }}>
								<CardContent>
									<div>
										<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
											Nom
										</InputLabel>

										<TextField
											required
											value={this.state.name}
											name="Nom"
											InputLabelProps={{
												shrink: true
											}}
											style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
											onChange={e => this.setState({ name: e.target.value })}
											fullWidth={true}
										/>
									</div>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Email
									</InputLabel>

									<TextField
										required
										type="email"
										value={this.state.email}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ email: e.target.value })}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Mot de passe
									</InputLabel>

									<TextField
										required
										type="password"
										value={this.state.password}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ password: e.target.value })}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>

									<div>
										<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
											Description
										</InputLabel>

										<TextField
											required
											value={this.state.description}
											style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
											onChange={e => this.setState({ description: e.target.value })}
											label=""
											InputLabelProps={{
												shrink: true
											}}
											fullWidth={true}
										/>
										<div style={{ display: 'block' }}>
											<div />

											<div
												style={{
													display: 'inline-block',
													position: 'relative'
												}}
											/>
										</div>
									</div>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<div>
										<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
											Téléphone
										</InputLabel>

										<TextField
											required
											label=""
											value={this.state.tel}
											InputLabelProps={{
												shrink: true
											}}
											style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
											onChange={e => this.setState({ tel: e.target.value })}
											fullWidth={true}
										/>
									</div>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Site Web
									</InputLabel>

									<TextField
										required
										value={this.state.website}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ website: e.target.value })}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<ListItem>
										<Switch
											checked={this.state.is_pill_maker}
											onChange={this.handleChange('is_pill_maker')}
											value={this.state.is_pill_maker}
											color="primary"
										/>
										<ListItemText
											style={{
												fontFamily: 'Montserrat, sans-serif',
												fontWeight: 'normal'
											}}
											primary={
												<span
													style={{
														fontFamily: 'Montserrat, sans-serif',
														fontWeight: 'normal'
													}}
												>
													Pilulier Personnalisé
												</span>
											}
										/>
									</ListItem>
								</CardContent>
							</Card>

							<Card style={{ marginBottom: 20 }}>
								<CardContent>
									<div>
										<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
											Adresse
										</InputLabel>
										<div style={{ Height: '100%', marginTop: '1px', padding: '0px' }}>
											<MUIPlacesAutocomplete
												onSuggestionSelected={this.onSuggestionSelected}
												renderTarget={() => <div />}
												textFieldProps={{ placeholder: 'Recherche une adresse', fullWidth: true }}
											/>
										</div>
									</div>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Ville
									</InputLabel>

									<TextField
										required
										value={this.state.city}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ city: e.target.value })}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Code Postale
									</InputLabel>

									<TextField
										required
										value={this.state.zip_code}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ zip_code: e.target.value })}
										fullWidth={true}
									/>

									<div style={{ display: 'block', marginTop: '30px' }}> </div>

									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Pays
									</InputLabel>

									<TextField
										required
										value={this.state.country}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ country: e.target.value })}
										fullWidth={true}
									/>

									<div style={{ display: 'block', marginTop: '50px' }}> </div>

									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Latitude
									</InputLabel>

									<TextField
										required
										value={this.state.latitude}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ latitude: e.target.value })}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
									<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
										Longitude
									</InputLabel>

									<TextField
										required
										value={this.state.longitude}
										label=""
										InputLabelProps={{
											shrink: true
										}}
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										onChange={e => this.setState({ longitude: e.target.value })}
										fullWidth={true}
									/>

									<div style={{ display: 'block', marginTop: '50px' }}> </div>
								</CardContent>
							</Card>
							<Card style={{ marginBottom: 20 }}>
								<CardContent>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>

									{
										<div>
											<table>
												<tr>
													<td style={{ padding: 20 }}>Lundi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.monday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			monday: {
																				...this.state.work_schedule.planing.monday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.monday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,
																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			monday: {
																				...this.state.work_schedule.planing.monday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Mardi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.tuesday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,
																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			tuesday: {
																				...this.state.work_schedule.planing.tuesday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.tuesday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			tuesday: {
																				...this.state.work_schedule.planing.tuesday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Mercredi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.wednesday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			wednesday: {
																				...this.state.work_schedule.planing.wednesday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.wednesday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			wednesday: {
																				...this.state.work_schedule.planing.wednesday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Jeudi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.thursday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			thursday: {
																				...this.state.work_schedule.planing.thursday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.thursday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,
																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			thursday: {
																				...this.state.work_schedule.planing.thursday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Vendredi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.friday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			friday: {
																				...this.state.work_schedule.planing.friday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.friday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			friday: {
																				...this.state.work_schedule.planing.friday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Samedi</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.saturday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			saturday: {
																				...this.state.work_schedule.planing.saturday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.saturday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			saturday: {
																				...this.state.work_schedule.planing.saturday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
												<tr>
													<td style={{ padding: 20 }}>Dimanche</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ouvert à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.sunday.open_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			sunday: {
																				...this.state.work_schedule.planing.sunday,
																				open_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
													<td>
														<InputLabel
															style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}
														>
															Ferme à
														</InputLabel>

														<TextField
															value={this.state.work_schedule.planing.sunday.close_at}
															id="full-width"
															label=""
															InputLabelProps={{
																shrink: true
															}}
															style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
															onChange={({ target: { value } }) => {
																this.setState({
																	...this.state,

																	work_schedule: {
																		...this.state.work_schedule,
																		planing: {
																			...this.state.work_schedule.planing,
																			sunday: {
																				...this.state.work_schedule.planing.sunday,
																				close_at: value
																			}
																		}
																	}
																});
															}}
															fullWidth={true}
														/>
													</td>
												</tr>
											</table>
										</div>
									}
									<div style={{ display: 'block', marginTop: '50px' }}> </div>

									<NotificationContainer />

									<div />
								</CardContent>
							</Card>
							<div style={{ marginTop: 20 }}>
								<div>
									<div>
										<Button
											type="button"
											style={{
												height: '36px',
												marginTop: 15,
												width: '300px',
												marginBottom: 15,
												backgroundColor: 'rgba(3, 180, 115)',
												backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
												color: 'rgb(255, 255, 255)',
												fontFamily: 'Montserrat, sans-serif',
												color: 'rgb(255, 255, 255)',
												border: 'none',
												marginRight: '50px',
												boxShadow:
													'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
												borderRadius: '4px',
												textTransform: 'uppercase',
												fontWeight: '500',
												outline: 'none',
												fontSize: '0.875rem'
											}}
											onClick={this.handleSubmit}
										>
											Enregistrer
										</Button>
										<button
											style={{
												height: '36px',
												marginTop: 15,
												width: '300px',
												marginBottom: 15,
												backgroundColor: 'rgba(3, 180, 115)',
												backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
												color: 'rgb(255, 255, 255)',
												fontFamily: 'Montserrat, sans-serif',
												color: 'rgb(255, 255, 255)',
												marginRight: '50px',
												border: 'none',
												boxShadow:
													'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
												borderRadius: '4px',
												textTransform: 'uppercase',
												fontWeight: '500',
												outline: 'none',
												fontSize: '0.875rem'
											}}
											onClick={() => this.props.history.push(`/pharmacies`)}
										>
											Annuler
										</button>
									</div>
								</div>
							</div>
						</Form>
					</List>
				</div>
			</div>
		);
	}
}


NewPharmacy.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(graphql(ADD_PAHRMACIES_MUTATION))(withStyles(styles, { withTheme: true })(NewPharmacy));
