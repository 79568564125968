import client from "../client";
import {AuthTypes} from "../types/auth.types";

const login = (data) => dispatch => {
    dispatch({type: AuthTypes.REQUEST_LOGIN});
    client.post("/auth/login/admin", data)
        .then(response => {
       
            if (response.data.success) {
                dispatch({type: AuthTypes.REQUEST_LOGIN_SUCCESS, user: response.data.token});
                localStorage.setItem("token", response.data.token);
                window.location.href = "/";
            } else {
                if (response.data.fieldError == "email") {
                    dispatch({type: AuthTypes.REQUEST_LOGIN_ERROR, error: "email"});
                } else if (response.data.fieldError == "password") {
                    dispatch({type: AuthTypes.REQUEST_LOGIN_ERROR, error: "password"});
                } else {
                    dispatch({type: AuthTypes.REQUEST_LOGIN_ERROR, error: "error"});
                }
            }
        })
        .catch(reason => {
           
            dispatch({type: AuthTypes.REQUEST_LOGIN_ERROR, error: "network"});
        });
};

const logout = async () => {
    await client.post("/auth/logout");
    localStorage.removeItem("token");
    window.location.href = "/";
};

const checkToken = () => dispatch => {
    dispatch({type: AuthTypes.REQUEST_CHECK_TOKEN});
    client.post("/auth/token/check")
        .then(response => {
         
            dispatch({type: AuthTypes.REQUEST_CHECK_TOKEN_SUCCESS});
        })
        .catch(reason => {
       
            if (reason.request&&reason.request.status == 401) {
                localStorage.removeItem("token");
                window.location.href = "/";
            }else {
                dispatch({type: AuthTypes.REQUEST_CHECK_TOKEN_ERROR, error: "network"});
            }
        });
};

export {
    login,
    logout,
    checkToken
};