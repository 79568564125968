export const PharmacyTypes  = {
    REQUEST_LOAD_PHAMRACIES: "REQUEST_LOAD_PHAMRACIES",
    REQUEST_LOAD_PHAMRACIES_SUCCESS: "REQUEST_LOAD_PHAMRACIES_SUCCESS",
    REQUEST_LOAD_PHAMRACIES_ERROR: "REQUEST_LOAD_PHAMRACIES_ERROR",
    REQUEST_UPDATE_PHAMRACIES: "REQUEST_UPDATE_PHAMRACIES",
    REQUEST_UPDATE_PHAMRACIES_SUCCESS: "REQUEST_UPDATE_PHAMRACIES_SUCCESS",
    REQUEST_UPDATE_PHAMRACIES_ERROR: "REQUEST_UPDATE_PHAMRACIES_ERROR",
    REQUEST_LOAD_PHAMRACY: "REQUEST_LOAD_PHAMRACY",
    REQUEST_LOAD_PHAMRACY_SUCCESS: "REQUEST_LOAD_PHAMRACY_SUCCESS",
    REQUEST_LOAD_PHAMRACY_ERROR: "REQUEST_LOAD_PHAMRACY_ERROR",
    REQUEST_UPDATE_PHAMRACY: "REQUEST_UPDATE_PHAMRACY",
    REQUEST_UPDATE_PHAMRACY_SUCCESS: "REQUEST_UPDATE_PHAMRACY_SUCCESS",
    REQUEST_UPDATE_PHAMRACY_ERROR: "REQUEST_UPDATE_PHAMRACY_ERROR",
};