import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { capitalizeFirstLetter } from "../tools/utils";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Horaire from "./Horaire";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SaveIcon from "@material-ui/icons/Save";
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import FormControl from '@material-ui/core/FormControl';
import { graphql, compose } from "react-apollo";
import { NotificationContainer, NotificationManager } from 'react-notifications';














class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                numeric={row.numeric}
                                padding={i == 0 ? "checkbox" : (row.disablePadding ? "none" : "default")}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
});
// Button Style //

class EnhancedTableToolbar extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle">

                    </Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    colorSwitchBase: {

    },
    colorBar: {
    },
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500],
            },
        },
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    }
});
/***************************************************Page  NewLivreur************************************************* */
const ADD_DELIVERYMEN_MUTATION = gql`
    mutation addDeliveryMan($first_name:String!,$last_name:String!,$tel:String!,$pharmacy_id:String!,$email:String!,$password:String!) {
        addaddDeliveryMan ( input: {first_name:$first_name,last_name:$last_name,tel:$tel,pharmacy_id:$pharmacy_id,email:$email,password:$password}) 
            {
            id
            tel 
            first_name
            last_name
            
            
        
            
            
            address

            city 
            zip_code 
            
            
        }
    }
`;


class NewLivreur extends React.Component {
    


    state = {
        checkedA: true,
        checkedB: true,
    };

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };


    constructor() {
        super();
        this.state = {
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 5,
        id:'',
        first_name: '',
        last_name: '',
        tel: '',
        pharmacy_id: '',

        email: '',


        password: '',
        };
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({ showFilter: !this.state.showFilter, filterText: "" });
        } else {
            this.setState({ showFilter: !this.state.showFilter });
        }
    };



    handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });


    componentWillUnmount() {
        window.onscroll = null;
    }


    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };
    handleSubmit = () => {
        
        let { id,first_name, last_name, tel, pharmacy_id, email, password } = this.state ;
        
        this.props.mutate({
            variables: { id,first_name, last_name, tel, pharmacy_id, email, password }
        }).then(response =>{
            NotificationManager.success('Ce Livreur est ajouté avec succés', 'Succés');
       setTimeout(() => {
        this.props.history.push("/candidates")
       }, 2000)  }).catch(err => NotificationManager.error('l\'ajout  n\' a pas été effectué', 'Erreur'))
    }

    render() {

   

        const { classes, theme } = this.props;



        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        };





        return (

            <div>







                <div className={classes.tableWrapper}>












                    

                        <Typography color="textSecondary" variant="headline">

                            Livreur

                                                </Typography>
                        

                            <div style={{ display: "block", marginTop: "50px" }}> </div>
                            <div>

                                <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                    Nom de famille*
                                                        </InputLabel>


                                <TextField
                                    id="full-width"
                                    label=""
                                    value={this.state.first_name}




                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                    onChange={e => this.setState({ first_name: e.target.value })}


                                    fullWidth={true}




                                />
                            </div>
                            <div style={{ display: "block", marginTop: "50px" }}> </div>
                            <div>

                                <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                    Prénom
                                                        </InputLabel>


                                <TextField
                                    id="full-width"
                                    label=""
                                    value={this.state.last_name}

                                    pattern=""


                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                    onChange={e => this.setState({ last_name: e.target.value })}


                                    fullWidth={true}




                                />
                            </div>
                            <div style={{ display: "block", marginTop: "50px" }}> </div>
                            <div>

                                <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                    tel
                                                        </InputLabel>


                                <TextField
                                    id="full-width"
                                    label=""
                                    value={this.state.tel}

                                    pattern=""


                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                    onChange={e => this.setState({ tel: e.target.value })}


                                    fullWidth={true}




                                />
                            </div>
                            <div style={{ display: "block", marginTop: "50px" }}> </div>

                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                Email
                                                            </InputLabel>

                            <TextField
                                value={this.state.email}
                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                onChange={e => this.setState({ email: e.target.value })}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "50px" }}> </div>

                            <InputLabel sstyle={{ position: "relative", lineHeight: "1px" }}>
                                Site Web
                                                            </InputLabel>

                            
                            <div style={{ display: "block", marginTop: "50px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                mot de passe
                                                            </InputLabel>

                            <TextField
                                value={this.state.password}
                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                onChange={e => this.setState({ password: e.target.value })}



                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "50px" }}> </div>

                            
                            <div style={{ display: "block", marginTop: "50px" }}> </div>

                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}
                            >
                                Logo
                                                            </InputLabel>
                            <div onUpload={{
                                onChange: function (files) {
                                    var storageRef = firebase.storage().ref('images/' + files[0].title);
                                    storageRef.put(files[0].rawFile).then((data) => files[0].url = data.metadata.downloadURLs[0]
                                    );
                                }
                            }} style={{ display: "block" }}>
                                <div>
                                    <span style={{

                                        cursor: "pointer",
                                        padding: "1rem",
                                        textAlign: "center",
                                        padding: "25px",
                                        fontSize: "20px",

                                        textAlign: "center",
                                        display: "block",
                                    }}
                                    > Déposer une photo à télécharger ou cliquez pour la sélectionner</span>
                                    <input accept="image/*" type="file" autocomplete="off" style={{ display: "none" }} />
                                </div>
                                <div style={{
                                    display: "inline-block",
                                    position: "relative"
                                }}>
                                    <Button style={{
                                        border: "10px",
                                        boxsizing: "border-box",
                                        display: "inline-block",

                                        cursor: "pointer",

                                        margin: "0px",
                                        padding: "12px",
                                        outline: "none",


                                        position: "absolute",
                                        overflow: "visible",

                                        width: "48px",
                                        height: "48px",
                                        top: "0.5rem",
                                        right: "0.5rem",
                                        opacity: 1,
                                        background: "none"
                                    }}>
                                        <RemoveCircleIcon />
                                    </Button>
                                    <img title alt src="https://firebasestorage.googleapis.com/v0/b/pharmaloop-ebdd4.appspot.com/o/images%2Fpharmacy-312139_960_720.png?alt=media&token=b633a681-d6d5-48e0-ae77-6c547ac21d2c" style={{
                                        margin: "0.5rem",
                                        maxHeight: "10rem",
                                    }} />


                                </div>

                            </div>
                            <div style={{ display: "block", marginTop: "50px" }}> </div>

                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                Adresse
                                                            </InputLabel>

                            <TextField

                                value={this.state.address}
                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}

                                onChange={e => this.setState({ address: e.target.value })}
                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "50px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                pharmacy_id
                                                            </InputLabel>

                            <TextField
                                value={this.state.pharmacy_id}
                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                                onChange={e => this.setState({ pharmacy_id: e.target.value })}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px" }}>
                                Gallerie
                                                            </InputLabel>
                            <div style={{ display: "block" }}>
                                <div>
                                    <span style={{

                                        cursor: "pointer",
                                        padding: "1rem",
                                        textAlign: "center",
                                        padding: "25px",
                                        fontSize: "20px",

                                        textAlign: "center",
                                        display: "block",
                                    }}> Déposer des images à télécharger ou cliquez pour sélectionner une</span>
                                    <input accept="\image" type="file" autocomplete="off" style={{ display: "none" }} />
                                </div>
                            </div>

                            <div style={{ display: "block", marginTop: "70px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }}>
                                Mode de paiement
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>




                            <ListItem>
                                <ListItemText primary="Horaire de travail" />
                            </ListItem>
                            <ListItem>
                                <Switch
                                    checked={this.state.checkedA}
                                    onChange={this.handleChange('checkedA')}
                                    value="checkedA" /><ListItemText primary="Toujours ouvert" />
                            </ListItem>
                            {this.state.checkedA ? "" :
                                <Horaire />}

                            <hr />

                            <div>
                                <span><h1 style={{ marginTop: "5px", lineHeight: "36px", fontFamily: "Montserrat, sans-serif", textTransform:"uppercase", marginTop:"20px" }}> Admin Information </h1></span>
                            </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }}>
                                Nom du Responsable
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }}>
                                Prénom du Responsable
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>
                            <InputLabel required style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }} >
                                Email
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>
                            <InputLabel required style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }} >
                                Mot de passe
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>
                            <InputLabel style={{ position: "relative", lineHeight: "1px", fontFamily: "Montserrat, sans-serif" }}>
                                Paypal Email
                                                            </InputLabel>

                            <TextField

                                id="full-width"
                                label=""
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}


                                fullWidth={true}

                            />
                            <div style={{ display: "block", marginTop: "30px" }}> </div>

                            <div>
                                <NotificationContainer/>

                            </div>
                            <button style={ {height:"36px", marginTop: 15, width:"300px",
                                                                marginBottom: 15,backgroundColor:"rgba(3, 180, 115)",backgroundImage:"linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",  color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color:"rgb(255, 255, 255)",
                                                         border:"none", boxShadow:"0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)", borderRadius:"4px", textTransform:"uppercase", fontWeight:"500",
                                                         outline:"none", fontSize:"0.875rem",
                                                            }} onClick={()=> this.handleSubmit(this.state)}>Enregistrer</button>
                            










                        







                   










                </div>



                
            </div>
        );

    }
}


NewLivreur.propTypes = {
    classes: PropTypes.object.isRequired,
};




export default graphql(ADD_DELIVERYMEN_MUTATION)((withStyles(styles, { withTheme: true })(NewLivreur)))