import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import MediaQuery from 'react-responsive';

import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";

import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import Badge from '@material-ui/core/Badge';
import Assignment from '@material-ui/icons/Assignment';
import { lighten } from "@material-ui/core/styles/colorManipulator";


import gql from "graphql-tag";
import { graphql } from "react-apollo";

import { loadCommands, loadPharmacies } from "../actions";
import moment from "moment";
import Excel from './Excel';
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden'
//**********************table************/
const style = theme => ({
  container: {
    flexGrow: 1
  },
  card: {
    marginTop: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 3
  }
});

function desc(a, b, orderBy) {
  if (orderBy == "createdAt") {
    if (b.profile[orderBy] < a.profile[orderBy]) {
      return -1;
    }
    if (b.profile[orderBy] > a.profile[orderBy]) {
      return 1;
    }
    return 0;
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "num", numeric: false, disablePadding: true, label: "NUMÉRO" },
  { id: "name", numeric: false, disablePadding: true, label: "NOM" },
  { id: "createdAt", numeric: false, disablePadding: true, label: "INSCRIT LE" },
  { id: "status", numeric: true, disablePadding: false, label: "STATUT" },
  { id: "city", numeric: true, disablePadding: false, label: "VILLE" },
  { id: "", numeric: true, disablePadding: false }
];



const LOAD_PAHRMACY_QUERY = gql`
query {
  getPharmacies {
    id
    is_pill_maker
    description
    num
    tel
    name
    website
    commands {
      id
    }

    profile {
      id
      email
      profile_pic_url
      is_blocked
      createdAt
    }
    location {
      latitude
      longitude
    }

    country
    address
    city
    zip_code 
    work_schedule 
    {
      is_always_on_work
      planing {
        monday {
          open_at
          close_at
        }
        tuesday {
          open_at
          close_at
        }
        wednesday {
          open_at
          close_at
        }
        thursday {
          open_at
          close_at
        }
        friday {
          open_at
          close_at
        }
        saturday {
          open_at
          close_at
        }
        sunday {
          open_at
          close_at
        }
      }
    }
  }
}
`;
const columns = (props) => (
  [{
    label: "prim", primary: "primary", key: "id", render: (id) => {
      let pharmacy = {};
      if (props.data && props.data.getPharmacies)
        props.data.getPharmacies.forEach(cl => {
          if (cl.id == id) {
            return pharmacy = cl;
          }
        })
      return (<span style={{ fontSize: 13 }}>{pharmacy.num + " - " + (capitalizeFirstLetter(pharmacy.name))}</span>)
    }
  },
  { label: "NUMÉRO", key: "num" },
  {
    label: "NOM COMPLET", key: "name", render: (name) => {
      return (
        capitalizeFirstLetter(name)
      )
    }
  },
  {
    label: "INSCRIT LE", key: "profile", render: (profile) => {
      return (
        profile.createdAt ? moment(profile.createdAt).format("LLL") : ""
      )
    }
  },
  {
    label: "STATUT", key: "profile", render: (profile) => {
      return (
        profile.is_blocked ? "Désactivée" : "Activée"
      )
    }
  },
  {
    label: "VILLE", key: "city", render: (city) => {
      return (
        city
      )
    }
  },
  {
    label: "", key: "id", render: (id) => {
      let pharmacy = {};
      if (props.data && props.data.getPharmacies)
        props.data.getPharmacies.forEach(cl => {
          if (cl.id == id) {
            return pharmacy = cl;
          }
        })
      return <div>
        <Tooltip title={"COMMANDES"}>
          <IconButton disabled={pharmacy.commands && pharmacy.commands.length === 0} variant="contained" onClick={() => props.history.push(`/pharmacies/commands/${pharmacy.id}`)}>
            <Badge badgeContent={pharmacy.commands && pharmacy.commands.length} classes={{ badge: props.classes.badge }}>
              <Assignment style={{ fill: "#28abb3" }} />
            </Badge>

          </IconButton>
        </Tooltip>
        <Tooltip title={"AFFICHER"}>
          <IconButton style={{ fill: "#28abb3", marginLeft: '10px' }} className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/content?id=${pharmacy.id}`)}>

            <Eye style={{ color: "#28abb3" }} viewBox="0 0 24 24" />


          </IconButton>
        </Tooltip>
        <Tooltip title={"EDITER"}>
          <IconButton style={{ fill: "#28abb3" }} aria-label="EDITER" onClick={() => props.history.push(`/pharmacies/edit/${pharmacy.id}`)}>

            <EditIcon style={{ color: "#28abb3" }} />


          </IconButton>
        </Tooltip>
        {pharmacy.profile && pharmacy.profile.is_blocked ? (<IconButton className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/unblock/${pharmacy.id}`)} >
          <BlockIcon style={{ color: "rgba(232,27,12)" }} />

        </IconButton>) : <IconButton className={props.classes.button} aria-label="" onClick={() => props.history.push(`/pharmacies/delete/${pharmacy.id}`)} >
            <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />

          </IconButton>}
      </div>



    }
  }
  ]
);

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  flexDirection: "column",
                  fontFamily: "Montserrat, sans-serif"
                }}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableHead}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  boxFlex: {
    display: "flex",
  },

  '@media screen and (max-width: 991px)': {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    boxFlex: {
      flexDirection: "column",
    },


  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto",
    textTransform: "uppercase"
  }
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class EnhancedTableToolbar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classNames(classes.boxFlex)}>
        <div className={classes.title}>
          <Typography variant="title" id="tableTitle" className={classNames(classes.firstTitle)} >
            <span style={{ fontFamily: "Montserrat, sans-serif" }} className={classNames(classes.firstTitle)} >
              {" "}
              Liste des pharmacies{" "}
            </span>
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Excel {...this.props.excelProps} />
            <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
              <IconButton
                aria-label="filter"
                onClick={() => this.props.toggleFilter()}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  boxFlex: {
    display: "flex",
  },

  '@media screen and (max-width: 991px)': {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px",
    },
    boxFlex: {
      flexDirection: "column",
    },


  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  rou: {
    textAlign: "left"
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },

  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  badge: {

    top: -9,
    right: -11,
    color: `white`,
    fontSize: 10,
    fontWeight: 800,
    width: 17,
    height: 17,

    // The border color match the background color.
    border: `2px solid white`,
    backgroundColor: teal[400]

  },
  button: {
    width: 30,
    height: 30
  },
  tableCell: {
    fontSize: "13px !important"
  },
  tableHead: {
    fontSize: "14px !important"
  },
  '@media screen and  (min-width: 991px) and (max-width: 1390px)': {
    tableCell: {
      fontSize: "12px !important"
    },
    tableHead: {
      fontSize: "12px !important"
    },
  }
});

class EnhancedTable extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: window.localStorage.getItem('admin_pharmacy_rows_per_page') ? parseInt(window.localStorage.getItem('admin_pharmacy_rows_per_page')) : 25
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    window.localStorage.setItem('admin_pharmacy_rows_per_page', event.target.value)
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {

  }

  componentWillUnmount() {
    window.onscroll = null;
  }


  render() {
 
    if (this.props.location && this.props.location.state && this.props.location.state.refetch) {
      this.props.data.refetch();
    }
    let pharmacies = this.props.data && this.props.data.getPharmacies ? this.props.data.getPharmacies : [];

    const { classes, theme } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };
    pharmacies = pharmacies.filter(item => {
      let num = item.num + "";
      return this.state.filterText.trim() == ""
        ? true
        : item.address.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        || item.name.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        || item.city.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        || item.zip_code.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        || item.tel.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
        || num.toLowerCase().indexOf(this.state.filterText.toLowerCase()) > -1
    });
    let excelProps = {
      columns: [{ label: "Numéro de la pharmacie", value: "num" },
      { label: "Pharmacie", value: "pharmacy" },
      { label: "Date d'inscription", value: "date" },
      { label: "Statut", value: "state" },
      { label: "Ville", value: "city" },
      {
        label: "Nombre de commandes", value: "commandes"
      }

      ], dataSets: [], sheetName: "Pharmacies"
    }
    const { match, location, history } = this.props;
    pharmacies.forEach(pharmacie => {
      let pharmacie1 = {
        "num": pharmacie.num,
        "pharmacy": pharmacie.name,
        "date": moment(pharmacie.profile.createdAt).format("LLL"),
        "state": pharmacie.profile.is_blocked ? "DESACTIVÉ" : "ACTIVÉ",
        "city": pharmacie.city,
        "commandes": pharmacie.commands.length,

      }
      excelProps.dataSets.push(pharmacie1);
    });
    return (
      <div>
        <Typography color="textSecondary" variant="headline" />
        {this.props.data.loading && (
          <div className={classes.progressContainer}>
            <Fade in timeout={300}>
              <Typography variant="subheading" color="textSecondary">
                Chargement...
                </Typography>
            </Fade>
          </div>
        )}
        {this.props.data.loading == false && (
          <Paper className={classes.root}>
            <EnhancedTableToolbar
              refresh={this.props.loadPharmacies}
              toggleFilter={this.toggleFilter}
              isFilterShowen={this.state.showFilter}
              excelProps={excelProps}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true} timeout={200}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                </div>
              )}
              <Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={pharmacies.length}
                    classes={classes}
                  />
                  <TableBody>
                    {pharmacies
                      .sort(getSorting(order, orderBy))

                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .reverse()
                      .map((pharmacy, i) => {
                        return (
                          <TableRow
                            role="checkbox"
                            tabIndex={-1}
                            key={pharmacy.id}
                          >
                            <TableCell
                              component="th"
                              scope="row"

                              style={{
                                textTransform: "uppercase",
                                color: "#28acb3",
                                fontWeight: "bold",
                                fontFamily: "Montserrat, sans-serif"
                                
                              }}
                              className={classes.tableCell}
                            >
                              <a style={{ cursor: "pointer", marginLeft: "-10px" }} onClick={() => this.props.history.push(`/pharmacies/content?id=${pharmacy.id}`)}>{pharmacy.num}</a>
                            </TableCell>
                            <TableCell
                              component="th"
                              scope="row"

                              style={{
                                textTransform: "uppercase",
                                color: "#28acb3",
                                fontWeight: "bold",
                                textAlign: "left",
                                fontFamily: "Montserrat, sans-serif",
                                width: 200

                              }}
                              className={classes.tableCell}
                            >
                              <a style={{
                                cursor: "pointer", marginLeft: "-20px",
                                textAlign: "left",
                                display: "block"
                              }} onClick={() => this.props.history.push(`/pharmacies/content?id=${pharmacy.id}`)}>{capitalizeFirstLetter(pharmacy.name)}</a>
                            </TableCell>
                            <TableCell component="th" scope="row" padding="checkbox" >
                              <Typography variant="subheading" color="textSecondary" className={classes.tableCell} style={{ marginLeft: "-10px" }}>

                                {moment(pharmacy.profile.createdAt).format("LLL")}

                              </Typography>
                            </TableCell>


                            <TableCell >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  fontFamily: "Montserrat, sans-serif",

                                }}
                              >
                                <Typography variant="subheading" color="textSecondary" className={classes.tableCell}>
                                  {pharmacy.profile.is_blocked ? "Désactivée" : "Activée"}
                                </Typography>
                              </div>
                            </TableCell>

                            <TableCell
                              style={{
                                textAlign: "center",

                              }}
                              component="th"
                              scope="row"
                              padding="checkbox"

                            >
                              <div
                                style={{
                                  position: "relative",
                                  verticalAlign: "middle",
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  paddingLeft: 8,
                                  paddingRight: 16,
                                  TextAlign: "center",
                                  fontFamily: "Montserrat, sans-serif",

                                  fontSize: 13
                                }}
                              >
                                <Typography variant="subheading" color="textSecondary" className={classes.tableCell}>
                                  {capitalizeFirstLetter(pharmacy.city)}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell style={{ textAlign: "center" }}>





                              <Tooltip title={"COMMANDES"}>
                                <IconButton variant="contained" className={classes.button} onClick={() => this.props.history.push(`/pharmacies/commands/${pharmacy.id}`)}>
                                  <Badge badgeContent={pharmacy.commands.length} classes={{ badge: classes.badge }}>
                                    <Assignment style={{ fill: "#28abb3" }} />
                                  </Badge>

                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"AFFICHER"}>
                                <IconButton style={{ fill: "#28abb3", marginLeft: '10px' }} className={classes.button} aria-label="" onClick={() => this.props.history.push(`/pharmacies/content?id=${pharmacy.id}`)}>

                                  <Eye style={{ color: "#28abb3" }} viewBox="0 0 24 24" />

                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"EDITER"}>
                                <IconButton style={{ fill: "#28abb3" }} className={classes.button} aria-label="EDITER" onClick={() => this.props.history.push(`/pharmacies/edit/${pharmacy.id}`)}>

                                  <EditIcon style={{ color: "#28abb3" }} />


                                </IconButton>
                              </Tooltip>
                              {pharmacy.profile.is_blocked ? <IconButton className={classes.button} aria-label="" onClick={() => this.props.history.push(`/pharmacies/unblock/${pharmacy.id}`)} >
                                <BlockIcon style={{ color: "rgba(232,27,12)" }} />

                              </IconButton> : <IconButton className={classes.button} aria-label="" onClick={() => this.props.history.push(`/pharmacies/delete/${pharmacy.id}`)} >
                                  <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />

                                </IconButton>}


                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Hidden >
              <Hidden only={['lg', 'xl']} implementation={'js'}>
                <ResponsiveTable
                  columns={columns(this.props)}
                  data={pharmacies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                  showPagination={true}
                  count={pharmacies.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  excludePrimaryFromDetails={true}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Hidden>
            </div>
            <Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
              <TablePagination
                component="div"
                count={pharmacies.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Hidden>
          </Paper>
        )}
        <Zoom
          in={this.state.openFab}
          timeout={transitionDuration}
          style={{
            position: "fixed",
            right: 16,
            bottom: 100,
            transitionDelay: transitionDuration.exit,
            backgroundColor: "rgba(30,180,115)",
            color: "rgba(255,255,255)"
          }}
        >
          <Button
            style={{ backgroundColor: "rgb(47, 194, 157)" }}
            variant="fab"
            onClick={() => this.props.history.push("/pharmacies/new")}
          >
            <AddIcon />
          </Button>
        </Zoom>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ pharmacies, commands }) => ({
  pharmacies,
  commands
});

const mapDispatchToProps = dispatch => ({
  loadPharmacies: bindActionCreators(loadPharmacies, dispatch),
  loadCommands: bindActionCreators(loadCommands, dispatch)
});

export default graphql(LOAD_PAHRMACY_QUERY, {
  options: () => ({ pollInterval: 1000 })
})(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(EnhancedTable))));
