import React from "react";
import {Router, Switch, Route, Redirect} from "react-router-dom";

import history from "../history";

import Dashboard from "../pages/Dashboard/Dashboard";
import Admins from "../pages/Admins";
import Pharmacies from "../pages/Pharmacies";
import Candidates from "../pages/Candidates";
import Commands from "../pages/Commands";
import Feedback from "../pages/Feedback";
import Profile from "../pages/Profile";
import Pharmacy from "../pages/Pharmacy";
import PharmacyCommands from "../pages/PharmacyCommands";
import Client from "../pages/Client";
import Command from "../pages/Command";
import Livreur from "../pages/Livreur";
import ClientCommands from "../pages/ClientCommands";
import DeliverymanCommands from "../pages/DeliverymanCommands";
import UpdatePharmacy from "../pages/UpdatePharmacy";
import UpdateLivreur from "../pages/UpdateLivreur";
import CreateAdmin from "../pages/Dashboard/CreateAdmin";
import NewPharmacy from "../pages/NewPharmacy";
import NewLivreur from "../pages/NewLivreur";
import NewClient from "../pages/NewClient";
import DeletePharmacy from "../pages/DeletePharmacy";
import DeleteClient from "../pages/DeleteClient";
import UnblockClient from "../pages/UnblockClient";
import UpdateClient from"../pages/UpdateClient";
import UnblockPharmacy from "../pages/UnblockPharmacy";
import Categories from "../pages/Categories";
import Produits from "../pages/Produits";
import Produit from "../pages/Produit";
import ProduitDetail from "../pages/ProduitDetail";

export default (props) => (
    <Router history={history} isMenuOpen={props.isMenuOpen}>
        <div>
            <Switch isMenuOpen={props.isMenuOpen}>
               


                    <Route exact path="/" component={Dashboard}/>

                    <Route path="/create" component={CreateAdmin}/>



                
                <Route path="/admins">
                    <Switch>
                        <Route exact path="/admins" component={Admins}/>
                        <Route path="/admins/commands/:id" component={ClientCommands}/>
                        <Route path ="/admins/edit/:id" component={UpdateClient}/>
                        <Route path ="/admins/new" component={NewClient}/>
                        <Route path ="/admins/delete/:id" component={DeleteClient}/>
                        <Route path ="/admins/unblock/:id" component={UnblockClient}/>

                        <Route path="/admins/:id" component={Client}/>
                    </Switch>
                </Route>
                <Route path="/pharmacies" >
                    <Switch>
                        <Route exact path="/pharmacies" component={Pharmacies}/>
                        <Route path="/pharmacies/commands/:id" component={PharmacyCommands}/>
                        <Route path="/pharmacies/edit/:id" component={UpdatePharmacy}/>
                        <Route path ="/pharmacies/new" component={NewPharmacy}/>
                        <Route path ="/pharmacies/delete/:id" component={DeletePharmacy}/>
                        <Route path ="/pharmacies/unblock/:id" component={UnblockPharmacy}/>
                         <Route path="/pharmacies/:id" component={Pharmacy}/>
                        
                      
                    </Switch>
                </Route>
                <Route path="/candidates" >
                <Switch>
                        <Route exact path="/candidates" component={Candidates}/>
                        <Route path="/candidates/commands/:id" component={DeliverymanCommands}/>
                        <Route path="/candidates/edit/:id" component={UpdateLivreur}/>
                        <Route path ="/candidates/new" component={NewLivreur}/>
                        <Route path="/candidates/:id" component={Livreur}/>
                    </Switch>
                </Route>
                <Route path="/commands" >
                <Switch>
                        <Route exact path="/commands" component={Commands}/>
                        <Route path="/commands/:id" component={Command}/>
                    </Switch>
                    </Route>
                    <Route path="/categories" >
                <Switch>
                        <Route exact path="/categories" component={Categories}/>
                        <Route path="/categories/:id" component={Categories}/>
                    </Switch>
                    </Route>
                    <Route path="/produits" isMenuOpen={props.isMenuOpen}>
                <Switch isMenuOpen={props.isMenuOpen}>
                        <Route exact path="/produits" component={()=> <Produits isMenuOpen={props.isMenuOpen}/>} />
                        <Route exact path="/produits/produit" component={Produit}/>
                        <Route exact path="/produits/produitDetail" component={ProduitDetail} isMenuOpen={props.isMenuOpen}/>
                    </Switch>
                    </Route>
                <Route path="/feedback" component={Feedback}/>
                <Route path="/account" component={Profile}/>
            </Switch>
        </div>
    </Router>
);