import { ClientTypes } from "../../types/client.types";

const INITIAL_STATE = {
    loading: false,
    requested: false,
    error: null, 
    data: []
};

export default (state = INITIAL_STATE, action) => {
    switch(action.type){
        case ClientTypes.REQUEST_LOAD_CLIENTS:
            return {...state, loading: true, error: null};
        case ClientTypes.REQUEST_LOAD_CLIENTS_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case ClientTypes.REQUEST_LOAD_CLIENTS_ERROR:
            return {...state, loading: false, requested: true, error: action.error};


            
            case ClientTypes.REQUEST_LOAD_CLIENT:
            return {...state, loading: true, error: null};
        case ClientTypes.REQUEST_LOAD_CLIENT_SUCCESS:
            return {...state, loading: false, requested: true, data: action.data};
        case ClientTypes.REQUEST_LOAD_CLIENT_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
        default:
            return state;
    }
};