import gql from "graphql-tag";
import apolloClient from "../apolloClient";
import { PharmacyTypes } from "../types/pharmacy.types";

const LOAD_PAHRMACIES_QUERY = gql`
  query {
    getPharmacies {
      id
      is_pill_maker
      description
      num
      tel
      name
      website
      commands {
        id
      }

      profile {
        id
        email
        profile_pic_url
        is_blocked
        createdAt
      }
      location {
        latitude
        longitude
      }

      country
      address
      city
      zip_code 
      work_schedule 
      {
        is_always_on_work
        planing {
          monday {
            open_at
            close_at
          }
          tuesday {
            open_at
            close_at
          }
          wednesday {
            open_at
            close_at
          }
          thursday {
            open_at
            close_at
          }
          friday {
            open_at
            close_at
          }
          saturday {
            open_at
            close_at
          }
          sunday {
            open_at
            close_at
          }
        }
      }
    }
  }
`;

const LOAD_PAHRMACY_QUERY = gql`
  query getPharmacy($id: ID!) {
    getPharmacy(id: $id) {
      id
      is_pill_maker
      tel
      name
      num
      website
      description
      profile {
        id
        email
        profile_pic_url
        is_blocked
        createdAt
      }
      location {
        latitude
        longitude
      }
      commands {
        id
      }
      country
      address
      city
      zip_code 
      work_schedule {
        is_always_on_work
        planing {
          monday {
            open_at
            close_at
          }
          tuesday {
            open_at
            close_at
          }
          wednesday {
            open_at
            close_at
          }
          thursday {
            open_at
            close_at
          }
          friday {
            open_at
            close_at
          }
          saturday {
            open_at
            close_at
          }
          sunday {
            open_at
            close_at
          }
        }
      }
    }
  }
`;

const loadPharmacies = () => dispatch => {
  dispatch({ type: PharmacyTypes.REQUEST_LOAD_PHAMRACIES });
  apolloClient
    .query({ query: LOAD_PAHRMACIES_QUERY })

    .then(response => {
 
      dispatch({
        type: PharmacyTypes.REQUEST_LOAD_PHAMRACIES_SUCCESS,
        data: response.data.getPharmacies
      });
    })
    .catch(error => {
   
      dispatch({ type: PharmacyTypes.REQUEST_LOAD_PHAMRACIES_SUCCESS, error });
    });
};

const loadPharmacy = id => dispatch => {
  dispatch({ type: PharmacyTypes.REQUEST_LOAD_PHAMRACY });
  apolloClient
    .query({ query: LOAD_PAHRMACY_QUERY })

    .then(response => {
   
      dispatch({
        type: PharmacyTypes.REQUEST_LOAD_PHAMRACY_SUCCESS,
        data: response.data.getPharmacy(id)
      });
    })
    .catch(error => {
    
      dispatch({ type: PharmacyTypes.REQUEST_LOAD_PHAMRACY_SUCCESS, error });
    });
};

export { loadPharmacies, loadPharmacy };
 