import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import Eye from "@material-ui/icons/RemoveRedEye";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import teal from "@material-ui/core/colors/teal";
import DeleteIcon from "@material-ui/icons/Delete";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { loadClients, loadCommands } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import EditIcon from "@material-ui/icons/Edit";
import Badge from "@material-ui/core/Badge";
import Assignment from "@material-ui/icons/Assignment";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import moment from "moment";
import MediaQuery from "react-responsive";
import Excel from "./Excel";
import ResponsiveTable from "material-ui-next-responsive-table";
import Hidden from "@material-ui/core/Hidden";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";
/**************************************Table des patients***********************************************************/
function desc(a, b, orderBy) {
  if (orderBy == "email") {
    if (b.profile[orderBy] < a.profile[orderBy]) {
      return -1;
    }
    if (b.profile[orderBy] > a.profile[orderBy]) {
      return 1;
    }
    return 0;
  } else if (orderBy == "first_name") {
    let bFullName = b.last_name + " " + b.first;
    let aFullName = a.last_name + " " + a.first_name;
    if (bFullName < aFullName) {
      return -1;
    }
    if (bFullName > aFullName) {
      return 1;
    }
    return 0;
  } else if (orderBy == "createdAt") {
    if (b.profile[orderBy] < a.profile[orderBy]) {
      return -1;
    }
    if (b.profile[orderBy] > a.profile[orderBy]) {
      return 1;
    }
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getSorting(order, orderBy) {

  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "num", numeric: false, disablePadding: true, label: "NUMÉRO" },
  {
    id: "first_name",
    numeric: false,
    disablePadding: true,
    label: "NOM COMPLET"
  },
  {
    id: "createdAt",
    numeric: false,
    disablePadding: true,
    label: "INSCRIT LE"
  },
  { id: "status", numeric: false, disablePadding: true, label: "STATUT" },
  { id: "email", numeric: false, disablePadding: false, label: "EMAIL" },
  { id: "", numeric: true, disablePadding: false, label: "" }
];
const columns = props => [
  {
    label: "prim",
    primary: "primary",
    key: "id",
    render: id => {
      let client = {};
      if (props.data && props.data.getClients)
        props.data.getClients.forEach(cl => {
          if (cl.id == id) {
            return (client = cl);
          }
        });
      return (
        <span style={{ fontSize: 13 }}>
          {client.num +
            " - " +
            capitalizeFirstLetter(client.last_name) +
            " " +
            capitalizeFirstLetter(client.first_name)}
        </span>
      );
    }
  },
  { label: "NUMÉRO", key: "num" },
  {
    label: "NOM COMPLET",
    key: "id",
    render: id => {
      let client = {};
      if (props.data && props.data.getClients)
        props.data.getClients.forEach(cl => {
          if (cl.id == id) {
            return (client = cl);
          }
        });
      return (
        <span style={{ fontSize: 13 }}>
          {capitalizeFirstLetter(client.last_name + " " + client.first_name)}
        </span>
      );
    }
  },
  {
    label: "INSCRIT LE",
    key: "profile",
    render: profile => {
      return profile.createdAt ? moment(profile.createdAt).format("LLL") : "";
    }
  },
  {
    label: "EMAIL",
    key: "profile",
    render: email => {
      return "email";
    }
  },
  {
    label: "",
    key: "id",
    render: id => {
      let client = {};
      if (props.data && props.data.getClients)
        props.data.getClients.forEach(cl => {
          if (cl.id == id) {
            return (client = cl);
          }
        });
   
      return (
        <div style={{ display: "flex" }} className="eye">
          <Tooltip title={"COMMANDES"}>
            <IconButton
              disabled={client.commands.length === 0}
              variant="contained"
              onClick={() =>
                props.history.push(`/admins/commands/${client.id}`)
              }
            >
              <Badge
                badgeContent={client.commands.length}
                classes={{ badge: props.classes.badge }}
              >
                <Assignment style={{ fill: "#28abb3" }} />
              </Badge>
            </IconButton>
          </Tooltip>
          <Tooltip title={"AFFICHER"}>
            <IconButton
              style={{ fill: "#28abb3", marginLeft: "10px" }}
              className={props.classes.button}
              aria-label=""
              onClick={() =>
                props.history.push(`/admins/content?id=${client.id}`)
              }
            >
              <Eye style={{ color: "#28abb3" }} viewBox="0 0 24 24" />
            </IconButton>
          </Tooltip>
          <Tooltip title={"EDITER"}>
            <IconButton
              style={{ fill: "#28abb3" }}
              aria-label="EDITER"
              onClick={() => props.history.push(`/admins/edit/${client.id}`)}
            >
              <EditIcon style={{ color: "#28abb3" }} />
            </IconButton>
          </Tooltip>
          {client.profile.is_blocked ? (
            <IconButton
              className={props.classes.button}
              aria-label=""
              onClick={() => props.history.push(`/admins/unblock/${client.id}`)}
            >
              <BlockIcon style={{ color: "rgba(232,27,12)" }} />
            </IconButton>
          ) : (
            <IconButton
              className={props.classes.button}
              aria-label=""
              onClick={() => props.history.push(`/admins/delete/${client.id}`)}
            >
              <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />
            </IconButton>
          )}
        </div>
      );
    }
  }
];
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy, classes } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                style={{
                  textAlign: "left",
                  fontSize: 16,
                  flexDirection: "column",
                  fontFamily: "Montserrat, sans-serif"
                }}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
                className={classes.tableHead}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};
const toolbarStyles = theme => ({
  root: {},
  boxFlex: {
    display: "flex"
  },

  "@media screen and (max-width: 991px)": {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    boxFlex: {
      flexDirection: "column"
    }
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto",
    textTransform: "uppercase",
    fontFamily: "Montserrat, sans-serif"
  }
});

class EnhancedTableToolbar extends React.Component {
  render() {
    const { classes } = this.props;
    const tooltip = "tooltip";

    return (
      <Toolbar className={classNames(classes.boxFlex)}>
        <div className={classes.title}>
          <Typography
            variant="title"
            id="tableTitle"
            className={classNames(classes.firstTitle)}
            style={{ width: "100%" }}
          >
            <span
              style={{ fontFamily: "Montserrat, sans-serif" }}
              className={classNames(classes.firstTitle)}
            >
              {" "}
              Liste des patients{" "}
            </span>
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Excel {...this.props.excelProps} />
            <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
              <IconButton
                aria-label="filter"
                onClick={() => this.props.toggleFilter()}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },

  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  button: {
    width: 30,
    height: 30
  },
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  },
  badge: {
    top: -9,
    right: -11,
    color: `white`,
    fontSize: 10,
    fontWeight: 800,
    width: 17,
    height: 17,

    // The border color match the background color.
    border: `2px solid white`,
    backgroundColor: teal[400]
  },
  tableCell: {
    fontSize: "14px !important"
  },
  tableHead: {
    fontSize: "14px !important"
  },
  "@media screen and  (min-width: 991px) and (max-width: 1390px)": {
    tableCell: {
      fontSize: "12px !important"
    },
    tableHead: {
      fontSize: "12px !important"
    }
  }
});
const LOAD_PATIENTS_QUERY = gql`
  query {
    getClients {
      id
      num
      profile {
        id
        email
        profile_pic_url
        is_verified
        is_blocked
        createdAt
      }
      commands {
        id
        num
      }
      first_name
      last_name
      tel
      address
    }
  }
`;

class EnhancedTable extends React.Component {
  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "first_name",
      page: 0,
      rowsPerPage: window.localStorage.getItem("admin_patient_rows_per_page")
        ? parseInt(window.localStorage.getItem("admin_patient_rows_per_page"))
        : 25
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = event => {
    window.localStorage.setItem(
      "admin_patient_rows_per_page",
      event.target.value
    );
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {}

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.refetch
    ) {
      this.props.data.refetch();
    }
    let clients =
      this.props.data && this.props.data.getClients
        ? this.props.data.getClients
        : [];
    let excelProps = {
      columns: [
        { label: "Numéro du patient", value: "num" },
        { label: "Nom complet", value: "patient" },
        { label: "Date d'inscription", value: "date" },
        { label: "Statut", value: "state" },
        { label: "Nombre de Commandes", value: "commandes" }
      ],
      dataSets: [],
      sheetName: "Patients"
    };
    const { classes, theme } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };
    clients = clients.filter(item => {
      let fullname = item.last_name + " " + item.first_name;
      fullname = fullname.toLowerCase();
      let num = item.num + "";
      let filter = this.state.filterText.toLowerCase();
      return filter.trim() == ""
        ? true
        : item.profile.email.toLowerCase().indexOf(filter) > -1 ||
            fullname.indexOf(filter) > -1 ||
            num.indexOf(filter) > -1;
    });
    clients.forEach(client => {
      let client1 = {
        num: client.num,
        patient: client.last_name + " " + client.first_name,
        date: moment(client.profile.createdAt).format("LLL"),
        state: client.profile.is_blocked ? "DESACTIVÉ" : "ACTIVÉ",
        commandes: client.commands.length
      };
      excelProps.dataSets.push(client1);
    });
    return (
      <div>
        <Typography color="textSecondary" variant="headline" />
        {this.props.data && this.props.data.loading && (
          <div className={classes.progressContainer}>
            <Fade>
              <Typography variant="subheading" color="textSecondary">
                Chargement...
              </Typography>
            </Fade>
          </div>
        )}
        {this.props.data && this.props.data.loading == false && (
          <Paper className={classes.root}>
            <EnhancedTableToolbar
              refresh={this.props.data.refetch}
              toggleFilter={this.toggleFilter}
              isFilterShowen={this.state.showFilter}
              excelProps={excelProps}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                </div>
              )}
              <Hidden only={["xs", "sm", "md"]} implementation={"js"}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <EnhancedTableHead
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={this.handleRequestSort}
                    rowCount={clients.length}
                    classes={classes}
                  />
                  <TableBody>
                    {clients
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .sort(getSorting(order, orderBy))
                      .reverse()
                      .map((client, i) => {
                        return (
                          <TableRow tabIndex={-1} key={client.id}>
                            <TableCell component="th" scope="row">
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  marginLeft: "-20px"
                                }}
                              >
                                <Avatar
                                  alt="avatar"
                                  src={
                                    client.profile.profile_pic_url
                                      ? client.profile.profile_pic_url
                                      : "http://pharmaloop.fr/img/profile-icon.png"
                                  }
                                  className={classes.avatar}
                                />
                                <a
                                  style={{
                                    textTransform: "uppercase",
                                    color: "#28acb3",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    cursor: "pointer"
                                  }}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/content?id=${client.id}`
                                    )
                                  }
                                  className={classes.tableCell}
                                >
                                  {client.num}
                                </a>
                              </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <div>
                                <a
                                  style={{
                                    textTransform: "uppercase",
                                    color: "#28acb3",
                                    fontWeight: "bold",
                                    fontFamily: "Montserrat, sans-serif",
                                    cursor: "pointer",
                                    marginLeft: "-20px"
                                  }}
                                  className={classes.tableCell}
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/content?id=${client.id}`
                                    )
                                  }
                                >
                                  {capitalizeFirstLetter(client.last_name) +
                                    " " +
                                    capitalizeFirstLetter(client.first_name)}
                                </a>
                              </div>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="subheading"
                                color="textSecondary"
                                className={classes.tableCell}
                                style={{ marginLeft: "-20px" }}
                              >
                                {moment(client.profile.createdAt).format("LLL")}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="subheading"
                                color="textSecondary"
                                className={classes.tableCell}
                                style={{ marginLeft: "-20px" }}
                              >
                                {client.profile.is_blocked
                                  ? "DESACTIVÉ"
                                  : "ACTIVÉ"}
                              </Typography>
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <Typography
                                variant="subheading"
                                color="textSecondary"
                                className={classes.tableCell}
                              >
                                {client.profile.email}
                              </Typography>
                            </TableCell>

                            <TableCell style={{ display: "flex" }}>
                              <Tooltip title={"COMMANDES"}>
                                <IconButton
                                  disabled={client.commands.length === 0}
                                  classes={{ root: classes.button }}
                                  variant="contained"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/commands/${client.id}`
                                    )
                                  }
                                >
                                  <Badge
                                    badgeContent={client.commands.length}
                                    classes={{ badge: classes.badge }}
                                  >
                                    <Assignment style={{ fill: "#28abb3" }} />
                                  </Badge>
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"AFFICHER"}>
                                <IconButton
                                  style={{
                                    fill: "#28abb3",
                                    marginLeft: "10px"
                                  }}
                                  classes={{ root: classes.button }}
                                  aria-label=""
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/content?id=${client.id}`
                                    )
                                  }
                                >
                                  <Eye
                                    style={{ color: "#28abb3" }}
                                    viewBox="0 0 24 24"
                                  />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={"EDITER"}>
                                <IconButton
                                  style={{ fill: "#28abb3" }}
                                  classes={{ root: classes.button }}
                                  aria-label="EDITER"
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/edit/${client.id}`
                                    )
                                  }
                                >
                                  <EditIcon style={{ color: "#28abb3" }} />
                                </IconButton>
                              </Tooltip>
                              {client.profile.is_blocked ? (
                                <IconButton
                                  classes={{ root: classes.button }}
                                  aria-label=""
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/unblock/${client.id}`
                                    )
                                  }
                                >
                                  <BlockIcon
                                    style={{ color: "rgba(232,27,12)" }}
                                  />
                                </IconButton>
                              ) : (
                                <IconButton
                                  classes={{ root: classes.button }}
                                  aria-label=""
                                  onClick={() =>
                                    this.props.history.push(
                                      `/admins/delete/${client.id}`
                                    )
                                  }
                                >
                                  <CheckCircleOutlineIcon
                                    style={{ color: "#28abb3" }}
                                  />
                                </IconButton>
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 49 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </Hidden>
              <Hidden only={["lg", "xl"]} implementation={"js"}>
                <ResponsiveTable
                  columns={columns(this.props)}
                  data={clients.slice(
                    page * rowsPerPage,
                    page * rowsPerPage + rowsPerPage
                  )}
                  showPagination={true}
                  count={clients.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  excludePrimaryFromDetails={true}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                />
              </Hidden>
            </div>
            <Hidden only={["xs", "sm", "md"]} implementation={"js"}>
              <TablePagination
                component="div"
                count={clients.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{
                  "aria-label": "Previous Page"
                }}
                nextIconButtonProps={{
                  "aria-label": "Next Page"
                }}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
              />
            </Hidden>
          </Paper>
        )}
        <Zoom
          in={this.state.openFab}
          timeout={transitionDuration}
          style={{
            position: "fixed",
            right: 16,
            bottom: 100,
            transitionDelay: transitionDuration.exit,
            backgroundColor: "rgba(30,180,115)",
            color: "rgba(255,255,255)"
          }}
        >
          <Button
            variant="fab"
            style={{ backgroundColor: "rgb(47, 194, 157)" }}
            onClick={() => this.props.history.push("/admins/new")}
          >
            <AddIcon />
          </Button>
        </Zoom>
      </div>
    );
  }
}

EnhancedTable.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ clients, commands }) => ({ clients, commands });
const mapDispatchToProps = dispatch => ({
  loadClients: bindActionCreators(loadClients, dispatch),
  loadCommands: bindActionCreators(loadCommands, dispatch)
});

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(EnhancedTable));
export default compose(graphql(LOAD_PATIENTS_QUERY))(
  withStyles(styles, { withTheme: true })(EnhancedTable)
);
