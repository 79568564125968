import React from "react";
import { withRouter } from "react-router-dom";
import CardHeader, { withStyles, Checkbox } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadPharmacies } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Horaire from "./Horaire";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import MapComponent from "./MapComponent";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Icon from "@material-ui/core/Icon";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import BlockIcon from "@material-ui/icons/Block";
import Badge from "@material-ui/core/Badge";
import Assignment from "@material-ui/icons/Assignment";
import Eye from "@material-ui/icons/RemoveRedEye";
import EditIcon from "@material-ui/icons/Edit";
import MediaQuery from "react-responsive";
import moment from "moment";

/******LISTSTYLES************************************* */
const liststyles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  boxFlex: {
    display: "flex"
  },
  firstTitle: {
    flex: " 0 0 auto",
    paddingTop: "20px"
  },

  "@media screen and (max-width: 991px)": {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    boxFlex: {
      display: "flex",
      flexDirection: "column"
    },
    boxFlex1: {
      width: "100% !important ",
      paddingLeft: "0px !important "
    },
    boxFlex2: {
      width: "100% !important "
    },
    lit: {
      fontSize: "14px",
      marginBottom: "10px",
      marginTop: "10px;"
    },
    titi: {
      padding: "0 !important"
    }
  },

  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class EnhancedTableToolbar extends React.Component {
  render() {
    const { classes, pharmacy, history } = this.props;

    return (
      <Toolbar className={classNames(classes.boxFlex)}>
        <div />
        <div className={classNames(classes.firstTitle)}>
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif" }}
            color="textSecondary"
            variant="headline"
          >
            PHARMACIE N° {pharmacy.num} -{" "}
            <span style={{ textTransform: "uppercase" }}>{pharmacy.name}</span>
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={"COMMANDES"}>
              <IconButton
                disabled={pharmacy.commands.length === 0}
                variant="contained"
                onClick={() =>
                  history.push(`/pharmacies/commands/${pharmacy.id}`)
                }
              >
                <Badge
                  badgeContent={pharmacy.commands.length}
                  classes={{ badge: classes.badge }}
                >
                  <Assignment style={{ fill: "#28abb3" }} />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title={"EDITER"}>
              <IconButton
                style={{ fill: "#28abb3" }}
                className={classes.button}
                aria-label="EDITER"
                onClick={() => history.push(`/pharmacies/edit/${pharmacy.id}`)}
              >
                <EditIcon style={{ color: "#28abb3" }} />

                <div
                  style={{
                    fill: "#28abb3",
                    position: "relative",
                    paddingLeft: 8,
                    paddingRight: 16,

                    verticalAlign: "middle",

                    fontWeight: 500,
                    fontSize: 14,
                    fontFamily: "Montserrat, sans-serif"
                  }}
                />
              </IconButton>
            </Tooltip>
            {pharmacy.profile.is_blocked ? (
              <IconButton
                className={classes.button}
                aria-label=""
                onClick={() =>
                  history.push(`/pharmacies/unblock/${pharmacy.id}`)
                }
              >
                <BlockIcon style={{ color: "rgba(232,27,12)" }} />
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 22,
                    paddingRight: 0,

                    verticalAlign: "middle",

                    fontWeight: 500,
                    fontSize: 14
                  }}
                />
              </IconButton>
            ) : (
              <IconButton
                className={classes.button}
                aria-label=""
                onClick={() =>
                  history.push(`/pharmacies/delete/${pharmacy.id}`)
                }
              >
                <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 8,
                    paddingRight: 16,

                    verticalAlign: "middle",

                    fontWeight: 500,
                    fontSize: 14
                  }}
                />
              </IconButton>
            )}

            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  boxFlex: {
    display: "flex"
  },
  "@media screen and (max-width: 991px)": {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    boxFlex: {
      display: "flex",
      flexDirection: "column",
      padding: "0 !important"
    },
    boxFlex1: {
      width: "100% !important ",
      paddingLeft: "0px !important "
    },
    boxFlex2: {
      width: "100% !important "
    },
    table: {
      padding: "0 !important"
    }
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
/***************************************************Page PHARMACY************************************************* */

class Pharmacy extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 5,
      checkedA: true,
      checkedB: true,
      checkedC: false
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {
    this.props.loadPharmacies();
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };

  render() {
    const { classes, theme } = this.props;

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };

    let pharmacy;

    this.props.pharmacies.data.map(pharma => {
      if (
        pharma.id ===
        window.location.hash.substring(window.location.hash.indexOf("id=") + 3)
      ) {
        pharmacy = pharma;
      }
    });

    return (
      <div>
        <Typography color="textSecondary" variant="headline" />

        {this.props.pharmacies.loading &&
          this.props.pharmacies.data.length == 0 && (
            <div className={classes.progressContainer}>
              <Fade in timeout={300}>
                <Typography variant="subheading" color="textSecondary">
                  Chargement...
                </Typography>
              </Fade>
            </div>
          )}
        {this.props.pharmacies.data.length > 0 && (
          <Paper className={classes.root}>
            <EnhancedTableToolbar
              refresh={this.props.loadPharmacies}
              toggleFilter={this.toggleFilter}
              pharmacy={pharmacy}
              history={this.props.history}
              isFilterShowen={this.state.showFilter}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true} timeout={200}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                </div>
              )}

              {this.props.pharmacies.data.map(pharmacy => {
                let url = "";
                if (pharmacy.profile.is_blocked) {
                  url = `/admins/unblock/${pharmacy.id}`;
                } else {
                  url = `/admins/delete/${pharmacy.id}`;
                }
                if (
                  pharmacy.id ===
                  window.location.hash.substring(
                    window.location.hash.indexOf("id=") + 3
                  )
                )
                  return (
                    <div className={classes.root}>
                      <Card
                        style={{
                          marginBottom: 20,
                          backgroundColor: "#f3f6f759"
                        }}
                      >
                        <CardContent>
                          <Typography color="textSecondary" variant="headline">
                            Informations de la pharmacie{" "}
                            <strong> N° {pharmacy.num}</strong>
                          </Typography>
                          <div className={classes.boxFlex}>
                            <div
                              style={{ width: "80%", padding: 10 }}
                              className={classes.boxFlex1}
                            >
                              <table
                                className="table"
                                className={classNames(classes.titi)}
                              >
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Nom :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {capitalizeFirstLetter(pharmacy.name)}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Ville :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {capitalizeFirstLetter(pharmacy.city)}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Date de création :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {" "}
                                      {moment(
                                        pharmacy.profile.createdAt
                                      ).format("LLL")}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Statut :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.profile.is_blocked ? (
                                        <div>
                                          <BlockIcon
                                            style={{ color: "rgba(232,27,12)" }}
                                          />{" "}
                                          Bloqué
                                        </div>
                                      ) : (
                                        <div
                                          className={classNames(classes.lit)}
                                        >
                                          <CheckCircleOutlineIcon
                                            BlockIcon
                                            style={{ color: "#28abb3" }}
                                          />{" "}
                                          Activé{" "}
                                        </div>
                                      )}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Pilulier personnalisé
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {" "}
                                      {pharmacy.is_pill_maker ? (
                                        <div
                                          className={classNames(classes.lit)}
                                        >
                                          <CheckCircleOutlineIcon
                                            style={{ color: "#28abb3" }}
                                          />{" "}
                                          Oui
                                        </div>
                                      ) : (
                                        <div
                                          className={classNames(classes.lit)}
                                        >
                                          {" "}
                                          <BlockIcon
                                            style={{ color: "rgba(232,27,12)" }}
                                          />{" "}
                                          Non
                                        </div>
                                      )}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Site web:{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.website}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Email :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.profile.email}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Téléphone :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.tel}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                              </table>
                            </div>
                            <div
                              style={{ width: "20%" }}
                              className={classes.boxFlex2}
                            >
                              <CardMedia
                                style={{ height: "100" }}
                                image={pharmacy.profile.profile_pic_url}
                                title={capitalizeFirstLetter(pharmacy.name)}
                                style={{ height: "200px" }}
                              />
                            </div>
                          </div>
                        </CardContent>
                      </Card>
                      <Card
                        style={{
                          marginBottom: 20,
                          backgroundColor: "#f3f6f759"
                        }}
                      >
                        <CardContent>
                          <Typography color="textSecondary" variant="headline">
                            Adresse
                          </Typography>
                          <div className={classes.boxFlex}>
                            <div
                              style={{ width: "100%", padding: 10 }}
                              className={classes.boxFlex1}
                            >
                              <table
                                className="table"
                                className={classNames(classes.titi)}
                              >
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Adresse :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.address}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Ville :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {capitalizeFirstLetter(pharmacy.city)}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                                <tr>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      Code Postal :{" "}
                                    </Typography>
                                  </td>
                                  <td>
                                    <Typography
                                      color="textSecondary"
                                      variant="subheading"
                                      className={classNames(classes.lit)}
                                    >
                                      {pharmacy.zip_code}{" "}
                                    </Typography>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </CardContent>
                      </Card>

                      <Card
                        style={{
                          marginBottom: 20,
                          backgroundColor: "#f3f6f759"
                        }}
                      >
                      <CardContent>
                      <Typography color="textSecondary" variant="headline">
                        Horaires d'ouverture
                      </Typography>
                      <table className="table" />
                      <table className="table">
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Lundi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.monday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.monday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.monday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.monday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.monday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.monday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.monday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.monday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Mardi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.tuesday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.tuesday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.tuesday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.tuesday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.tuesday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.tuesday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.tuesday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.tuesday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Mercredi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.wednesday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.wednesday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.wednesday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.wednesday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.wednesday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.wednesday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.wednesday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.wednesday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Jeudi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.thursday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.thursday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.thursday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.thursday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.thursday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.thursday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.thursday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.thursday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>{" "}
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Vendredi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.friday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.friday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.friday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.friday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.friday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.friday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.friday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.friday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Samedi :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.saturday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.saturday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.saturday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.saturday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.saturday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.saturday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.saturday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.saturday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <Typography
                              color="textSecondary"
                              variant="subheading"
                            >
                              Dimanche :
                            </Typography>
                          </td>
                          <td>
                            <div>
                              <Typography
                                color="textSecondary"
                                variant="subheading"
                              >
                                {pharmacy.work_schedule.planing.sunday &&
                                  "Matin : " +
                                    pharmacy.work_schedule.planing.sunday[0]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.sunday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.sunday[0]
                                      .close_at +
                                    ""}
                                     {pharmacy.work_schedule.planing.sunday &&
                                  "" +
                                  " "+"  ----------  Après midi :   "+pharmacy.work_schedule.planing.sunday[1]
                                      .open_at +
                                    ""}{" "}
                                {pharmacy.work_schedule.planing.sunday &&
                                  " - " +
                                    pharmacy.work_schedule.planing.sunday[1]
                                      .close_at +
                                    ""}
                              </Typography>
                            </div>
                          </td>
                        </tr>
                        <table />
                      </table>
                    </CardContent>
                      </Card>
                    </div>
                  );
              })}
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

Pharmacy.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ pharmacies }) => ({ pharmacies });

const mapDispatchToProps = dispatch => ({
  loadPharmacies: bindActionCreators(loadPharmacies, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(Pharmacy)));
