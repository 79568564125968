import React from "react";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import CardTitle from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";

import {connect} from "react-redux";
import{bindActionCreators} from "redux";
import Button from "@material-ui/core/Button";
import DirectionsBike from "@material-ui/icons/DirectionsBike";

import Assignmentturnedin from "@material-ui/icons/AssignmentTurnedIn";
import Group from"@material-ui/icons/Group";
import Equalizer from "@material-ui/icons/Equalizer";
import IconButton from "@material-ui/core/IconButton";



const stylesIcon = {
    card: { borderLeft: "solid 4px #f44336", flex: 1 },
    icon: { float: "right", width: 64, height: 64, padding: 16, color: "#f44336" },
};

const style = theme => ({
    container: {
        flexGrow: 1,
    },
    card: {
        marginTop: theme.spacing.unit * 5,
        padding: theme.spacing.unit * 3,
    }
});
const stylesWelcome = {
    welcome: { marginBottom: "1em" },
    button: { width: "97%", margin: "1.5%" },
    flex: { display: "flex", marginBottom: "2em" },
    leftCol: { flex: 1, marginRight: "1em" },
    rightCol: { flex: 1, marginLeft: "1em" },
    singleCol: { flex: 1 }
};



class PrixCommandeValideCB extends React.Component {

    componentWillMount(){
        
        
        
        
        
    }

    render() {
        
        const {classes} = this.props;
        
        return (
            
                    
                
    
            
                <Card style={stylesIcon.card}>
                    <Assignmentturnedin style={stylesIcon.icon} />
                    <CardTitle subtitle="Pharmacies" />
                      
                </Card>
            
            
            
            
            
            

        );
    }
}


export default ((withStyles( { withTheme: true })(PrixCommandeValideCB)));