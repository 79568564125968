import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadClients } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";

import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ListSubheader from "@material-ui/core/ListSubheader";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import MapComponent from "./MapComponent";
import admins from "./Admins";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import Badge from "@material-ui/core/Badge";
import Assignment from "@material-ui/icons/Assignment";
import BlockIcon from "@material-ui/icons/Block";
import MediaQuery from "react-responsive";

/******LISTSTYLES************************************* */
const liststyles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  },
  boxFlex: {
    display: "flex"
  },

  "@media screen and (max-width: 991px)": {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    boxFlex: {
      flexDirection: "column"
    }
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const rows = [
  { id: "name", numeric: false, disablePadding: true, label: "NOM" },
  { id: "website", numeric: true, disablePadding: false, label: "SITE WEB" },
  { id: "tel", numeric: true, disablePadding: false, label: "TELEPHONE" },
  { id: "city", numeric: true, disablePadding: false, label: "VILLE" },
  { id: "", numeric: true, disablePadding: false, label: "NB DE COMMANDE" },
  { id: "", numeric: true, disablePadding: false, label: "COMMANDES " },
  { id: "", numeric: true, disablePadding: false, label: "AFFICHER " },
  { id: "", numeric: true, disablePadding: false, label: "EDITER " },
  { id: "", numeric: true, disablePadding: false, label: "DÉSACTIVER " }
];

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  boxFlex: {
    display: "flex"
  },

  "@media screen and (max-width: 991px)": {
    firstTitle: {
      fontSize: "22px !important",
      textAlign: "center !important",
      paddingTop: "20px",
      paddingBottom: "20px"
    },
    boxFlex: {
      flexDirection: "column"
    }
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class EnhancedTableToolbar extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { classes, client, history } = this.props;

    return (
      <Toolbar className={classNames(classes.boxFlex)}>
        <div className={classes.firstTitle}>
          <Typography
            style={{ fontFamily: "Montserrat, sans-serif", width: "600px" }}
            color="textSecondary"
            variant="headline"
            className={classNames(classes.firstTitle)}
          >
            PATIENT N° {client.num} -{" "}
            <span
              style={{ textTransform: "uppercase" }}
              className={classNames(classes.firstTitle)}
            >
              {client.first_name + " " + client.last_name}
            </span>
          </Typography>
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={"EDITER"}>
              <IconButton
                style={{ fill: "#28abb3" }}
                aria-label="EDITER"
                onClick={() => history.push(`/admins/edit/${client.id}`)}
              >
                <EditIcon style={{ color: "#28abb3" }} />
              </IconButton>
            </Tooltip>
            {client.profile.is_blocked ? (
              <IconButton
                className={classes.button}
                aria-label=""
                onClick={() => history.push(`/admins/unblock/${client.id}`)}
              >
                <BlockIcon style={{ color: "rgba(232,27,12)" }} />
              </IconButton>
            ) : (
              <IconButton
                className={classes.button}
                aria-label=""
                onClick={() => thistory.push(`/admins/delete/${client.id}`)}
              >
                <CheckCircleOutlineIcon style={{ color: "#28abb3" }} />
              </IconButton>
            )}
            <Tooltip title={"COMMANDES"}>
              <IconButton
                disabled={client.commands.length === 0}
                variant="contained"
                onClick={() =>
                  this.props.history.push(`/admins/commands/${client.id}`)
                }
              >
                <Badge
                  badgeContent={client.commands.length}
                  classes={{ badge: classes.badge }}
                >
                  <Assignment style={{ fill: "#28abb3" }} />
                </Badge>
              </IconButton>
            </Tooltip>

            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 70,
    height: 70,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
/***************************************************Page PHARMACY************************************************* */

class Client extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 5
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    handleChangePage = (event, page) => this.setState({ page });

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {
    this.props.loadClients();
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    const clients = this.props.clients.data;
    const { classes, theme } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };

    const { match, location, history } = this.props;

    let client;

    this.props.clients.data.map(clien => {
      if (
        clien.id ===
        window.location.hash.substring(window.location.hash.indexOf("id=") + 3)
      ) {
        client = clien;
      }
    });

    return (
      <div>
        {this.props.clients.loading && this.props.clients.data.length == 0 && (
          <div className={classes.progressContainer}>
            <Fade in timeout={300}>
              <Typography variant="subheading" color="textSecondary">
                Chargement...
              </Typography>
            </Fade>
          </div>
        )}
        {this.props.clients.data.length > 0 && (
          <Paper className={classes.root}>
            <EnhancedTableToolbar
              refresh={this.props.loadClients}
              toggleFilter={this.toggleFilter}
              client={client}
              history={this.props.history}
              isFilterShowen={this.state.showFilter}
            />
            <div className={classes.tableWrapper}>
              {this.state.showFilter && (
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Zoom in={true} timeout={200}>
                    <TextField
                      style={{ marginRight: 20 }}
                      placeholder={"Chercher..."}
                      autoFocus
                      value={this.state.filterText}
                      onChange={this.handleFilterChange}
                    />
                  </Zoom>
                </div>
              )}

              {this.props.clients.data.map(client => {
                let url = "";
                if (client.profile.is_blocked) {
                  url = `/admins/unblock/${client.id}`;
                } else {
                  url = `/admins/delete/${client.id}`;
                }
                if (
                  client.id ===
                  window.location.hash.substring(
                    window.location.hash.indexOf("id=") + 3
                  )
                )
                  return (
                    <div className={classes.root}>
                      <Typography color="textSecondary" variant="headline">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            color: "#27aab3",
                            marginLeft: "20px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          <Avatar
                            alt="avatar"
                            src={
                              client.profile.profile_pic_url
                                ? client.profile.profile_pic_url
                                : "http://pharmaloop.fr/img/profile-icon.png"
                            }
                            style={{
                              marginRight: "20px",
                              width: 70,
                              height: 70
                            }}
                          />
                        </div>
                      </Typography>
                      <List component="nav">
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Numéro du patient
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={client.num}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Nom de famille
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={capitalizeFirstLetter(client.last_name)}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Prénom
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={capitalizeFirstLetter(client.first_name)}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Email
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={client.profile.email}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Téléphone
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={client.tel}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText
                            primary={
                              <span
                                style={{ fontFamily: "Montserrat, sans-serif" }}
                              >
                                {" "}
                                <Typography
                                  variant="subheading"
                                  color="textSecondary"
                                >
                                  Adresse
                                </Typography>
                              </span>
                            }
                            style={{ fontFamily: "Montserrat, sans-serif" }}
                            secondary={client.address}
                          />
                        </ListItem>
                      </List>
                    </div>
                  );
              })}
            </div>
          </Paper>
        )}
      </div>
    );
  }
}

Client.propTypes = {
  classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ clients }) => ({ clients });

const mapDispatchToProps = dispatch => ({
  loadClients: bindActionCreators(loadClients, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles, { withTheme: true })(Client)));
