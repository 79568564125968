import React from "react";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Badge from "@material-ui/core/Badge";
import NotificationsIcon from "@material-ui/icons/Notifications";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import Tooltip from '@material-ui/core/Tooltip';
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import BusinessIcon from "@material-ui/icons/Business";
import InboxIcon from "@material-ui/icons/Inbox";
import SchoolIcon from "@material-ui/icons/School";
import PowerSettingsNewIcon from "@material-ui/icons/PowerSettingsNew";
import FeedbackIcon from "@material-ui/icons/Feedback";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import DirectionsBike from '@material-ui/icons/DirectionsBike';
import LocalHospital from '@material-ui/icons/LocalHospital';
import Assignment from '@material-ui/icons/Assignment';
import Group from '@material-ui/icons/Group';
import Equalizer from '@material-ui/icons/Equalizer';
import MediaQuery from 'react-responsive';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Router from "../router";
import history from "../history";
import AboutDialog from "../components/AboutDialog";
import { logout } from "../actions";

const drawerWidth = 280;

const styles = theme => ({

    root: {
        flexGrow: 1,
        height: "100%",
        zIndex: 1,
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        Backgroundcolor: "rgba(3,180,115)"

    },
    boxFlex: {
        display: "flex",
        alignItems: "center",

    },
    titre: {
        marginTop: "20px",
    },

    '@media screen and (max-width: 768px)': {
        firstTitle: {
            fontSize: 22,
            textAlign: "center",
        },

        boxFlex: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

        },
        boxFlex1: {
            margin: "0 !important",
            marginBottom: "2em !important",
        },
        logo: {
            height: "32px !important",
            marginBottom: "7px "
        },
        boxNav: {
            paddingTop: "30px !important",
        },

        titre: {
            fontSize: "16px !important",
            marginTop: "0 !important",
        },
        boxRight: {
            textAlign: "right",
        }
    }
    ,
    container: {
        display: "flex",
        Backgroundcolor: "rgba(3,180,115)",
    },
    appBar: {
        flexGrow: 1,
        position: "fixed",
        backgroundColor: "rgba(3, 180, 115, 1)",
        backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",

        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    menuButton: {
        marginLeft: 12,
        marginRight: 36,
        color: "#FFFFFF"
    },
    drawerPaper: {
        backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",
        color: "#FFF",
        height: "100vh",
        position: "fixed",
        whiteSpace: "nowrap",
        width: drawerWidth,
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: "hidden",
        transition: theme.transitions.create("width", {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing.unit * 7,
        [theme.breakpoints.up("sm")]: {
            width: theme.spacing.unit * 9,
        },
    },
    toolbar: {

        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 8px",
        ...theme.mixins.toolbar,
    },
    flex: {
        flexGrow: 1,
    },
    badge: {
        top: -7,
        right: -10
    },

    content: {
        height: "100%",
        flexGrow: 1,
        overflow: "auto",
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing.unit * 3,
        marginLeft: theme.spacing.unit * 7,
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing.unit * 9,
        },
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    collapsedContent: {
        marginLeft: drawerWidth,
        transition: theme.transitions.create(["width", "margin"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        })
    },
});

class Home extends React.Component {

    constructor() {
        super();
        this.state = { path: "", open: false, openDialog: false, open: false };
    }

    componentDidMount() {
        history.listen((value) => {
            this.setState({ ...this.state, path: value.pathname });
        });
    }
    toggleDrawer = () => {
        this.setState({ open: !this.state.open });
    };
    handleCategoriesCollapse = () => {
        this.setState(state => ({ open: !state.open }));
        this.props.history.setState
    };
    render() {
        const { classes } = this.props;
        const path = this.props.history.location.pathname;
        return (
            <div className={classes.root} >

                <AppBar
                    position="static"
                    className={classes.appBar}

                >
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="Open drawer"
                            onClick={this.toggleDrawer}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="title" color="inherit" noWrap className={classes.flex}>
                            <span style={{ fontFamily: "Montserrat, sans-serif", }} className={classes.boxFlex} ><img style={{ height: 50 }} className={classes.logo} src="http://pharmaloop.fr/img/pharmaloop-logo-blanc.png" /><span style={{ position: "relative", }} className={classes.titre} >&nbsp; &nbsp; Administration</span></span>
                        </Typography>

                        <div className={classes.boxRight} >
                            {/* <IconButton
                                aria-owns={open ? "menu-appbar" : null}
                                aria-haspopup="true"
                                onClick={() => {
                                    this.props.history.push("/commands")
                                }}
                                color="inherit"
                            >
                                <Badge badgeContent={1} color="secondary" classes={{badge: classes.badge}}>
                                    <NotificationsIcon/>
                                </Badge>
                            </IconButton> */}

                            <IconButton
                                aria-owns={open ? "menu-appbar" : null}
                                aria-haspopup="true"
                                onClick={() => this.props.history.push("/account")}
                                color="inherit"
                            >
                                <AccountCircleIcon />
                            </IconButton>
                        </div>

                    </Toolbar>
                </AppBar>

                <div className={classes.container}>
                    <Drawer
                        variant="permanent"
                        classes={{
                            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
                        }}
                        open={this.state.open}
                    >
                        <div style={{ backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)" }} className={classes.toolbar} />
                        <List component="nav" className={classes.boxNav} >
                            <ListItem button onClick={() => this.props.history.push("/")}>
                                <Tooltip title="Tableau de bord"><ListItemIcon >
                                    <DashboardIcon style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                </Tooltip>
                                <ListItemText   >
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Tableau de bord
                                </span>
                                </ListItemText>
                            </ListItem>
                            <ListItem button onClick={() => this.props.history.push("/admins")}>
                                <Tooltip title="Patients"><ListItemIcon>
                                    <Group style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Patients
                                </span>
                                </ListItemText>
                            </ListItem>
                            <ListItem button onClick={() => this.props.history.push("/pharmacies")}>
                                <Tooltip title="Pharmacies"><ListItemIcon>
                                    <LocalHospital style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Pharmacies
                                </span>
                                </ListItemText>
                            </ListItem>


                            <ListItem button onClick={() => this.props.history.push("/commands")}>
                                <Tooltip title="Commandes"><ListItemIcon>
                                    <Assignment style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                </Tooltip>
                                <ListItemText>
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Commandes
                                </span>
                                </ListItemText>
                            </ListItem>
                            <ListItem button onClick={this.handleCategoriesCollapse}>
                            <img  src="http://pharmacien.toolynk-lab.com/healthcare-and-medical.png" style={{marginLeft:"3px", height: "24px"}} />
                                <ListItemText >
                                    <span style={{ color: "rgb(255, 255, 255)",marginLeft:"18px" }}>
                                        Parapharmacie
                                </span>
                                </ListItemText>
                                {this.state.open ? <ExpandLess /> : <ExpandMore />}
                            </ListItem>
                            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button >
                                        <ListItemText inset onClick={() => this.props.history.push("/categories")}>
                                            <span style={{ color: "rgb(255, 255, 255)" }}>
                                                Catégories
                                </span>
                                        </ListItemText>
                                    </ListItem >
                                    <ListItem button onClick={() => this.props.history.push("/produits")}>
                                        
                                        <ListItemText inset>
                                            <span style={{ color: "rgb(255, 255, 255)" }}>
                                                Produits
                                </span>
                                        </ListItemText>
                                    </ListItem>
                                    {/* <ListItem button >
                                        <ListItemText inset >
                                        <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Sous Catégories
                                </span>
                                </ListItemText>
                                    </ListItem>
                                    <ListItem button >
                                        <ListItemText inset>
                                        <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Sous sous Catégories
                                </span>
                                </ListItemText>
                                    </ListItem> */}
                                </List>
                            </Collapse>
                            <ListItem button onClick={() => this.props.history.push("/feedback")}>
                                <Tooltip title="Statistiques"><ListItemIcon>
                                    <Equalizer style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                </Tooltip>
                                <ListItemText >
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Statistiques
                                </span>
                                </ListItemText>
                            </ListItem>

                        </List>
                        <Divider />
                        <List>
                            <ListItem button onClick={() => logout()}>
                                <ListItemIcon>
                                    <PowerSettingsNewIcon style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        Se déconnecter
                                </span>
                                </ListItemText>
                            </ListItem>

                            <ListItem button onClick={() => this.setState({ ...this.state, openDialog: true })}>
                                <ListItemIcon>
                                    <InfoIcon style={{ color: "rgb(255, 255, 255)" }} />
                                </ListItemIcon>
                                <ListItemText>
                                    <span style={{ color: "rgb(255, 255, 255)" }}>
                                        À propos
                                </span>
                                </ListItemText>
                            </ListItem>
                        </List>
                    </Drawer>

                    <main className={classNames(classes.content, this.state.open && classes.collapsedContent)}>
                        <div className={classes.toolbar} />
                        <Router isMenuOpen={this.state.open} />
                    </main>
                </div>
                <AboutDialog open={this.state.openDialog}
                    onClose={() => this.setState({ ...this.state, openDialog: false })} />
            </div>
        );
    }
}

export default withRouter(withStyles(styles)(Home));