import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadDeliveryMen } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import { Link } from "react-router-dom";
import Icon from "@material-ui/core/Icon";
import Eye from "@material-ui/icons/RemoveRedEye";
import FormatListBulleted from "@material-ui/icons/FormatListBulleted";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";

import Toolbar from "@material-ui/core/Toolbar";

import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import InboxIcon from "@material-ui/icons/Inbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import ListSubheader from "@material-ui/core/ListSubheader";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import MapComponent from "./MapComponent";
import admins from "./Admins";

/******LISTSTYLES************************************* */
const liststyles = theme => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper
  }
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85)
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark
      },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});
// Button Style //
const buttonstyles = theme => ({
  button: {
    margin: theme.spacing.unit
  },
  leftIcon: {
    marginRight: theme.spacing.unit
  },
  iconSmall: {
    fontSize: 20
  },
  input: {
    display: "none"
  }
});

class EnhancedTableToolbar extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="title" id="tableTitle" />
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title="Liste">
              <IconButton
                aria-label="list"
                onClick={() => this.props.history.push(`/admins`)}
              >
                <FormatListBulleted />
              </IconButton>
            </Tooltip>

            <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
              <IconButton
                aria-label="filter"
                onClick={() => this.props.toggleFilter()}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
/***************************************************Page DeliveryMen************************************************* */

class Livreur extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 5
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {
    this.props.loadDeliveryMen();
  }

  componentWillUnmount() {
    window.onscroll = null;
  }




  render() {
    const deliverymen = this.props.deliverymen.data;
    const { classes, theme } = this.props;
    const { order, orderBy, rowsPerPage, page } = this.state;
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen,
    };


    const { match, location, history } = this.props;




    return (

      <div>

        <Typography style={{ fontFamily: "Montserrat, sans-serif" }} color="textSecondary" variant="headline">

          {capitalizeFirstLetter('Fiche Livreur')}
        </Typography>
      </div>
    )
    {
      this.props.deliverymen.data.length > 0 && (
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            refresh={this.props.loadDeliveryMen}
            toggleFilter={this.toggleFilter}
            isFilterShowen={this.state.showFilter}
          />
          <div className={classes.tableWrapper}>
            {this.state.showFilter && (
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Zoom in={true} timeout={200}>
                  <TextField
                    style={{ marginRight: 20 }}
                    placeholder={"Chercher..."}
                    autoFocus
                    value={this.state.filterText}
                    onChange={this.handleFilterChange}
                  />
                </Zoom>
              </div>
            )}

            {this.props.deliverymen.data.map(deliveryman => {
              if (deliveryman.id === window.location.search.substring(7))
                return (
                  <div className={classes.root}>
                    <Typography color="textSecondary" variant="headline">
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center"
                        }}
                      >
                        <Avatar
                          alt="avatar"
                          src={deliveryman.profile.profile_pic_url}
                        />

                        {capitalizeFirstLetter(deliveryman.first_name) +
                          "  " +
                          capitalizeFirstLetter(deliveryman.last_name)}
                      </div>
                      </Typography>
                      

                        
                              <List component="nav">
                                <ListItem >
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Nom de famille</span>} secondary={capitalizeFirstLetter(deliveryman.last_name)} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Prénom</span>} secondary={deliveryman.first_name} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Email</span>} secondary={deliveryman.profile.email} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Téléphone</span>} secondary={deliveryman.tel} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Adresse</span>} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Code postale</span>} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>statut</span>} secondary={deliveryman.is_on_work} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Date d'inscription</span>} />
                                </ListItem>


                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Nombre de commandes</span>} secondary='nb de commandes' />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Dernière connexion</span>} />
                                </ListItem>
                                <ListItem>
                                  <ListItemText primary={<span style={{ fontFamily: "Montserrat, sans-serif" }}>Dernière déconnexion</span>} />
                                </ListItem>


                                <Button variant="contained" color="secondary" primary
                                  style={{
                                    height: "36px", marginTop: 15, width: "300px",
                                    marginBottom: 15, backgroundColor: "rgba(3, 180, 115)", backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)", color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color: "rgb(255, 255, 255)",
                                    border: "none", boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)", borderRadius: "4px", textTransform: "uppercase", fontWeight: "500",
                                    outline: "none", fontSize: "0.875rem",
                                  }}
                                  fullWidth={true}
                                  label="Commandes"
                                  onClick={() => this.props.history.push(`/commands/${deliveryman.id}`)}>
                                  <div>
                                    Commandes
                                                                  </div>
                                </Button>





                              </List>
                              






                            </div>



                          )
                      }
                    )
                  }
        
        <Zoom
                          in={this.state.openFab}
                          timeout={transitionDuration}
                          style={{
                            position: "fixed",
                            right: 16,
                            bottom: 16,
                            transitionDelay: transitionDuration.exit
                          }}
                        >
                          <Button
                            variant="fab"
                            color="secondary"
                            onClick={() => this.props.history.push("/livreur/new")}
                          >
                            <AddIcon />
                          </Button>
                        </Zoom>
      </div>
      </Paper>
                    );
                  }
                }
              }
                
                

                
                
Livreur.propTypes = {
                        classes: PropTypes.object.isRequired
                    };
                  
                    
const mapStateToProps = ({deliverymen}) => ({deliverymen});
                      
const mapDispatchToProps = dispatch => ({
                        loadDeliveryMen: bindActionCreators(loadDeliveryMen, dispatch)
                });
      export default connect(
                      mapStateToProps,
                      mapDispatchToProps
)(withRouter(withStyles(styles, {withTheme: true })(Livreur)));
