import gql from "graphql-tag";
import apolloClient from "../apolloClient";
import {CommandTypes} from "../types/command.types";
const LOAD_COMMANDS_QUERY = gql`
    query {
        getCommands {
            id
            num
            to_pay
            delivery_date
            address
            second_address

            refunded
            pharmacy {
                id
                num
                name
                address
            }
            client {
                id
                num
                first_name
                last_name
                address
                tel


            }
            paraPharmacies{
                edges{
                    paraPharmacy{
                        name
                        img_url
                        bar_code
                    }
                    price
                    amount
    
                }
                total
            }
            refunded
            createdAt
            state
            delivery_mode
            tracking_url

        }
    }
`;


const LOAD_COMMAND_QUERY = gql`
  query getCommand($id: ID!) {
    getCommand(id: $id) {
        id
        num
        to_pay
        delivery_date
        address
        second_address
        refunded
        pharmacy {
            id
            num
            name
            address
        }
        client {
            id
            num
            first_name
            last_name
            address


        }
        
        createdAt
        state
        delivery_mode
        tracking_url

    }
  }
`;



const loadCommands = () => dispatch => {
    dispatch({type: CommandTypes.REQUEST_LOAD_COMMANDS});
    apolloClient.query({query: LOAD_COMMANDS_QUERY})
    .then(response => {
  
        dispatch({type: CommandTypes.REQUEST_LOAD_COMMANDS_SUCCESS, data: response.data.getCommands});
    })
    .catch(error => {
       
        dispatch({type: CommandTypes.REQUEST_LOAD_COMMANDS_ERROR, error});
    });
};


const loadCommand = id => dispatch => {
    dispatch({ type: CommandTypes.REQUEST_LOAD_COMMAND });
    apolloClient
      .query({ query: LOAD_COMMAND_QUERY ,variables:{id:id} })
     
      .then(response => {
        
        dispatch({
          type: CommandTypes.REQUEST_LOAD_COMMAND_SUCCESS,
          data: response.data.getCommand
        });
      })
      .catch(error => {
        
        dispatch({ type: CommandTypes.REQUEST_LOAD_COMMAND_SUCCESS, error });
      });
  };


  const setCategory =data =>dispatch =>{
    dispatch({type:"SET_CATEGORY_FILTER",payload:data});
}
const setSubCategory =data =>dispatch =>{
    dispatch({type:"SET_SUB_CATEGORY_FILTER",payload:data});
}
const setNestedSubCategory =data =>dispatch =>{
    dispatch({type:"SET_NESTED_SUB_CATEGORY_FILTER",payload:data});
}


export{
    loadCommands,loadCommand,setCategory,setSubCategory,setNestedSubCategory
};
