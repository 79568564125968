import axios from "axios";
import {BASE_URL} from "../tools/constants";

const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 5000,
    headers: {
        authorization: localStorage.getItem("token")
    }
});


instance.interceptors.response.use(res => new Promise((resolve, reject) => {
    setTimeout(() => {
        resolve(res);
    }, 0);
}), (error) => {
    if (error.response.status == 401) {
        localStorage.removeItem("token");
        return window.location.href = "/";
    }
    return error;
});

export default instance;