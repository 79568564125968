import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles, CircularProgress } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { capitalizeFirstLetter } from '../tools/utils';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Eye from '@material-ui/icons/RemoveRedEye';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import MediaQuery from 'react-responsive';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import ListSubheader from '@material-ui/core/ListSubheader';
import Toolbar from '@material-ui/core/Toolbar';
import InfoIcon from '@material-ui/icons/Info';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';
import InputLabel from '@material-ui/core/InputLabel';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import Badge from '@material-ui/core/Badge';
import Assignment from '@material-ui/icons/Assignment';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { graphql, compose } from 'react-apollo';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { loadCommands, loadPharmacies } from '../actions';
import moment from 'moment';
import Excel from './Excel';
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden';
import Modal from '@material-ui/core/Modal';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { isAbsolute } from 'upath';
import { setCategory, setSubCategory, setNestedSubCategory } from '../actions';
//**********************table************/
const style = theme => ({
	container: {
		flexGrow: 1
	},
	card: {
		marginTop: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 3
	}
});

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
	{ id: 'name', numeric: false, disablePadding: true, label: 'NOM' },
	{ id: 'bar_code', numeric: false, disablePadding: true, label: 'Code EAN' },
	{ id: 'laboratory', numeric: false, disablePadding: true, label: 'Laboratoire' },
	{ id: 'createdAt', numeric: false, disablePadding: true, label: 'CRÉÉE LE' },
	{ id: 'state', numeric: true, disablePadding: false, label: 'STATUT' },
	{ id: '', numeric: true, disablePadding: false }
];

function getModalStyle() {
	const top = 40;
	const left = 50;

	return {
		top: `${top}%`,
		left: `${left}%`,
		transform: `translate(-${top}%, -${left}%)`
	};
}

const LOAD_PAHRMACY_QUERY = gql`
	query {
		getPharmacies {
			id
			is_pill_maker
			description
			num
			tel
			name
			website
			commands {
				id
			}
			profile {
				id
				email
				profile_pic_url
				is_blocked
				createdAt
			}
			location {
				latitude
				longitude
			}
			country
			address
			city
			zip_code
			work_schedule {
				is_always_on_work
				planing {
					monday {
						open_at
						close_at
					}
					tuesday {
						open_at
						close_at
					}
					wednesday {
						open_at
						close_at
					}
					thursday {
						open_at
						close_at
					}
					friday {
						open_at
						close_at
					}
					saturday {
						open_at
						close_at
					}
					sunday {
						open_at
						close_at
					}
				}
			}
		}
	}
`;
const LOAD_PRODUITS_QUERY = gql`
	query getParaPharmacies {
		getParaPharmacies {
			id
			category_id
			sub_category_id
			nested_sub_category_id
			name
			laboratory
			description
			img_url
			price
			bar_code
			indications
			usage_tips
			composition
			precautions
			state
			createdAt
		}
	}
`;
const LOAD_CATEGORIES_QUERY = gql`
	query getCategories {
		getCategories {
			id
			name
			para_count
			state
			createdAt
			subCategories {
				id
				name
				para_count
				state
				createdAt
				nestedSubCategories {
					id
					name
					para_count
					state
					createdAt
				}
			}
		}
	}
`;
const columns = props => [
	{
		label: 'prim',
		primary: 'primary',
		key: 'id',
		render: id => {
			let produit = {};
			if (props.getProduits && props.getProduits.getParaPharmacies)
				props.getProduits.getParaPharmacies.forEach(cl => {
					if (cl.id == id) {
						return (produit = cl);
					}
				});
			return <span style={{ fontSize: 13 }}>{capitalizeFirstLetter(produit.name)}</span>;
		}
	},
	{
		label: 'NOM COMPLET',
		key: 'name',
		render: name => {
			return capitalizeFirstLetter(name);
		}
	},
	{
		label: 'CODE EAN',
		key: 'bar_code',
		render: bar_code => {
			return bar_code;
		}
	},
	{
		label: 'Laboratoire',
		key: 'laboratory',
		render: laboratory => {
			return laboratory;
		}
	},
	{
		label: 'CRÉER LE',
		key: 'createdAt',
		render: createdAt => {
			return moment(createdAt).format('LLL');
		}
	},
	{
		label: 'STATUT',
		key: 'state',
		render: state => {
			return state == false ? 'Désactivée' : 'Activée';
		}
	},

	{
		label: '',
		key: 'id',
		render: id => {
			let produit = {};
			if (props.getProduits && props.getProduits.getParaPharmacies)
				props.getProduits.getParaPharmacies.forEach(cl => {
					if (cl.id == id) {
						return (produit = cl);
					}
				});
			return (
				<div>
					<div style={{ display: 'flex' }} className="eye">
						<IconButton onClick={() => props.history.push(`/produits/produitDetail?id=${produit.id}`)}>
							<InfoIcon style={{ color: 'rgb(40, 171, 179)' }} />
						</IconButton>
						<IconButton onClick={() => props.history.push(`/produits/produit?id=${produit.id}`)}>
							<EditIcon style={{ color: 'rgb(40, 171, 179)' }} />
						</IconButton>
					</div>
				</div>
			);
		}
	}
];

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy, classes } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								style={{
									textAlign: 'left',
									fontSize: 16,
									flexDirection: 'column',
									fontFamily: 'Montserrat, sans-serif'
								}}
								numeric={row.numeric}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
								className={classes.tableHead}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	boxFlex: {
		display: 'flex'
	},

	'@media screen and (max-width: 991px)': {
		firstTitle: {
			fontSize: '22px !important',
			textAlign: 'center !important',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		boxFlex: {
			flexDirection: 'column'
		}
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto',
		textTransform: 'uppercase'
	}
});
// Button Style //
const buttonstyles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	input: {
		display: 'none'
	}
});
const mapStateToProps = ({ commands }) => ({ commands });

const mapDispatchToProps = dispatch => ({
	setCategory: bindActionCreators(setCategory, dispatch),
	setSubCategory: bindActionCreators(setSubCategory, dispatch),
	setNestedSubCategory: bindActionCreators(setNestedSubCategory, dispatch)
});

@connect(mapStateToProps, mapDispatchToProps)
class EnhancedTableToolbar extends React.Component {
	constructor() {
		super();
		this.state = {
			category: {},
			sub_category: {},
			nested_sub_category: {},
			listSubCategories: [],
			listNestedSubCategories: []
		};
	}
	handleCategorieChange = event => {
		if (event.target.name == 'category') {
		
			this.setState(
				{
					listSubCategories: event.target.value && event.target.value.subCategories,
					listNestedSubCategories: [],
					category: event.target.value
				}
			
			);
	
			this.props.handleCategory(event.target.value);
			this.props.setCategory(event.target.value);
			this.props.setSubCategory(null);
			this.props.setNestedSubCategory(null);
		} else if (event.target.name == 'sub_category') {
			this.setState({
				listNestedSubCategories: event.target.value && event.target.value.nestedSubCategories,
				sub_category: event.target.value
			});
			this.props.handleSubCategory(event.target.value);
			this.props.setSubCategory(event.target.value);
			this.props.setNestedSubCategory(null);
		} else {
			this.setState({ nested_sub_category: event.target.value });
			this.props.handleNestedSubCategory(event.target.value);
			this.props.setNestedSubCategory(event.target.value);
		}
	
		this.props.filterBy(
			event.target.name == 'category' ? 2 : event.target.name == 'sub_category' ? 1 : 0,
			event.target.value && event.target.value
		);
	};
	// a continuer
	initCategories = () => {
		if (this.props.location && this.props.location.state && this.props.location.state.category) {
			let locationState = this.props.location.state;
			this.setState({
				category: locationState.category,
				listSubCategories: (() => {
					if (locationState.category && locationState.subCategories) {
						locationState.subCategories.forEach(sub => {});
					}
				})(),
				sub_category: locationState.subCategory,
				listNestedSubCategories: locationState.category && locationState.category.subCategories
			});
		}
		if (event.target.name == 'category') {
	
			this.setState(
				{
					listSubCategories: event.target.value && event.target.value.subCategories,
					listNestedSubCategories: [],
					category: event.target.value
				}
				
			);
			
		} else if (event.target.name == 'sub_category') {
			this.setState({
				listNestedSubCategories: event.target.value && event.target.value.nestedSubCategories,
				sub_category: event.target.value
			});
		} else {
			this.setState({ nested_sub_category: event.target.value });
		}
	
		this.props.filterBy(
			event.target.name == 'category' ? 2 : event.target.name == 'sub_category' ? 1 : 0,
			event.target.value && event.target.value
		);
	};
	
	componentDidMount() {
	
		if (this.props.location && this.props.location.state && this.props.location.state.category) {
			// this.setState({category:this.props.location.state.category});
			//this.handleCategorieChange({ target: { name: 'category', value: this.props.location.state.category } })
		}
		if (this.props.location && this.props.location.state && this.props.location.state.subCategory) {
			this.setState({ sub_category: this.props.location.state.subCategory });
			this.handleCategorieChange({ target: { name: 'subCategory', value: this.props.location.state.subCategory } });
		}
		if (this.props.location && this.props.location.state && this.props.location.state.nestedSubCategory) {
			//this.setState({ nested_sub_category: this.props.location.state.nestedSubCategory });
			this.handleCategorieChange({
				target: { name: 'nestedSubCategory', value: this.props.location.state.nestedSubCategory }
			});
		}
	}
	render() {
		const { classes, listCategories, listSubCategories, listNestedSubCategories, refresh } = this.props;
	

		return (
			<div>
				<Toolbar className={classNames(classes.boxFlex)}>
					<div className={classes.title}>
						<Typography variant="title" id="tableTitle" className={classNames(classes.firstTitle)}>
							<span style={{ fontFamily: 'Montserrat, sans-serif' }} className={classNames(classes.firstTitle)}>
								{' '}
								Liste des produits{' '}
							</span>
						</Typography>
					</div>

					<div className={classes.spacer} />
					<div className={classes.actions}>
						<div style={{ display: 'flex', flexDirection: 'row' }}>
							{this.props.excelProps ? <Excel {...this.props.excelProps} /> : ''}
							<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
								<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
									<FilterListIcon />
								</IconButton>
							</Tooltip>
							<Tooltip title="Rafraîchir">
								<IconButton
									aria-label="refresh"
									onClick={() => {
										refresh();
									}}
								>
									<RefreshIcon />
								</IconButton>
							</Tooltip>
						</div>
					</div>
				</Toolbar>
				<div
					style={{
						display: 'flex',
						'flex-direction': 'row',
						'justify-content': 'space-between',
						'margin-bottom': 20
					}}
				>
					<FormControl className={classes.formControl} style={{ width: '32%' }}>
						<InputLabel htmlFor="age-simple">Catégorie</InputLabel>
						<Select
							value={this.state.category}
							onChange={this.handleCategorieChange}
							inputProps={{
								name: 'category',
								id: 'categorie-simple'
							}}
						>
							{listCategories &&
								listCategories.map(c => {
									return <MenuItem value={c}>{c.name}</MenuItem>;
								})}
						</Select>
					</FormControl>
					<FormControl className={classes.formControl} style={{ width: '32%' }}>
						<InputLabel htmlFor="age-simple">Sous catégorie</InputLabel>
						<Select
							value={this.state.sub_category}
							onChange={this.handleCategorieChange}
							inputProps={{
								name: 'sub_category',
								id: 'categorie-simple'
							}}
						>
							{this.state.listSubCategories &&
								this.state.listSubCategories.map(c => {
									return <MenuItem value={c}>{c.name}</MenuItem>;
								})}
						</Select>
					</FormControl>
					<FormControl className={classes.formControl} style={{ width: '32%' }}>
						<InputLabel htmlFor="age-simple">Sous sous catégorie</InputLabel>
						<Select
							value={this.state.nested_sub_category}
							onChange={this.handleCategorieChange}
							inputProps={{
								name: 'nested_sub_category',
								id: 'categorie-simple'
							}}
						>
							{this.state.listNestedSubCategories &&
								this.state.listNestedSubCategories.map(c => {
									return <MenuItem value={c}>{c.name}</MenuItem>;
								})}
						</Select>
					</FormControl>
					<Tooltip title="Effacer le filtre">
						<IconButton
							aria-label="refresh"
							onClick={() => {
								this.props.setCategory(null);
								this.props.setSubCategory(null);
								this.props.setNestedSubCategory(null);
								this.setState({ category: {}, sub_category: {}, nested_sub_category: {} });
								this.props.filterBy(3);
							}}
						>
							<ClearIcon />
						</IconButton>
					</Tooltip>
				</div>

				<div>
					{this.props.commands.selectedCategory && (
						<Typography
							variant="subheading"
							color="textSecondary"
							style={{
								fontFamily: 'Montserrat, sans-serif',
								'font-weight': 'bold',
								color: '#00C0A6',
								textTransform: 'capitalize'
							}}
						>
							{`${this.props.commands.selectedCategory.name}  ${this.props.commands.selectedSubCategory
								? '>' + this.props.commands.selectedSubCategory.name
								: ''} ${this.props.commands.selectedNesteSubCategory
								? '>' + this.props.commands.selectedNesteSubCategory.name
								: ''}`}
						</Typography>
					)}
				</div>
			</div>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	boxFlex: {
		display: 'flex'
	},

	'@media screen and (max-width: 991px)': {
		firstTitle: {
			fontSize: '22px !important',
			textAlign: 'center !important',
			paddingTop: '20px',
			paddingBottom: '20px'
		},
		boxFlex: {
			flexDirection: 'column'
		}
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	rou: {
		textAlign: 'left'
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: '#00adac',
		'&$colorChecked': {
			color: '#00adac',
			'& + $colorBar': {
				backgroundColor: '#00adac'
			}
		}
	},

	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	badge: {
		top: -9,
		right: -11,
		color: `white`,
		fontSize: 10,
		fontWeight: 800,
		width: 17,
		height: 17,

		// The border color match the background color.
		border: `2px solid white`,
		backgroundColor: teal[400]
	},
	button: {},
	tableCell: {
		fontSize: '13px !important'
	},
	tableHead: {
		fontSize: '14px !important'
	},
	'@media screen and  (min-width: 991px) and (max-width: 1390px)': {
		tableCell: {
			fontSize: '12px !important'
		},
		tableHead: {
			fontSize: '12px !important'
		}
	},
	paper: {
		position: 'relative',
		backgroundColor: theme.palette.background.paper,
		boxShadow: theme.shadows[5],
		padding: theme.spacing.unit * 4
	},
	gridRoot: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
		'& .grid-tile-class > div': {
			border: '1px solid rgb(3, 180, 115)'
		}
	},
	gridList: {
		width: '100%',
		height: '100%'
	}
});

const UPDATE_CATEGORIE_MUTATION = gql`
	mutation updateParaPharmacySubCategory($id: ID!, $name: String!) {
		updateParaPharmacySubCategory(id: $id, name: $name) {
			id
			name
		}
	}
`;
const ADD_CATEGORIE_MUTATION = gql`
	mutation addParaPharmacySubCategory($name: String!) {
		addParaPharmacySubCategory(name: $name) {
			id
			name
		}
	}
`;
const ENABLED_CATEGORIE_MUTATION = gql`
	mutation enableParaPharmacySubCategory($id: ID!) {
		enableParaPharmacySubCategory(id: $id) {
			id
			name
			state
		}
	}
`;
const DISABLED_CATEGORIE_MUTATION = gql`
	mutation disableParaPharmacySubCategory($id: ID!) {
		disableParaPharmacySubCategory(id: $id) {
			id
			name
			state
		}
	}
`;
const ENABLED_PRODUIT_MUTATION = gql`
	mutation enableParaPharmacy($id: ID!) {
		enableParaPharmacy(id: $id) {
			id
			name
			laboratory
			description
			img_url
			price
			indications
			usage_tips
			composition
			precautions
			state
		}
	}
`;
const DISABLED_PRODUIT_MUTATION = gql`
	mutation disableParaPharmacy($id: ID!) {
		disableParaPharmacy(id: $id) {
			id
			name
			laboratory
			description
			img_url
			price
			indications
			usage_tips
			composition
			precautions
			state
		}
	}
`;
const REMOVE_PRODUIT_MUTATION = gql`
	mutation removeParaPharmacy($id: ID!) {
		removeParaPharmacy(id: $id) {
			id
		}
	}
`;
@connect(mapStateToProps, mapDispatchToProps)
class Produits extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			categorie: '',
			id: '',
			mode: 'create',
			openModal: false,
			active: true,
			selectedCategorie: null,
			selectedSubCategorie: null,
			selectedNestedSubCategorie: null,
			produits:
				this.props.getProduits && this.props.getProduits && this.props.getProduits.getParaPharmacies
					? this.props.getProduits.getParaPharmacies
					: [],
			rowsPerPage: window.localStorage.getItem('admin_produits_rows_per_page')
				? parseInt(window.localStorage.getItem('admin_produits_rows_per_page'))
				: 25
		};

		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
		this.handleProductAction = this.handleProductAction.bind(this.props);
	}
	handleOpenModal = () => {
		this.setState({ openModal: true });
	};
	handleProductAction = (type, produit) => {
		
		this.state.selectedCategorie !== null && this.props.setCategory(this.state.selectedCategorie);
		this.state.selectedSubCategorie !== null
			? this.props.setSubCategory(this.state.selectedSubCategorie)
			: this.props.setSubCategory(null);

		if (this.state.selectedNestedSubCategorie !== null) {
			this.props.setNestedSubCategory(this.state.selectedNestedSubCategorie);
		}

		let action = type == 1 ? 'enabled' : 'disabled';
		this.props
			[action]({
				variables: {
					id: produit.id
				}
			})
			.then(response => {
				this.props.getProduit && this.props.getProduit.refetch();
			});

		if (this.props && this.props.commands.selectedCategory) {
			this.filterBy(2, this.props.commands.selectedCategory);
		}
		if (this.props && this.props.commands.selectedSubCategory) {
			this.filterBy(1, this.props.commands.selectedSubCategory);
		}
		if (this.props && this.props.commands.selectedNesteSubCategory) {
			this.filterBy(0, this.props.commands.selectedNesteSubCategory);
		}
	};
	handleSwitchChange = (event, id, state) => {

		let action = state ? 'enabledCategory' : 'disabledCategory';
		state = event.target.value;
		this.props
			[action]({
				variables: {
					id
				}
			})
			.then(response => {
			
				if (response && response.data && response.data.errors) {
					NotificationManager.error('La catégorie existe  déjà !');
					this.props.getCategories.refetch();
					this.handleClose();
				}
			})
			.catch(error => {
				NotificationManager.error('Une erreur est survenue');
			});
	};
	handleClose = () => {
		this.setState({ openModal: false });
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		let produits = Object.assign(
			[],
			this.props.getProduits && this.props.getProduits.getParaPharmacies && this.props.getProduits.getParaPharmacies
		);

		produits =
			produits && produits.length > 0
				? produits.sort(getSorting(order, orderBy)).filter(item => {
						let it = item.name.toLowerCase();
						let filter = this.state.filterText.toLowerCase();
						return this.state.filterText.trim() == ''
							? true
							: it.indexOf(filter) > -1 || item.bar_code.indexOf(filter) > -1;
					})
				: [];
		this.setState({ produits, order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		window.localStorage.setItem('admin_categories_rows_per_page', event.target.value);
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => {
		let produits = Object.assign(
			[],
			this.props.getProduits && this.props.getProduits.getParaPharmacies && this.props.getProduits.getParaPharmacies
		);

		produits =
			produits && produits.length > 0
				? produits.sort(getSorting(this.stateorder, this.state.orderBy)).filter(item => {
						let it = item.name.toLowerCase();
						let filter = value.toLowerCase();
						return value.trim() == '' ? true : it.indexOf(filter) > -1 || item.bar_code.indexOf(filter) > -1;
					})
				: [];
		this.setState({ produits, filterText: value });
	};

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}
	removeElement = (index, tab, name) => {
		if (index > -1) {
			tab.splice(index, 1);
		}
		this.filterProducts();
	};

	filterBy = (type, cat) => {
		this.state.produits = this.props.getProduits && this.props.getProduits.getParaPharmacies;
		let tp = 'category';
		let produits;
		if (type == 2) {
			tp = 'category';
			produits = this.state.produits.filter(produit => {
				return produit.category_id == cat.id;
			});
		} else if (type == 1) {
			tp = 'subCategory';
			produits = this.state.produits.filter(produit => {
				return produit.sub_category_id == cat.id;
			});
		} else if (type == 0) {
			tp = 'nestedSubCategory';
			produits = this.state.produits.filter(produit => {
				return produit.nested_sub_category_id == cat.id;
			});
		} else {
			produits = this.state.produits;
		}
		this.setState({ produits: produits, [tp]: cat });
	};

	componentDidMount() {
	

		if (this.props && this.props.commands.selectedCategory) {
			this.filterBy(2, this.props.commands.selectedCategory);
		}
		if (this.props && this.props.commands.selectedSubCategory) {
			this.filterBy(1, this.props.commands.selectedSubCategory);
		}
		if (this.props && this.props.commands.selectedNesteSubCategory) {
			this.filterBy(0, this.props.commands.selectedNesteSubCategory);
		}
	}

	async componentWillReceiveProps(nextProps) {
		
		if (nextProps.getProduits !== this.props.getProduits) {
			if (nextProps.getProduits && nextProps.getProduits && nextProps.getProduits.getParaPharmacies) {
				await this.setState({ produits: nextProps.getProduits.getParaPharmacies });
				if (this.props && this.props.commands.selectedCategory) {
					this.filterBy(2, this.props.commands.selectedCategory);
				}
				if (this.props && this.props.commands.selectedSubCategory) {
					this.filterBy(1, this.props.commands.selectedSubCategory);
				}
				if (this.props && this.props.commands.selectedNesteSubCategory) {
					this.filterBy(0, this.props.commands.selectedNesteSubCategory);
				}
			}
		}
	}

	handleCategory = categorie => {
	

		this.setState({ selectedCategorie: categorie });
		//this.props.setCategory(categorie);
	};
	handleSubCategory = categorie => {
		this.setState({ selectedSubCategorie: categorie });
	};
	handleNestedSubCategory = categorie => {
		this.setState({ selectedNestedSubCategorie: categorie });
	};

	render() {
		if (this.props.location && this.props.location.state && this.props.location.state.refetch) {
			this.props.getProduits.refetch();
		}
		const { order, orderBy, rowsPerPage, page } = this.state;
		let categories =
			this.props.getCategories && this.props.getCategories.getCategories ? this.props.getCategories.getCategories : [];
		let produits =
			this.state.produits && this.state.produits.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
	
		const { classes, theme } = this.props;

		const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		//     let excelProps={columns:[{label:"Numéro de la pharmacie",value:"num"},
		//     {label:"Pharmacie",value:"pharmacy"},
		//     {label:"Date d'inscription",value:"date"},
		//     {label:"Statut",value:"state"},
		//     {label:"Ville",value:"city"},
		//     {label:"Nombre de commandes",value:"commandes"
		//   }

		//   ],dataSets:[],sheetName:"Pharmacies"}
		//     const { match, location, history } = this.props;
		//     pharmacies.forEach(pharmacie => {
		//       let pharmacie1={"num":pharmacie.num,
		//       "pharmacy":pharmacie.name,
		//       "date":moment(pharmacie.profile.createdAt).format("LLL"),
		//       "state":pharmacie.profile.is_blocked?"DESACTIVÉ":"ACTIVÉ",
		//       "city":pharmacie.city,
		//       "commandes":pharmacie.commands.length,

		//   }
		//       excelProps.dataSets.push(pharmacie1);
		//   });
		return (
			<div>
				<NotificationContainer />

				<div>
					<Typography color="textSecondary" variant="headline" />
					{this.props.getCategories &&
						this.props.getCategories.loading && (
							<div className={classes.progressContainer}>
								<Fade in timeout={300}>
									<Typography variant="subheading" color="textSecondary">
										Chargement...
									</Typography>
								</Fade>
							</div>
						)}
					<EnhancedTableToolbar
						toggleFilter={this.toggleFilter}
						isFilterShowen={this.state.showFilter}
						listCategories={this.props.getCategories && this.props.getCategories.getCategories}
						filterBy={this.filterBy}
						refresh={this.props.getProduits && this.props.getProduits.refetch}
						location={this.props.location}
						handleCategory={this.handleCategory}
						handleSubCategory={this.handleSubCategory}
						handleNestedSubCategory={this.handleNestedSubCategory}
					/>
					<div className={classes.tableWrapper}>
						{this.state.showFilter && (
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
								<Zoom in={true} timeout={200}>
									<TextField
										style={{ marginRight: 20 }}
										placeholder={'Chercher...'}
										autoFocus
										value={this.state.filterText}
										onChange={this.handleFilterChange}
									/>
								</Zoom>
							</div>
						)}
						<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
							<Table className={classes.table} aria-labelledby="tableTitle">
								<EnhancedTableHead
									order={order}
									orderBy={orderBy}
									onRequestSort={this.handleRequestSort}
									rowCount={produits.length}
									classes={classes}
								/>
								<TableBody />
								{produits.map(produit => {
									return (
										<TableRow role="checkbox" tabIndex={-1} key={produit.id}>
											<TableCell component="th" scope="row" padding="checkbox">
												<div
													style={{
														textTransform: 'uppercase',
														color: '#28acb3',
														fontWeight: 'bold',
														textAlign: 'left',
														fontFamily: 'Montserrat, sans-serif',
														cursor: 'pointer'
													}}
													onClick={() => {
														this.state.selectedCategorie !== null &&
															this.props.setCategory(this.state.selectedCategorie);
														this.state.selectedSubCategorie !== null
															? this.props.setSubCategory(this.state.selectedSubCategorie)
															: this.props.setSubCategory(null);

														if (this.state.selectedNestedSubCategorie !== null) {
															this.props.setNestedSubCategory(this.state.selectedNestedSubCategorie);
														}

														this.props.history.push(`/produits/produit?id=${produit.id}`);
													}}
													className={classes.tableCell}
												>
													{produit.name}
												</div>
											</TableCell>
											<TableCell component="th" scope="row" padding="checkbox">
												<div
													style={{
														textTransform: 'uppercase',
														color: '#28acb3',
														fontWeight: 'bold',
														textAlign: 'left',
														fontFamily: 'Montserrat, sans-serif',
														marginLeft: -20,
														cursor: 'pointer'
													}}
													onClick={() => this.props.history.push(`/produits/produit?id=${produit.id}`)}
													className={classes.tableCell}
												>
													{produit.bar_code}
												</div>
											</TableCell>
											<TableCell>
												<div
													style={{
														position: 'relative',
														verticalAlign: 'middle',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														marginLeft: -20,
														TextAlign: 'left',
														padding: 'unset'
													}}
													className={classes.tableCell}
												>
													{produit.laboratory}
												</div>
											</TableCell>
											<TableCell>
												<div
													style={{
														position: 'relative',
														verticalAlign: 'middle',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														marginLeft: -20,
														TextAlign: 'left'
													}}
													className={classes.tableCell}
												>
													{moment(produit.createdAt).format('LLL')}
												</div>
											</TableCell>

											<TableCell style={{ textAlign: 'left' }}>
												<div
													style={{
														position: 'relative',
														verticalAlign: 'middle',
														display: 'flex',
														flexDirection: 'row',
														alignItems: 'center',
														TextAlign: 'left'
													}}
													className={classes.tableCell}
												>
													{produit.state ? 'Active' : 'Désactivé'}
												</div>
											</TableCell>

											<TableCell style={{ padding: 'unset' }}>
												<div style={{ display: 'flex' }} className={classes.tableCell}>
													<IconButton
														className={classes.icon}
														onClick={() => {
															this.state.selectedCategorie !== null &&
																this.props.setCategory(this.state.selectedCategorie);
															this.state.selectedSubCategorie !== null
																? this.props.setSubCategory(this.state.selectedSubCategorie)
																: this.props.setSubCategory(null);

															if (this.state.selectedNestedSubCategorie !== null) {
																this.props.setNestedSubCategory(this.state.selectedNestedSubCategorie);
															}
															
															this.props.history.push({
																pathname: '/produits/produitDetail',
																search: `id=${produit.id}`,
																state: {
																	category: this.state.category,
																	subCategory: this.state.subCategory,
																	nestedSubCategory: this.state.nestedSubCategory
																}
															});
														}}
													>
														<InfoIcon style={{ color: 'rgb(40, 171, 179)' }} />
													</IconButton>
													<IconButton
														className={classes.icon}
														onClick={() => {
															this.state.selectedCategorie !== null &&
																this.props.setCategory(this.state.selectedCategorie);
															this.state.selectedSubCategorie !== null
																? this.props.setSubCategory(this.state.selectedSubCategorie)
																: this.props.setSubCategory(null);

															if (this.state.selectedNestedSubCategorie !== null) {
																this.props.setNestedSubCategory(this.state.selectedNestedSubCategorie);
															}
															this.props.history.push(`/produits/produit?id=${produit.id}`);
														}}
													>
														<EditIcon style={{ color: 'rgb(40, 171, 179)' }} />
													</IconButton>
													{!produit.state ? (
														<IconButton
															className={classes.button}
															aria-label=""
															onClick={() => this.handleProductAction(1, produit)}
														>
															<BlockIcon style={{ color: 'rgba(232,27,12)' }} />
														</IconButton>
													) : (
														<IconButton
															className={classes.button}
															aria-label=""
															onClick={() => this.handleProductAction(0, produit)}
														>
															<CheckCircleOutlineIcon style={{ color: '#28abb3' }} />
														</IconButton>
													)}
												</div>
											</TableCell>
										</TableRow>
									);
								})}
							</Table>
						</Hidden>
						<Hidden only={['lg', 'xl']} implementation={'js'}>
							{this.state.produits && this.state.produits.length ? (
								<ResponsiveTable
									columns={columns(this.props)}
									data={produits}
									count={this.state.produits && this.state.produits.length}
									rowsPerPage={rowsPerPage}
									page={page}
									excludePrimaryFromDetails={true}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							) : (
								<div style={{ textAlign: 'center' }}>
									<CircularProgress />
								</div>
							)}
						</Hidden>
					</div>
					{/* <div className={classes.gridRoot}>
                      
                            <GridList cellHeight={160} className={classes.gridList} cols={this.props.isMenuOpen ? 2 : 4}>
                              
                                {
                                    produits
                                        .map(produit => {
                                            return (
                                                <GridListTile cols={1} style={{
                                                    borderRadius: "10px 10px 0px 0px",
                                                    height: 220,
                                                    width: this.props.isMenuOpen ? 300 : 220,
                                                    borderRadius: "10px 10px 0px 0px",
                                                    marginBottom: 20
                                                }}
                                                    className={'grid-tile-class'}
                                                >
                                                    <img style={{ height: '100%', width: '100%' }} className={classes.logo} src="http://pharmaloop.fr/img/no-image.png" />
                                                    <GridListTileBar
                                                        title={produit.name}
                                                        subtitle={<div>
                                                            <div style={{ marginBottom: 5 }}>
                                                                par: {produit.laboratory}
                                                            </div>
                                                            <div>
                                                                prix: <span style={{ fontSize: 14, color: 'red' }}>{produit.price} €</span>
                                                            </div>
                                                        </div>}
                                                        actionIcon={
                                                            <div>
                                                                <IconButton className={classes.icon} onClick={() => this.props.history.push(`/produits/produitDetail?id=${produit.id}`)}>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                                <IconButton className={classes.icon} onClick={() => this.props.history.push(`/produits/produit?id=${produit.id}`)}>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </div>

                                                        }
                                                        style={{ background: "rgba(3,180,115)", opacity: 0.9 }}
                                                    />
                                                </GridListTile>

                                            )
                                        })}
                            </GridList>
                        </div> */}
				</div>
				{/* </Hidden> */}

				<TablePagination
					component="div"
					count={this.state.produits && this.state.produits.length}
					rowsPerPage={rowsPerPage}
					page={page}
					backIconButtonProps={{
						'aria-label': 'Previous Page'
					}}
					nextIconButtonProps={{
						'aria-label': 'Next Page'
					}}
					onChangePage={this.handleChangePage}
					onChangeRowsPerPage={this.handleChangeRowsPerPage}
				/>
				<Zoom
					in={this.state.openFab}
					timeout={transitionDuration}
					style={{
						position: 'fixed',
						right: 50,
						bottom: 120,
						transitionDelay: transitionDuration.exit,
						backgroundColor: 'rgba(30,180,115)',
						color: 'rgba(255,255,255)'
					}}
				>
					<Button
						style={{ backgroundColor: 'rgb(47, 194, 157)' }}
						variant="fab"
						onClick={() => this.props.history.push('/produits/produit')}
					>
						<AddIcon />
					</Button>
				</Zoom>
			</div>
		);
	}
}

Produits.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	graphql(LOAD_CATEGORIES_QUERY, {
		name: 'getCategories'
	}),
	graphql(DISABLED_PRODUIT_MUTATION, { name: 'disabled' }),
	graphql(ENABLED_PRODUIT_MUTATION, { name: 'enabled' }),
	graphql(LOAD_PRODUITS_QUERY, {
		name: 'getProduits'
	})
)(withRouter(withStyles(styles, { withTheme: true })(Produits)));
