import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { capitalizeFirstLetter } from "../tools/utils";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Switch from "@material-ui/core/Switch";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Horaire from "./Horaire";
import ContactPhoneIcon from "@material-ui/icons/ContactPhone";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import SaveIcon from "@material-ui/icons/Save";

import gql from "graphql-tag";

import { Mutation } from "react-apollo";
import { graphql, compose } from "react-apollo";
import {
  NotificationContainer,
  NotificationManager
} from "react-notifications";

import FormControl from "@material-ui/core/FormControl";
import axios from "./../tools/axios";
class EnhancedTableHead extends React.Component {
  createSortHandler = property => event => {
    this.props.onRequestSort(event, property);
  };

  render() {
    const { order, orderBy } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map((row, i) => {
            return (
              <TableCell
                key={i}
                numeric={row.numeric}
                padding={
                  i == 0 ? "checkbox" : row.disablePadding ? "none" : "default"
                }
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Trier"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
  root: {
    paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85)
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark
        },
  spacer: {
    flex: "1 1 100%"
  },
  actions: {
    color: theme.palette.text.secondary
  },
  title: {
    flex: "0 0 auto"
  }
});
// Button Style //

class EnhancedTableToolbar extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Toolbar className={classNames(classes.root)}>
        <div className={classes.title}>
          <Typography variant="title" id="tableTitle" />
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
              <IconButton
                aria-label="filter"
                onClick={() => this.props.toggleFilter()}
              >
                <FilterListIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Rafraîchir">
              <IconButton
                aria-label="refresh"
                onClick={() => this.props.refresh()}
              >
                <RefreshIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Toolbar>
    );
  }
}

EnhancedTableToolbar.propTypes = {
  classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3
  },
  table: {
    minWidth: 900
  },
  tableWrapper: {
    overflowX: "auto",
    paddingLeft: 30,
    paddingRight: 30
  },
  colorSwitchBase: {},
  colorBar: {},
  colorChecked: {
    color: green[300],
    "&$colorChecked": {
      color: green[500],
      "& + $colorBar": {
        backgroundColor: green[500]
      }
    }
  },
  avatar: {
    width: 30,
    height: 30,
    margin: 10
  },
  progressContainer: {
    display: "flex",
    marginTop: "30vh",
    flexGrow: 1,
    alignItems: "center",
    justifyContent: "center"
  }
});
/***************************************************Page  NewClient************************************************* */
const ADD_CLIENT_MUTATION = gql`
  mutation addClient(
    $file: Upload!
    $first_name: String!
    $last_name: String!
    $email: String!
    $password: String!
    $tel: String!
    $address: String!
    
  ) {
    addClient(
      file: $file
      input: {
        first_name: $first_name
        last_name: $last_name
        email: $email
        password: $password
        tel: $tel
        address: $address
        
      }
    ) {
      id
      tel
      first_name
      last_name
      address
    }
  }
`;

class NewClient extends React.Component {
  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  };

  constructor() {
    super();
    this.state = {
    avatarChangeLoading: false,
      filterText: "",
      openFab: true,
      showFilter: false,
      order: "asc",
      orderBy: "name",
      page: 0,
      rowsPerPage: 25,
      file: null,
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      tel: "",
      address: "",
      
     
      image: "",
    };
    this.toggleFilter = this.toggleFilter.bind(this.props);
    this.handleFilterChange = this.handleFilterChange.bind(this.props);
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleChangePage = (event, page) => this.setState({ page });

  handleChangeRowsPerPage = event => {
    this.setState({ rowsPerPage: event.target.value });
  };

  toggleFilter = () => {
    if (this.state.showFilter) {
      this.setState({ showFilter: !this.state.showFilter, filterText: "" });
    } else {
      this.setState({ showFilter: !this.state.showFilter });
    }
  };

  handleFilterChange = ({ target: { value } }) =>
    this.setState({ filterText: value });

  componentWillMount() {}

  componentWillUnmount() {
    window.onscroll = null;
  }

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0
    });
  };
  handleChangeAvatar = ({ target: { files } }) => {
    let reader = new FileReader();
    reader.onload = e => {
      this.setState({ image: e.target.result });
    };

    reader.readAsDataURL(files[0]);
    return this.setState({ file: files.length ? files[0] : null });
  };

  handleSubmit = e => {
    NotificationManager.listNotify=[];
    e.preventDefault();


    let {
      id,
      first_name,
      last_name,
      email,
      password,
      tel,
      address,
      file
    } = this.state;
    if(first_name.length==0){
      return NotificationManager.error('Le nom est obligatoire', 'Erreur');
  }
  if(last_name.length==0){
      return NotificationManager.error('Le prénom est obligatoire', 'Erreur');
  }
  if(email.length==0){
    return NotificationManager.error('L\'email est obligatoire', 'Erreur');
}
if(password.length==0){
  return NotificationManager.error('Le mot de passe est obligatoire', 'Erreur');
}
  if(tel.length==0){
      return NotificationManager.error('Le téléphone est obligatoire', 'Erreur');
  }
  if(address.length==0){
      return NotificationManager.error('L\'adresse est obligatoire', 'Erreur');
  }
  let body={"address":address,"phone":tel};
  axios.post('/stuart/validAddress?type=delivering',body).then(resp=>{

    if(resp.data&&resp.data.success){
    this.props
      .mutate({
        variables: {
          id,
          first_name,
          last_name,
          email,
          password,
          tel,
          address,
          file
        }
      })
      .then(response => {
        NotificationManager.success(
          "Ce Patient a été ajouté avec succès",
          "Succès"
        );
        setTimeout(() => {
          this.props.history.push("/admins");
        }, 2000);
      })
      .catch(err =>
        NotificationManager.error("L'ajout  n' a pas été effectué", "Erreur")
      );
    }
    else{
      if(resp.data&&resp.data.error=="ADDRESS_CONTACT_PHONE_REQUIRED"){
        return NotificationManager.error("Le téléphone est invalide");
      }
      return NotificationManager.error("L'adresse est invalide");
    }

    },error=>{
      if(error.data&&error.data.error=="ADDRESS_CONTACT_PHONE_REQUIRED"){
        return NotificationManager.error("Le téléphone est invalide");
      }
      return NotificationManager.error("L'adresse est invalide");
    });
  };

  render() {
    const { classes, theme } = this.props;

    const transitionDuration = {
      enter: theme.transitions.duration.enteringScreen,
      exit: theme.transitions.duration.leavingScreen
    };


    return (
      <div>
        <div className={classes.tableWrapper}>
          <List component="nav">
          <form>
            <Typography color="textSecondary" variant="headline">
              <h3> AJOUTER UN PATIENT </h3>
            </Typography>

            <div style={{ display: "block", marginTop: "50px" }}> </div>
            <div>
              <InputLabel
                required
                style={{ position: "relative", lineHeight: "1px" }}
              >
                Avatar
              </InputLabel>
              <div
                style={{
                  background: "rgb(239, 239, 239)",
                  cursor: "pointer",
                  padding: "1rem",
                  textAlign: "center",
                  color: "rgb(153, 153, 153)"
                }}
              >
                <p>
                  Déposez une photo à télécharger ou cliquez pour la
                  sélectionner.
                </p>
                <input
                  type="file"
                  accept="image/*"
                  autoComplete="off"
                  onChange={this.handleChangeAvatar}
                  className="form-control"
                />
                <div>
                  <img
                    src={this.state.image}
                    style={{
                      maxWidth: "250px",
                      maxHeight: "250px",
                      marginTop: "10px"
                    }}
                  />
                </div>
              </div>
           

              <InputLabel
                required
                style={{ position: "relative", lineHeight: "1px" }}
              >
                Nom de famille
              </InputLabel>

              <TextField
                required
                label=""
                value={this.state.first_name}
                InputLabelProps={{
                  shrink: true
                }}
                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                onChange={e => this.setState({ first_name: e.target.value })}
                fullWidth={true}
              />
            </div>
            <div style={{ display: "block", marginTop: "50px" }}> </div>
            <div>
              <InputLabel
                required
                style={{ position: "relative", lineHeight: "1px" }}
              >
                Prénom
              </InputLabel>

              <TextField
                required
                label=""
                value={this.state.last_name}
                InputLabelProps={{
                  shrink: true
                }}
                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                onChange={e => this.setState({ last_name: e.target.value })}
                fullWidth={true}
              />
            </div>
            <div style={{ display: "block", marginTop: "50px" }}> </div>
            <InputLabel
              required
              style={{ position: "relative", lineHeight: "1px" }}
            >
              Email
            </InputLabel>

            <TextField
              required
              type="email"
              value={this.state.email}
              label=""
              InputLabelProps={{
                shrink: true
              }}
              style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
              onChange={e => this.setState({ email: e.target.value })}
              fullWidth={true}
            />
            <div style={{ display: "block", marginTop: "50px" }}> </div>
            <InputLabel
              required
              style={{ position: "relative", lineHeight: "1px" }}
            >
              Mot de passe
            </InputLabel>

            <TextField
              required
              type="password"
              value={this.state.password}
              label=""
              InputLabelProps={{
                shrink: true
              }}
              style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
              onChange={e => this.setState({ password: e.target.value })}
              fullWidth={true}
            />
            <div style={{ display: "block", marginTop: "50px" }}> </div>
            <div>
              <InputLabel
                required
                style={{ position: "relative", lineHeight: "1px" }}
              >
                Téléphone
              </InputLabel>

              <TextField
                required
                label=""
                value={this.state.tel}
                InputLabelProps={{
                  shrink: true
                }}
                style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
                onChange={e => this.setState({ tel: e.target.value })}
                fullWidth={true}
              />
            </div>
            <div style={{ display: "block", marginTop: "50px" }}> </div>

            <InputLabel
              required
              style={{ position: "relative", lineHeight: "1px" }}
            >
              Adresse
            </InputLabel>

            <TextField
              required
              value={this.state.address}
              label=""
              InputLabelProps={{
                shrink: true
              }}
              style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
              onChange={e => this.setState({ address: e.target.value })}
              fullWidth={true}
            />
            <div style={{ display: "block", marginTop: "50px" }}> </div>
           

            <div style={{ display: "block", marginTop: "30px" }}> </div>
            <NotificationContainer />

            <div />
            <div>
              <Button

                style={{
                  height: "36px",
                  marginTop: 15,
                  width: "300px",
                  marginBottom: 15,
                  backgroundColor: "rgba(3, 180, 115)",
                  backgroundImage:
                    "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",
                  color: "rgb(255, 255, 255)",
                  fontFamily: "Montserrat, sans-serif",
                  color: "rgb(255, 255, 255)",
                  marginRight: "50px",
                  border: "none",
                  boxShadow:
                    "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  outline: "none",
                  fontSize: "0.875rem"
                }}
                onClick={this.handleSubmit}
              >
                Enregistrer
              </Button>
              <Button
                style={{
                  height: "36px",
                  marginTop: 15,
                  width: "300px",
                  marginBottom: 15,
                  backgroundColor: "rgba(3, 180, 115)",
                  backgroundImage:
                    "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",
                  color: "rgb(255, 255, 255)",
                  fontFamily: "Montserrat, sans-serif",
                  color: "rgb(255, 255, 255)",
                  border: "none",
                  boxShadow:
                    "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)",
                  borderRadius: "4px",
                  textTransform: "uppercase",
                  fontWeight: "500",
                  outline: "none",
                  fontSize: "0.875rem"
                }}
                onClick={() => this.props.history.push(`/admins`)}
              >
                Annuler
              </Button>
            </div>
            </form>
          </List>
        </div>
      </div>
    );
  }
}


NewClient.propTypes = {
  classes: PropTypes.object.isRequired
};

export default graphql(ADD_CLIENT_MUTATION)(
  withStyles(styles, { withTheme: true })(NewClient)
);
