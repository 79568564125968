import gql from "graphql-tag";
import apolloClient from "../apolloClient";
import { DeliverymanTypes } from "../types/deliveryman.types";
const LOAD_DELIVERYMEN_QUERY = gql`
  query {
    getDeliveryMen {
      id

      first_name
      last_name
      tel
      is_on_work
      profile {
        email
        profile_pic_url
      }
    }
  }
`;
const LOAD_DELIVERYMAN_QUERY = gql`
  query getDeliveryMan($id: ID!) {
    getDeliveryMan(id: $id) {
      id
      first_name
      last_name
      tel
      is_on_work
      profile {
        email
        profile_pic_url
      }
    }
  }
`;

const loadDeliveryMen = () => dispatch => {
  dispatch({ type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN });
  apolloClient
    .query({ query: LOAD_DELIVERYMEN_QUERY })
    .then(response => {
 
      dispatch({
        type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN_SUCCESS,
        data: response.data.getDeliveryMen
      });
    })
    .catch(error => {
    
      dispatch({
        type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMEN_ERROR,
        error
      });
    });
};
const loadDeliveryMan = id => dispatch => {
  dispatch({ type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN });
  apolloClient
    .query({ query: LOAD_DELIVERYMAN_QUERY })
    .then(response => {
     
      dispatch({
        type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN_SUCCESS,
        data: response.data.getDeliveryMan(id)
      });
    })
    .catch(error => {

      dispatch({
        type: DeliverymanTypes.REQUEST_LOAD_DELIVERYMAN_ERROR,
        error
      });
    });
};

export { loadDeliveryMen, loadDeliveryMan };
