import {AuthTypes} from "../../types/auth.types";

const INITIAL_STATE = {
    loading: false,
    requested: false,
    token: null,
    error: null

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case AuthTypes.REQUEST_LOGIN:
            return {...state, loading: true, error: null};
        case AuthTypes.REQUEST_LOGIN_SUCCESS:
            return {...state, requested: true, token: action.data};
        case AuthTypes.REQUEST_LOGIN_ERROR:
            return {...state, loading: false, requested: true, error: action.error};
        case AuthTypes.REQUEST_LOGOUT:
            return {...state, loading: true, requested: false};
        case AuthTypes.REQUEST_LOGOUT_COMPLETE:
            return {...state, loading: false, requested: true, token: null};
        default:
            return state;
    }
};