import {combineReducers} from "redux";
import admin from "./admin";
import auth from "./auth";
import pharmacy from "./pharmacy";
import client from "./client";
import command from "./command";
import deliveryman from "./deliveryman";
export default combineReducers({
    ...auth,
    ...admin,
    ...pharmacy,
    ...client,
    ...command,
    ...deliveryman,
});