import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadPharmacy } from '../actions';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Horaire from './Horaire';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SaveIcon from '@material-ui/icons/Save';
import gql from 'graphql-tag';
import { graphql, compose } from 'react-apollo';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import CardHeader from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import axios from './../tools/axios';
import MUIPlacesAutocomplete from 'mui-places-autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';
/******LISTSTYLES************************************* */
const liststyles = theme => ({
	root: {
		width: '100%',
		maxWidth: 360,
		backgroundColor: theme.palette.background.paper
	}
});

function desc(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //
const buttonstyles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	input: {
		display: 'none'
	}
});

class EnhancedTableToolbar extends React.Component {
	render() {
		const { classes } = this.props;

		return (
			<Toolbar className={classNames(classes.root)}>
				<div className={classes.title}>
					<Typography variant="title" id="tableTitle" />
				</div>
				<div className={classes.spacer} />
				<div className={classes.actions}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
							<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Rafraîchir">
							<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</Toolbar>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	boxFlex: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around'
	},

	'@media screen and (max-width: 768px)': {
		boxFlex: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		}
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	}
});
/***************************************************Page PHARMACY************************************************* */
const UPDATE_PAHRMACIES_MUTATION = gql`
	mutation updatePharmacy($id: ID, $input: UpdatePharmacyInput!) {
		updatePharmacy(id: $id, input: $input) {
			id
			is_pill_maker
			name
			description
			tel
			website
			address
			country
			city
			zip_code
		}
	}
`;

const LOAD_PAHRMACY_QUERY = gql`
	query getPharmacy($id: ID!) {
		getPharmacy(id: $id) {
			id
			is_pill_maker
			tel
			name
			website
			description
			commands {
				id
			}
			profile {
				id
				email
				profile_pic_url
			}
			country
			address
			city
			zip_code
			location {
				latitude
				longitude
			}
			work_schedule {
				is_always_on_work
				planing {
					monday {
						open_at
						close_at
					}
					tuesday {
						open_at
						close_at
					}
					wednesday {
						open_at
						close_at
					}
					thursday {
						open_at
						close_at
					}
					friday {
						open_at
						close_at
					}
					saturday {
						open_at
						close_at
					}
					sunday {
						open_at
						close_at
					}
				}
			}
		}
	}
`;

class UpdatePharmacy extends React.Component {
	state = {
		checkedA: true,
		checkedB: true
	};

	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: 5,
			test: true,
			pharmacy: props.data.getPharmacy,
			uri: props.data.getPharmacy ? props.data.getPharmacy.profile.profile_pic_url : null,
			file: null
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
		this.onSuggestionSelected = this.onSuggestionSelected.bind(this);
	}

	handleChange = name => event => {
		this.setState({ [name]: event.target.checked });
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};
	handleChangeAvatar = ({ target: { files } }) => {
		let reader = new FileReader();
		reader.onload = e => {
			this.setState({ image: e.target.result });
		};

		reader.readAsDataURL(files[0]);
		return this.setState({ file: files.length ? files[0] : null });
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillReceiveProps(nextProps) {
		if (nextProps.data !== this.props.data) {
			if (nextProps.data.getPharmacy) {
				this.setState({
					pharmacy: nextProps.data.getPharmacy,
					uri: nextProps.data.getPharmacy.profile.profile_pic_url
				});
			}
		}
	}

	onSuggestionSelected(suggestion) {

		let pharmacy = Object.assign({}, this.state.pharmacy);
		pharmacy.address = suggestion.description;
		this.setState({ pharmacy });
	}
	componentWillUnmount() {
		window.onscroll = null;
	}

	handleNext = () => {
		this.setState(state => ({
			activeStep: state.activeStep + 1
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0
		});
	};

	//MUTATION FUNCTION //
	handleSubmit = pharmacy => {
		NotificationManager.listNotify = [];
		let findError = false;
		let errorMessage;

		if (this.state.uri == null || this.state.uri == undefined || this.state.uri.length == 0) {
			return NotificationManager.error('Veuillez renseigner le logo');
		}
		if (
			this.state.pharmacy.name == null ||
			this.state.pharmacy.name == undefined ||
			this.state.pharmacy.name.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner le nom');
		}
		if (
			this.state.pharmacy.description == null ||
			this.state.pharmacy.description == undefined ||
			this.state.pharmacy.description.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner la description');
		}
		if (
			this.state.pharmacy.tel == null ||
			this.state.pharmacy.tel == undefined ||
			this.state.pharmacy.tel.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner le téléphone');
		}

		if (!this.state.pharmacy.tel.match(/^((\+)33|0)[1-9](\d{2}){4}$/g)) {
			return NotificationManager.error('Le téléphone est invalide');
		}
		if (
			this.state.pharmacy.address == null ||
			this.state.pharmacy.address == undefined ||
			this.state.pharmacy.address.length == 0
		) {
			return NotificationManager.error("Veuillez renseigner l'adresse");
		}
		if (
			this.state.pharmacy.country == null ||
			this.state.pharmacy.country == undefined ||
			this.state.pharmacy.country.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner le pays');
		}
		if (
			this.state.pharmacy.city == null ||
			this.state.pharmacy.city == undefined ||
			this.state.pharmacy.city.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner le la ville');
		}
		if (
			this.state.pharmacy.zip_code == null ||
			this.state.pharmacy.zip_code == undefined ||
			this.state.pharmacy.zip_code.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner le code postal');
		}
		if (
			this.state.pharmacy.location == null ||
			this.state.pharmacy.location == undefined ||
			this.state.pharmacy.location.longitude.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner la longitude');
		}
		if (
			this.state.pharmacy.location == null ||
			this.state.pharmacy.location == undefined ||
			this.state.pharmacy.location.latitude.length == 0
		) {
			return NotificationManager.error('Veuillez renseigner la latitude');
		}
		if (
			(Number(parseFloat(this.state.pharmacy.location.longitude)) ===
				parseFloat(this.state.pharmacy.location.longitude) &&
				parseFloat(this.state.pharmacy.location.longitude) % 1 !== 0) == false
		) {
			return NotificationManager.error('Valeur invalide pour la longitude');
		}
		if (
			(Number(parseFloat(this.state.pharmacy.location.latitude)) ===
				parseFloat(this.state.pharmacy.location.latitude) &&
				parseFloat(this.state.pharmacy.location.latitude) % 1 !== 0) == false
		) {
			return NotificationManager.error('Valeur invalide pour la latitude');
		}
		let daysName = [
			{ id: 'monday', name: 'Lundi' },
			{ id: 'tuesday', name: 'Mardi' },
			{ id: 'wednesday', name: 'Mercredi' },
			{ id: 'thursday', name: 'Jeudi' },
			{ id: 'friday', name: 'Vendredi' },
			{ id: 'saturday', name: 'Samedi' },
			{ id: 'sunday', name: 'Dimanche' }
		];
		daysName.forEach(el => {
			if (
			  this.state.pharmacy.work_schedule.planing[el.id][0].open_at.length == 0 &&
			  this.state.pharmacy.work_schedule.planing[el.id][0].close_at.length > 0 ||
			  this.state.pharmacy.work_schedule.planing[el.id][1].open_at.length == 0 &&
			  this.state.pharmacy.work_schedule.planing[el.id][1].close_at.length > 0
			) {
			  findError = true;
			  errorMessage = "Veuillez renseillez l'heure d'ouverture du " + el.name;
			} else if (
			  this.state.pharmacy.work_schedule.planing[el.id][0].open_at.length > 0 &&
			  this.state.pharmacy.work_schedule.planing[el.id][0].close_at.length == 0 ||
			  this.state.pharmacy.work_schedule.planing[el.id][1].open_at.length > 0 &&
			  this.state.pharmacy.work_schedule.planing[el.id][1].close_at.length == 0
			) {
			  findError = true;
			  errorMessage = "Veuillez renseillez l'heure de fermeture du " + el.name;
			}
		  });
		if (findError) {
			return NotificationManager.error(errorMessage);
		}
		if (this.state.loading) {
			return;
		}
		this.setState({ loading: true });

		let {
			id,
			is_pill_maker,
			name,
			tel,
			website,
			description,
			location: { latitude, longitude },
			address,
			country,
			city,
			zip_code,
			work_schedule: { is_always_on_work, planing: { monday, tuesday, wednesday, thursday, friday, saturday, sunday } }
		} = this.state.pharmacy;
		let { file, uri } = this.state;
		let data = {
			id,
			input: {
				is_pill_maker,
				name,
				tel,
				website,
				description,
				location: {
					latitude,
					longitude
				},
				address,
				country,
				city,
				zip_code,
				work_schedule: {
					is_always_on_work,
					planing: {
						monday: [{
						  open_at: monday[0].open_at,
						  close_at: monday[0].close_at
						},
						{
						  open_at: monday[1].open_at,
						  close_at: monday[1].close_at
						}],
						tuesday: [{
						  open_at: tuesday[0].open_at,
						  close_at: tuesday[0].close_at
						},
						{
						  open_at: tuesday[1].open_at,
						  close_at: tuesday[1].close_at
						}],
						wednesday: [{
						  open_at: wednesday[0].open_at,
						  close_at: wednesday[0].close_at
						},
						{
						  open_at: wednesday[1].open_at,
						  close_at: wednesday[1].close_at
						}],
						thursday: [{
						  open_at: thursday[0].open_at,
						  close_at: thursday[0].close_at
						},
						{
						  open_at: thursday[1].open_at,
						  close_at: thursday[1].close_at
						}],
						friday: [{
						  open_at: friday[0].open_at,
						  close_at: friday[0].close_at
						},
						{
						  open_at: friday[1].open_at,
						  close_at: friday[1].close_at
						}],
						saturday: [{
						  open_at: saturday[0].open_at,
						  close_at: saturday[0].close_at
						},
						{
						  open_at: saturday[1].open_at,
						  close_at: saturday[1].close_at
						}],
						sunday: [{
						  open_at: sunday[0].open_at,
						  close_at: sunday[0].close_at
						},
						{
						  open_at: sunday[1].open_at,
						  close_at: sunday[1].close_at
						}]
					  }
				}
			}
		};
		if (file) {
			data = { ...data, input: { ...data.input, file } };
		} else if (uri) {
			data = { ...data, input: { ...data.input, profile_pic_url: uri } };
		}
	
		let body = { address: address, phone: tel };
		axios.post('/stuart/validAddress?type=delivering', body).then(
			resp => {
				if (resp.data && resp.data.success) {
					this.props
						.mutate({
							variables: data //{ id, is_pill_maker, name, tel, website, address, country, city, zip_code }
						})
						.then(response => {
							this.setState({ loading: true });
							NotificationManager.success('Cette pharmacie a été mise à jour avec succès', 'Succès');
							setTimeout(() => {
								this.props.history.push('/pharmacies');
							}, 2000);
						})
						.catch(err => {
					
							if (/email_1 dup key/.test(err)) {
								NotificationManager.error("Email déjà existant, merci d'en choisir un autre", 'Erreur');
							} else {
								NotificationManager.error("La modification  n' a pas été effectué", 'Erreur');
							}
						});
				} else {
					if (resp.data && resp.data.error == 'ADDRESS_CONTACT_PHONE_REQUIRED') {
						return NotificationManager.error('Le téléphone est invalide');
					}
					return NotificationManager.error("L'adresse est invalide");
				}
			},
			error => {
				if (error.data && error.data.error == 'ADDRESS_CONTACT_PHONE_REQUIRED') {
					return NotificationManager.error('Le téléphone est invalide');
				}
				return NotificationManager.error("L'adresse est invalide");
			}
		);
	};
	handleAvatarChange = ({ target: { files } }) => {
		if (files.length) {
			this.setState({ file: files[0] });
			const reader = new FileReader();
			reader.readAsDataURL(files[0]);
			reader.onloadend = () => this.setState({ uri: reader.result });
		}
	};
	render() {
		const { classes, theme } = this.props;
		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		return (
			<div>
				{!this.state.loading ? (
					<div>
						<div style={{ display: 'block', marginTop: '50px' }}> </div>
						<div>
							<Card style={{ marginBottom: 20 }}>
								<CardContent>
									<Grid item xs={12} style={{ marginBottom: 40 }}>
										<input
											accept="image/*"
											style={{ display: 'none' }}
											id="avatar"
											type="file"
											onChange={this.handleAvatarChange}
										/>
										<label htmlFor="avatar">
											{this.state.uri ? (
												<Avatar src={this.state.uri} style={{ width: 100, height: 100, marginLeft: 20 }} />
											) : (
												<Avatar style={{ width: 100, height: 100, marginLeft: 20 }}>
													<AddAPhotoIcon style={{ fontSize: 50 }} />
												</Avatar>
											)}
										</label>
										{this.state.uri && (
											<Typography
												style={{ marginLeft: 40, marginTop: 10, cursor: 'pointer' }}
												variant="caption"
												onClick={() => this.setState({ file: null, uri: null })}
											>
												Supprimer
											</Typography>
										)}
									</Grid>
								</CardContent>
							</Card>
						</div>
						<Card style={{ marginBottom: 20 }}>
							<CardContent>
								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Nom
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.name ? this.state.pharmacy.name : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.name = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>

								<div style={{ display: 'block', marginTop: '50px' }}> </div>

								<div>
									<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
										Description
									</InputLabel>

									<TextField
										style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
										id="full-width"
										label=""
										InputLabelProps={{
											shrink: true
										}}
										value={
											this.state.pharmacy && this.state.pharmacy.description ? this.state.pharmacy.description : ''
										}
										onChange={e => {
											let pharmacy = Object.assign({}, this.state.pharmacy);
											pharmacy.description = e.target.value;
											this.setState({ pharmacy });
										}}
										fullWidth={true}
									/>
									<div style={{ display: 'block', marginTop: '50px' }}> </div>
								</div>

								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Téléphone
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.tel ? this.state.pharmacy.tel : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.tel = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>
								<InputLabel sstyle={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Site Web
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.website ? this.state.pharmacy.website : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.website = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>
							</CardContent>
						</Card>
						<Card style={{ marginBottom: 20 }}>
							<CardContent>
								<div style={{ display: 'block', marginTop: '50px', display: 'flex' }}>
									<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
										Adresse Actuelle
									</InputLabel>
								</div>
								<div style={{ display: 'block', marginTop: '25px', display: 'flex' }}>
									<InputLabel
										style={{
											position: 'relative',
											lineHeight: '1px',
											fontFamily: 'Montserrat, sans-serif',
											color: '#000'
										}}
									>
										{this.state.pharmacy ? this.state.pharmacy.address : ''}
									</InputLabel>
								</div>
								<div style={{ display: 'block', marginTop: '50px', display: 'flex' }}>
									<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
										Nouvelle adresse
									</InputLabel>
								</div>
								<div className="autocom" style={{ display: 'block', marginTop: '25px' }}>
									<MUIPlacesAutocomplete
										createAutocompleteRequest={inputValue => {
											return {
												input: inputValue,
												types: ['address'],
												componentRestrictions: { country: 'fr' }
											};
										}}
										onSuggestionSelected={this.onSuggestionSelected}
										renderTarget={() => (
											<div
												style={{
													display: 'flex',
													flexDirection: 'column',
													alignItems: 'left',
													marginTop: 16
												}}
											/>
										)}
										textFieldProps={{ fullWidth: true }}

										//textFieldProps={{ value: this.state.pharmacy ? this.state.pharmacy.address : '', fullWidth: true }}
									/>
								</div>
								{/* <TextField

value={(this.state.pharmacy && this.state.pharmacy.address) ? this.state.pharmacy.address : ""}
id="full-width"
label=""
InputLabelProps={{
    shrink: true,
}}
style={{ Height: "100%", marginTop: "1px", padding: "0px" }}
fullWidth={true}
/> */}
								<div style={{ display: 'block', marginTop: '50px' }}> </div>
								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Pays
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.country ? this.state.pharmacy.country : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.country = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>
								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Ville
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.city ? this.state.pharmacy.city : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.city = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>

								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Code postale
								</InputLabel>

								<TextField
									value={this.state.pharmacy && this.state.pharmacy.zip_code ? this.state.pharmacy.zip_code : ''}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										let pharmacy = Object.assign({}, this.state.pharmacy);
										pharmacy.zip_code = e.target.value;
										this.setState({ pharmacy });
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>

								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Latitude
								</InputLabel>

								<TextField
									value={
										this.state.pharmacy && this.state.pharmacy.location && this.state.pharmacy.location.latitude
											? this.state.pharmacy.location.latitude
											: ''
									}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										this.setState({
											...this.state,
											pharmacy: {
												...this.state.pharmacy,
												location: {
													...this.state.pharmacy.location,
													latitude: e.target.value
												}
											}
										});
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '30px' }}> </div>

								<InputLabel style={{ position: 'relative', lineHeight: '1px', fontFamily: 'Montserrat, sans-serif' }}>
									Longitude
								</InputLabel>

								<TextField
									value={
										this.state.pharmacy && this.state.pharmacy.location && this.state.pharmacy.location.longitude
											? this.state.pharmacy.location.longitude
											: ''
									}
									id="full-width"
									label=""
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => {
										this.setState({
											...this.state,
											pharmacy: {
												...this.state.pharmacy,
												location: {
													...this.state.pharmacy.location,
													longitude: e.target.value
												}
											}
										});
									}}
									fullWidth={true}
								/>
								<div style={{ display: 'block', marginTop: '50px' }}> </div>
							</CardContent>
						</Card>
						<Card style={{ marginBottom: 20 }}>
						<CardContent>
                <div style={{ display: "block", marginTop: "50px" }}> </div>
                <div>
                  <table>
                    <tr><td></td><td  style={{paddingBottom:25,fontSize:18,textAlign:"center",textTransform:"uppercase"}}>Matin</td><td></td><td style={{paddingBottom:25,fontSize:18,textAlign:"center",textTransform:"uppercase"}}>Après midi</td></tr>
                    <tr>
                      <td style={{ padding: 20 }}>Lundi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.monday
                              ? this.state.pharmacy.work_schedule.planing.monday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                   
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.monday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                    monday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.monday
                              ? this.state.pharmacy.work_schedule.planing.monday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                           
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.monday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                    monday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.monday
                              ? this.state.pharmacy.work_schedule.planing.monday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                  
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.monday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                    monday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.monday
                              ? this.state.pharmacy.work_schedule.planing.monday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.monday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                    monday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Mardi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.tuesday
                              ? this.state.pharmacy.work_schedule.planing.tuesday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
            
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.tuesday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      tuesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.tuesday
                              ? this.state.pharmacy.work_schedule.planing.tuesday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                    
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.tuesday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      tuesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.tuesday
                              ? this.state.pharmacy.work_schedule.planing.tuesday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                  
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.tuesday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      tuesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.tuesday
                              ? this.state.pharmacy.work_schedule.planing.tuesday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.tuesday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      tuesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.tuesday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Mercredi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.wednesday
                              ? this.state.pharmacy.work_schedule.planing.wednesday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                         
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.wednesday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      wednesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.wednesday
                              ? this.state.pharmacy.work_schedule.planing.wednesday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                     
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.wednesday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      wednesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.wednesday
                              ? this.state.pharmacy.work_schedule.planing.wednesday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                           
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.wednesday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      wednesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.wednesday
                              ? this.state.pharmacy.work_schedule.planing.wednesday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.wednesday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      wednesday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.wednesday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Jeudi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.thursday
                              ? this.state.pharmacy.work_schedule.planing.thursday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                           
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.thursday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      thursday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.thursday
                              ? this.state.pharmacy.work_schedule.planing.thursday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                 
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.thursday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      thursday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.thursday
                              ? this.state.pharmacy.work_schedule.planing.thursday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.thursday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      thursday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.thursday
                              ? this.state.pharmacy.work_schedule.planing.thursday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.thursday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      thursday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.thursday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Vendredi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.friday
                              ? this.state.pharmacy.work_schedule.planing.friday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
               
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.friday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      friday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.friday
                              ? this.state.pharmacy.work_schedule.planing.friday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                       
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.friday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      friday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.friday
                              ? this.state.pharmacy.work_schedule.planing.friday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                          
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.friday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      friday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.friday
                              ? this.state.pharmacy.work_schedule.planing.friday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                           
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.friday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      friday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.friday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Samedi</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.saturday
                              ? this.state.pharmacy.work_schedule.planing.saturday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                      
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.saturday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      saturday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.saturday
                              ? this.state.pharmacy.work_schedule.planing.saturday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                            
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.saturday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      saturday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.saturday
                              ? this.state.pharmacy.work_schedule.planing.saturday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                          
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.saturday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      saturday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.saturday
                              ? this.state.pharmacy.work_schedule.planing.saturday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                       
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.saturday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      saturday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.saturday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                    <tr>
                      <td style={{ padding: 20 }}>Dimanche</td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.monday
                              ? this.state.pharmacy.work_schedule.planing.monday[0]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                       
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.monday[0],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                    monday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.monday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.sunday
                              ? this.state.pharmacy.work_schedule.planing.sunday[0]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                          
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.sunday[0],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      sunday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(0,0),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(1)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>

                      <td style={{paddingLeft:40}}>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ouvert à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.sunday
                              ? this.state.pharmacy.work_schedule.planing.sunday[1]
                                  .open_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                           
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.sunday[1],{open_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      sunday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>
                      <td>
                        <InputLabel
                          style={{
                            position: "relative",
                            lineHeight: "1px",
                            fontFamily: "Montserrat, sans-serif"
                          }}
                        >
                          Ferme à
                        </InputLabel>

                        <TextField
                          value={
                            this.state.pharmacy &&
                            this.state.pharmacy.work_schedule &&
                            this.state.pharmacy.work_schedule.planing &&
                            this.state.pharmacy.work_schedule.planing.sunday
                              ? this.state.pharmacy.work_schedule.planing.sunday[1]
                                  .close_at
                              : ""
                          }
                          id="full-width"
                          label=""
                          InputLabelProps={{
                            shrink: true
                          }}
                          style={{
                            Height: "100%",
                            marginTop: "1px",
                            padding: "0px"
                          }}
                          onChange={({ target: { value } }) => {
                          
                            let  updatedObj = Object.assign({}, this.state.pharmacy.work_schedule
                              .planing.sunday[1],{close_at: value});
                            this.setState({
                              ...this.state,
                              pharmacy: {
                                ...this.state.pharmacy,
                                work_schedule: {
                                  ...this.state.pharmacy.work_schedule,
                                  planing: {
                                    ...this.state.pharmacy.work_schedule
                                      .planing,
                                      sunday: [
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(0,1),
                                      updatedObj,
                                      ...this.state.pharmacy.work_schedule
                                      .planing.sunday.slice(2)
                                    ]
                                  }
                                }
                              }
                            });
                          }}
                          fullWidth={true}
                        />
                      </td>


                    </tr>
                  </table>
                </div>
              </CardContent>
						</Card>

						{/* <InputLabel style={{ position: "relative", lineHeight: "1px",  fontFamily: "Montserrat, sans-serif" }}>
                        Gallerie
                                                            </InputLabel>
                    <div style={{ display: "block" }}>
                        <div>
                            <span style={{

                                cursor: "pointer",
                                padding: "1rem",
                                textAlign: "center",
                                padding: "25px",
                                fontSize: "20px",

                                textAlign: "center",
                                display: "block",
                            }}> Déposer des images à télécharger ou cliquez pour sélectionner une</span>
                            <input accept="\image" type="file" autocomplete="off" style={{ display: "none" }} />
                        </div>
                    </div> */}

						<div style={{ display: 'block', marginTop: '30px' }}> </div>

						<div style={{ display: 'block', marginTop: '30px' }}> </div>
						<NotificationContainer />
						<div />
						{/*  <Mutation mutation={UPDATE_PAHRMACIES_MUTATION
                } variables={(this.state.pharmacy.id, this.state.pharmacy.is_pill_maker, this.state.pharmacy.name, this.state.pharmacy.tel, this.state.pharmacy.website, this.state.pharmacy.address, this.state.pharmacy.country, this.state.pharmacy.city, this.state.pharmacy.zip_code, this.state.pharmacy.location.latitude, this.state.pharmacy.location.longitude)}>
                    {updatePharmacyDetails => <button onClick={updatePharmacyDetails}>Confirmer</button>}
                </Mutation>
 */}

						<div className={classes.boxFlex}>
							<button
								className="plBtn"
								style={{
									height: '36px',
									marginTop: 15,
									width: '100%',
									maxWidth: '300px',
									marginBottom: 15,
									backgroundColor: 'rgba(3, 180, 115)',
									backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
									color: 'rgb(255, 255, 255)',
									fontFamily: 'Montserrat, sans-serif',
									color: 'rgb(255, 255, 255)',
									border: 'none',
									boxShadow:
										'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
									borderRadius: '4px',
									textTransform: 'uppercase',
									fontWeight: '500',
									outline: 'none',
									fontSize: '0.875rem'
								}}
								onClick={() => {
									this.setState({ loading: true });
									this.handleSubmit(this.state.pharmacy);
								}}
							>
								Confirmer
							</button>
							<button
								className="plBtn"
								style={{
									height: '36px',
									marginTop: 15,
									width: '100%',
									maxWidth: '300px',
									marginBottom: 15,
									backgroundColor: 'rgba(3, 180, 115)',
									backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
									color: 'rgb(255, 255, 255)',
									fontFamily: 'Montserrat, sans-serif',
									color: 'rgb(255, 255, 255)',
									border: 'none',
									boxShadow:
										'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
									borderRadius: '4px',
									textTransform: 'uppercase',
									fontWeight: '500',
									outline: 'none',
									fontSize: '0.875rem'
								}}
								onClick={() => this.props.history.push(`/pharmacies`)}
							>
								Annuler
							</button>
						</div>
					</div>
				) : (
					<div>
						<div className={classes.progressContainer}>
							<CircularProgress className={classes.progress} />
						</div>
					</div>
				)}
			</div>
		);
	}
}


UpdatePharmacy.propTypes = {
	classes: PropTypes.object.isRequired
};

const mapStateToProps = ({ pharmacy }) => ({ pharmacy });

const mapDispatchToProps = dispatch => ({
	loadPharmacy: bindActionCreators(loadPharmacy, dispatch)
});

export default compose(
	graphql(LOAD_PAHRMACY_QUERY, {
		options: props => {
		
			return {
				variables: {
					id: props.match.params.id
				}
			};
		}
	}),
	graphql(UPDATE_PAHRMACIES_MUTATION)
)(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles, { withTheme: true })(UpdatePharmacy)));
