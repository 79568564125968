import {ApolloClient} from "apollo-boost";
import {setContext} from "apollo-link-context";
import {createUploadLink} from "apollo-upload-client";
import {InMemoryCache} from "apollo-cache-inmemory";
import {concat} from "apollo-link";
import {BASE_URL} from "../tools/constants";


const cache = new InMemoryCache();


const uploadLink = createUploadLink({
    uri: BASE_URL + "/graphql",
});

const authMiddleware = setContext(async (_, {headers}) => {
    const token = await localStorage.getItem("token");
    return {
        headers: {
            ...headers,
            authorization: token.replace(new RegExp('"', "g"), "")
        }
    };
});

const client = new ApolloClient({
    link: concat(authMiddleware, uploadLink),
    cache
});

export default client;