import React from "react";
import { withRouter } from "react-router-dom";
import { withStyles } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { loadClient } from "../actions";
import { capitalizeFirstLetter } from "../tools/utils";
import classNames from "classnames";
import PropTypes from "prop-types";
import Avatar from "@material-ui/core/Avatar";
import Zoom from "@material-ui/core/Zoom";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import TextField from "@material-ui/core/TextField";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from "@material-ui/icons/FilterList";
import RefreshIcon from "@material-ui/icons/Refresh";
import AddIcon from "@material-ui/icons/Add";
import green from "@material-ui/core/colors/green";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import List from "@material-ui/core/List";
import InputLabel from '@material-ui/core/InputLabel';
import gql from "graphql-tag";
import { Mutation } from "react-apollo";
import { graphql, compose } from "react-apollo";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import FormControl from '@material-ui/core/FormControl';
import axios from "./../tools/axios";

class EnhancedTableHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const { order, orderBy } = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                numeric={row.numeric}
                                padding={i == 0 ? "checkbox" : (row.disablePadding ? "none" : "default")}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
});


class EnhancedTableToolbar extends React.Component {

    render() {
        const { classes } = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle">

                    </Typography>
                </div>
                <div className={classes.spacer} />
                <div className={classes.actions}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

EnhancedTableToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    boxFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",

    },
    titre: {
        marginTop: "20px",
    },

    '@media screen and (max-width: 768px)': {
        firstTitle: {
            fontSize: 22,
            textAlign: "center",
        },

        boxFlex: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",

        },
        boxFlex1: {
            margin: "0 !important",
            marginBottom: "2em !important",
        },
        logo: {
            height: "32px !important",
            marginBottom: "7px "
        },
        boxNav: {
            paddingTop: "30px !important",
        },

        titre: {
            fontSize: "16px !important",
            marginTop: "0 !important",
        },
        boxRight: {
            textAlign: "right",
        }
    }
    ,
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    colorSwitchBase: {

    },
    colorBar: {
    },
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500],
            },
        },
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    }
});
/***************************************************Page UpdateClient************************************************* */
const UPDATE_CLIENTS_MUTATION = gql`
    mutation updateClientDetails($id: ID! , $first_name: String!, $last_name: String!, $tel:String!, $address : String!) {
        updateClientDetails (id : $id , input: {first_name: $first_name,last_name:$last_name,tel:$tel, address:$address}) 
            {
            id          
            first_name
            last_name
            tel 
            address     
        }
    }
`;

const LOAD_CLIENT_QUERY = gql`
query getClient ($id: ID!){ 
        getClient(id: $id) {
            id
            num
            first_name
            last_name
            tel        
            address          
        }
    }
`;

class UpdateClient extends React.Component {



    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };


    constructor(props) {
        super(props);

        this.state = {
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 5,
            client: this.props.data&&this.props.data.getClient ? this.props.data.getClient : {}
        }
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
       // this.setState({ client: this.props.data&&this.props.data.getClient ? this.props.data.getClient : {} });
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.checked });
    };


    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({ order, orderBy });
    };

    handleChangePage = (event, page) => this.setState({ page });

    handleChangeRowsPerPage = event => {
        this.setState({ rowsPerPage: event.target.value });
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({ showFilter: !this.state.showFilter, filterText: "" });
        } else {
            this.setState({ showFilter: !this.state.showFilter });
        }
    };



    handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

    componentWillReceiveProps(nextProps) {
    
        this.setState({ client: nextProps.data&&nextProps.data.getClient ? nextProps.data.getClient : {} });
    }
  

    componentWillUnmount() {
        window.onscroll = null;
    }


    handleNext = () => {
        this.setState(state => ({
            activeStep: state.activeStep + 1,
        }));
    };

    handleBack = () => {
        this.setState(state => ({
            activeStep: state.activeStep - 1,
        }));
    };

    handleReset = () => {
        this.setState({
            activeStep: 0,
        });
    };


    handleSubmit = (client) => {
        NotificationManager.listNotify=[];

        let { id, first_name, last_name, tel, address } = this.state.client;
        if(first_name.length==0){
            return NotificationManager.error('Le nom est obligatoire', 'Erreur');
        }
        if(last_name.length==0){
            return NotificationManager.error('Le prénom est obligatoire', 'Erreur');
        }
        if(tel.length==0){
            return NotificationManager.error('Le téléphone est obligatoire', 'Erreur');
        }
        if(address.length==0){
            return NotificationManager.error('L\'adresse est obligatoire', 'Erreur');
        }
        let body={"address":address,"phone":tel};
        axios.post('/stuart/validAddress?type=delivering',body).then(resp=>{
     
          if(resp.data&&resp.data.success){
        this.props.mutate({
            variables: { id, first_name, last_name, tel, address }
        }).then(response => {
            NotificationManager.success('Ce patient a été mis à jour', 'Succès');
            setTimeout(() => {
                this.props.history.push('/admins')
            }, 2000)
        }).catch(err => {
         
            NotificationManager.error('La modification n\'a pas eu lieu ,veuillez renseigner tous les champs', 'Erreur')
        })
    }
    else{
        if(resp.data&&resp.data.error=="ADDRESS_CONTACT_PHONE_REQUIRED"){
            return NotificationManager.error("Le téléphone est invalide");
          }
          return NotificationManager.error("L'adresse est invalide");
    }
    },error=>{
        if(error.data&&error.data.error=="ADDRESS_CONTACT_PHONE_REQUIRED"){
            return NotificationManager.error("Le téléphone est invalide");
          }
          return NotificationManager.error("L'adresse est invalide");
    });
    }






    handleFiledChange = (event) => {
        this.setState({ [event.target.name]: event.target.value })
    }
    render() {





        const { classes, theme } = this.props;



        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        };








        return (

            <div>







                <div className={classes.tableWrapper}>








                    <div className={classes.root}>



                        <List component="nav">

                            {/* <Typography color="textSecondary" variant="headline">
                                <div style={{
                                    display: "flex",
                                    flex: 2,
                                    flexDirection: "row",
                                    alignItems: "center",
                                    color:"#27aab3",
                                    fontFamily: "Montserrat, sans-serif",
                                }}>
                                    <img alt="Avatar" style={{ width: "50px", height: "50px" }}

                                    />




                                </div>

                            </Typography> */}

                            <form>

                                <div style={{ display: "block", marginTop: "50px" }}> </div>
                                <div>

                                    <InputLabel style={{ position: "relative", lineHeight: "1px", fontWeight: "bold", fontFamily: "Montserrat, sans-serif", }}>
                                        Prénom
                                                        </InputLabel>


                                    <TextField
                                        id="first_name"
                                        label=""
                                        value={this.state.client.first_name}

                                        name='first_name'


                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        style={{ Height: "100%", marginTop: "1px", padding: "0px", fontFamily: "Montserrat, sans-serif", }}
                                        onChange={e => {
                                            let client = Object.assign({}, this.state.client);
                                            client.first_name = e.target.value;
                                            this.setState({ client });
                                        }}


                                        fullWidth={true}




                                    />
                                </div>
                                <div style={{ display: "block", marginTop: "50px" }}> </div>


                                <InputLabel style={{ position: "relative", lineHeight: "1px", fontWeight: "bold", fontFamily: "Montserrat, sans-serif", }}>
                                    Nom de famille
                                                            </InputLabel>

                                <TextField

                                    value={this.state.client.last_name}
                                    id="last_name"
                                    name='last_name'
                                    label=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ Height: "100%", marginTop: "1px", padding: "0px", fontFamily: "Montserrat, sans-serif", }}

                                    onChange={e => {
                                        let client = Object.assign({}, this.state.client);
                                        client.last_name = e.target.value;
                                        this.setState({ client });
                                    }}
                                    fullWidth={true}

                                />
                                <div style={{ display: "block", marginTop: "50px" }}> </div>



                                <InputLabel style={{ position: "relative", lineHeight: "1px", fontWeight: "bold", fontFamily: "Montserrat, sans-serif", }}>
                                    Téléphone
                                                            </InputLabel>

                                <TextField
                                    value={this.state.client.tel}
                                    id="tel"
                                    label=""
                                    name='tel'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}

                                    style={{ Height: "100%", marginTop: "1px", padding: "0px", fontFamily: "Montserrat, sans-serif", }}
                                    onChange={e => {
                                        let client = Object.assign({}, this.state.client);
                                        client.tel = e.target.value;
                                        this.setState({ client });
                                    }}


                                    fullWidth={true}

                                />
                                <div style={{ display: "block", marginTop: "50px" }}> </div>


                                <InputLabel style={{ position: "relative", lineHeight: "1px", fontWeight: "bold", fontFamily: "Montserrat, sans-serif", }}>
                                    Adresse
                                                            </InputLabel>

                                <TextField
                                    value={this.state.client.address}


                                    id="full-width"
                                    label=""
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    style={{ Height: "100%", marginTop: "1px", padding: "0px", fontFamily: "Montserrat, sans-serif", }}
                                    onChange={e => {
                                        let client = Object.assign({}, this.state.client);
                                        client.address = e.target.value;
                                        this.setState({ client });
                                    }}


                                    fullWidth={true}

                                />
                                <div style={{ display: "block", marginTop: "50px" }}> </div>


                                <div style={{ display: "block", marginTop: "50px" }}> </div>









                                <div style={{ display: "block", marginTop: "30px" }}> </div>


                                <div>
                                    <NotificationContainer />

                                </div>
                                <div className={classes.button} >
                                    <div style={{
                                        position: "relative",
                                        display: "flex",

                                        alignItems: "center",
                                    }} className={classes.boxFlex} >

                                        <button type="button" className="plBtn" style={{
                                            height: "36px", marginTop: 15, width: "100%", maxWidth: "300px",
                                            marginBottom: 15, backgroundColor: "rgba(3, 180, 115)", backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)", color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color: "rgb(255, 255, 255)",
                                            border: "none", boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)", borderRadius: "4px", textTransform: "uppercase", fontWeight: "500",
                                            outline: "none", fontSize: "0.875rem",
                                        }} onClick={() => this.handleSubmit(this.props.data&&this.props.data.getClient)}>Confirmer</button>
                                        <button className="plBtn" style={{
                                            height: "36px", marginTop: 15, width: "300px", width: "100%", maxWidth: "300px",
                                            marginBottom: 15, backgroundColor: "rgba(3, 180, 115)", backgroundImage: "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)", color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color: "rgb(255, 255, 255)",
                                            border: "none", boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)", borderRadius: "4px", textTransform: "uppercase", fontWeight: "500",
                                            outline: "none", fontSize: "0.875rem",
                                        }} onClick={() => this.props.history.push(`/admins`)}>Annuler</button>

                                    </div>
                                </div>
                            </form>
















                        </List>






                    </div>



                </div>





            </div>
        );

    }
}


UpdateClient.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = ({ client }) => ({ client });

const mapDispatchToProps = dispatch => ({
    loadClient: bindActionCreators(loadClient, dispatch),

});

export default compose(
    graphql(LOAD_CLIENT_QUERY, {
        options: props => {
            return ({
                variables: {
                    id: window.location.hash.substring(14)
                }
            })
        }
    }),
    graphql(UPDATE_CLIENTS_MUTATION),
)(connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(UpdateClient))))


const Styles = theme => ({

    button: {
        boxSizing: "border-box",


        height: "56px",
        padding: "0px 24px",
        display: "flex",
        justifyContent: "space-between",
        clear: "both",
    }
})