import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Time from './Time';

const styles = theme => ({
  root: {
    width: '90%',
    fontFamily: "Montserrat, sans-serif",
  },
  button: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    fontFamily: "Montserrat, sans-serif",
  },
  actionsContainer: {
    marginBottom: theme.spacing.unit * 2,
    fontFamily: "Montserrat, sans-serif",
  },
  resetContainer: {
    padding: theme.spacing.unit * 3,
    fontFamily: "Montserrat, sans-serif",
  },
});

function getSteps() {
  return ['Lundi', 'Mardi', 'Mercredi','Jeudi','Vendredi','Samedi','Dimanche'];
}

function getStepContent(step) {
  switch (step) {
    case 0:
      return <Time/>;
    case 1:
      return <Time/>;
    case 2:
      return <Time/>;
      case 3:
      return <Time/>;
      case 4:
      return <Time/>;
      case 5:
      return <Time/>;
      case 6:
      return <Time/>;
    default:
      return 'Unknown step';
  }
}

class VerticalLinearStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState(state => ({
      activeStep: state.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      activeStep: state.activeStep - 1,
    }));
  };

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { classes } = this.props;
    const steps = getSteps();
    const { activeStep } = this.state;

    return (
      <div className={classes.root} style={{   fontFamily: "Montserrat, sans-serif",  color:"#28abb3",}} >
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((label, index) => {
            return (
              <Step style={{   fontFamily: "Montserrat, sans-serif",  color:"#28abb3",}}  key={label}>
                <StepLabel style={{   fontFamily: "Montserrat, sans-serif",  color:"#28abb3",}} >{label}</StepLabel>
                <StepContent>
                  <Typography>{getStepContent(index)}</Typography>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button style={{   fontFamily: "Montserrat, sans-serif",  color:"#28abb3",}}
                        disabled={activeStep === 0}
                        onClick={this.handleBack}
                        className={classes.button}
                      >
                        précédent
                      </Button>
                      <Button  style={ {backgroundColor:"rgba(3, 180, 115)",backgroundImage:"linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",  color: "rgb(255, 255, 255)", fontFamily: "Montserrat, sans-serif", color:"rgb(255, 255, 255)"}}
                        variant="contained"
                        color="primary"
                        onClick={this.handleNext}
                        className={classes.button}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'suivant'}
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
            );
          })}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography></Typography>
            <Button onClick={this.handleReset} className={classes.button}>
              Reconsulter
            </Button>
          </Paper>
        )}
      </div>
    );
  }
}


VerticalLinearStepper.propTypes = {
  classes: PropTypes.object,
};

export default withStyles(styles)(VerticalLinearStepper);