import React from "react";
import {ApolloProvider} from "react-apollo";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {withStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import purple from "@material-ui/core/colors/purple";
import Fade from "@material-ui/core/Fade";

import Home from "./Home";
import {checkToken} from "../actions";
import apolloClient from "../apolloClient";

const styles = ({
    progressContainer: {
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
    }
});

class AuthLoading extends React.Component{

    componentWillMount(){
        this.props.checkToken();
    }

    render(){
        const { classes,isMenuOpen } = this.props;
        if (this.props.checkTokenState.success) {
            return (
                <ApolloProvider client={apolloClient} isMenuOpen>
                    <Home isMenuOpen/>
                </ApolloProvider>
            );
        }
        return (
            <div className={classes.progressContainer}>
                <Fade in timeout={1000}>
                    <CircularProgress style={{ color: purple[500] }} thickness={2} />
                </Fade>
            </div>
        );
    }
}

const mapStateToProps = ({checkToken}) => ({checkTokenState: checkToken});

const mapDispatchToProps = dispatch => ({
    checkToken: bindActionCreators(checkToken, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AuthLoading));