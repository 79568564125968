import React from "react";
import {Provider} from "react-redux";
import {Router} from "react-router-dom";

import AuthLoading from "./pages/AuthLoading";
import Login from "./pages/Login";

import store from "./store";
import history from "./history";

import {isLoggedIn} from "./tools/auth";

export default class App extends React.Component {
    render() {
        return (
            <Provider store={store}>
                <Router history={history}>
                    {isLoggedIn() ? <AuthLoading {...this.props} /> : <Login/>}
                </Router>
            </Provider>
        );
    }
};