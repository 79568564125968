import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

class AboutDialog extends React.Component{
    render(){
        const {fullScreen} = this.props;
        return (
            <Dialog
                fullScreen={fullScreen}
                open={this.props.open}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="alert-dialog-title">{"A propros"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                    <img src=" http://pharmaloop.fr/images/logo/logo.png" alt="Logo"/><br/><br/>
                    Version: 0.0.1
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.props.onClose()} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AboutDialog;