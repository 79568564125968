import React from 'react';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { capitalizeFirstLetter } from '../tools/utils';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from '@material-ui/core/Switch';
import Toolbar from '@material-ui/core/Toolbar';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Horaire from './Horaire';
import ContactPhoneIcon from '@material-ui/icons/ContactPhone';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import SaveIcon from '@material-ui/icons/Save';
import Select from '@material-ui/core/Select';
import gql from 'graphql-tag';
import MenuItem from '@material-ui/core/MenuItem';
import { Mutation } from 'react-apollo';
import { graphql, compose } from 'react-apollo';
import { NotificationContainer, NotificationManager } from 'react-notifications';

import FormControl from '@material-ui/core/FormControl';
import axios from './../tools/axios';
class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										{row.label}
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},
	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //

class EnhancedTableToolbar extends React.Component {
	render() {
		const { classes } = this.props;

		return (
			<Toolbar className={classNames(classes.root)}>
				<div className={classes.title}>
					<Typography variant="title" id="tableTitle" />
				</div>
				<div className={classes.spacer} />
				<div className={classes.actions}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
							<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Rafraîchir">
							<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</Toolbar>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	formControl: {
		margin: theme.spacing.unit,
		minWidth: 120
	}
});
/***************************************************Page  New Produit************************************************* */
const ADD_PRODUIT_MUTATION = gql`
	mutation addParaPharmacy(
		$file: Upload!
		$category_id: ID!
		$sub_category_id: ID!
		$nested_sub_category_id: ID!
		$name: String!
		$laboratory: String!
		$description: String
		$bar_code: String!
		$indications: String
		$usage_tips: String
		$composition: String
		$precautions: String
	) {
		addParaPharmacy(
			input: {
				file: $file
				category_id: $category_id
				sub_category_id: $sub_category_id
				nested_sub_category_id: $nested_sub_category_id
				name: $name
				laboratory: $laboratory
				description: $description
				bar_code: $bar_code
				indications: $indications
				usage_tips: $usage_tips
				composition: $composition
				precautions: $precautions
			}
		) {
			id
			name
			laboratory
			description
			img_url
			indications
			usage_tips
			composition
			precautions
		}
	}
`;
const UPDATE_PRODUIT_MUTATION = gql`
	mutation updateParaPharmacy(
		$file: Upload
		$id: ID!
		$name: String!
		$laboratory: String!
		$description: String
		$bar_code: String!
		$indications: String
		$usage_tips: String
		$composition: String
		$precautions: String
	) {
		updateParaPharmacy(
			id: $id
			input: {
				file: $file
				name: $name
				laboratory: $laboratory
				description: $description
				bar_code: $bar_code
				indications: $indications
				usage_tips: $usage_tips
				composition: $composition
				precautions: $precautions
			}
		) {
			id
			name
			laboratory
			description
			img_url
			indications
			usage_tips
			composition
			precautions
		}
	}
`;
const LOAD_PRODUIT_QUERY = gql`
	query getParaPharmacyById($id: ID!) {
		getParaPharmacyById(id: $id) {
			id
			name
			laboratory
			description
			img_url
			bar_code
			indications
			usage_tips
			composition
			precautions
		}
	}
`;
const LOAD_CATEGORIES_QUERY = gql`
	query getCategories {
		getCategories {
			id
			name
			para_count
			state
			createdAt
			subCategories {
				id
				name
				para_count
				state
				createdAt
				nestedSubCategories {
					id
					name
					para_count
					state
					createdAt
				}
			}
		}
	}
`;
class Produit extends React.Component {
	static propTypes = {
		match: PropTypes.object.isRequired,
		location: PropTypes.object.isRequired,
		history: PropTypes.object.isRequired
	};

	constructor(props) {
		super(props);
		this.state = {
			avatarChangeLoading: false,
			name:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.name
					? this.props.getProduit.getParaPharmacyById.name
					: '',
			laboratory:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.laboratory
					? this.props.getProduit.getParaPharmacyById.laboratory
					: '',
			description:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.description
					? this.props.getProduit.getParaPharmacyById.description
					: '',
			img_url:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.img_url
					? this.props.getProduit.getParaPharmacyById.img_url
					: '',
			price:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.price
					? this.props.getProduit.getParaPharmacyById.price / 100
					: 0,
			bar_code:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.bar_code
					? this.props.getProduit.getParaPharmacyById.bar_code
					: '',
			indications:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.indications
					? this.props.getProduit.getParaPharmacyById.indications
					: '',
			usage_tips:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.usage_tips
					? this.props.getProduit.getParaPharmacyById.usage_tips
					: '',
			composition:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.composition
					? this.props.getProduit.getParaPharmacyById.composition
					: '',
			precautions:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.precautions
					? this.props.getProduit.getParaPharmacyById.precautions
					: '',
			category: {},
			sub_category: {},
			nested_sub_category: {},
			listSousCategories: [],
			listSousSousCategories: [],
			categorie_name: '',
			sous_categorie_name: '',
			sous_sous_categorie_name: '',
			image: '',
			file: null,
			id:
				this.props.getProduit &&
				this.props.getProduit.getParaPharmacyById &&
				this.props.getProduit.getParaPharmacyById.id
					? this.props.getProduit.getParaPharmacyById.id
					: ''
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
		this.getCategories = this.getCategories.bind(this.props);
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.getProduit !== this.props.getProduit) {
			if (nextProps.getProduit && nextProps.getProduit && nextProps.getProduit.getParaPharmacyById) {
				let {
					id,
					name,
					laboratory,
					description,
					indications,
					usage_tips,
					composition,
					precautions,
					category_id,
					sub_category_id,
					nested_sub_category_id,
					bar_code
				} =
					nextProps.getProduit && nextProps.getProduit.loading == false && nextProps.getProduit.getParaPharmacyById;
			if (id)
					this.setState({
						id,
						name,
						laboratory,
						description,
						indications,
						usage_tips,
						composition,
						precautions,
						bar_code
					});
			}
		}
	}
	getCategories = (parentId, sub, nested) => {

		let category, sub_category, nested_sub_category;
		this.state.listSousCategories.forEach(element => {
			if (element.id == parentId) {
				category = element;
				element.subCategories.forEach(subcat => {
					if (subcat.id == sub) {
						sub_category = subcat;
						subcat.nestedSubCategories.forEach(nestedCat => {
							if (nestedCat.id == nested) {
								nested_sub_category = nestedCat;
							}
						});
					}
				});
			}
		});
		return { category, sub_category, nested_sub_category };
	};
	handleChange = name => event => {
		this.setState({ [name]: event.target.value });
	};

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}

	handleNext = () => {
		this.setState(state => ({
			activeStep: state.activeStep + 1
		}));
	};

	handleBack = () => {
		this.setState(state => ({
			activeStep: state.activeStep - 1
		}));
	};

	handleReset = () => {
		this.setState({
			activeStep: 0
		});
	};
	handleChangeAvatar = ({ target: { files } }) => {
		let reader = new FileReader();
		reader.onload = e => {
			this.setState({ image: e.target.result });
		};

		reader.readAsDataURL(files[0]);
		return this.setState({ file: files.length ? files[0] : null });
	};
	handleCategorieChange = event => {
		this.setState({ [event.target.name]: event.target.value && event.target.value });
		if (event.target.name == 'category') {
			this.setState({ listSousCategories: event.target.value && event.target.value.subCategories });
			this.setState({ listSousSousCategories: event.target.value && event.target.value.subCategories });
			this.setState({ nested_sub_category: {} });
		} else if (event.target.name == 'sub_category') {
			this.setState({ listSousSousCategories: event.target.value && event.target.value.nestedSubCategories });
			this.setState({ nested_sub_category: {} });
		} else {
			this.setState({ sous_sous_categorie_name: event.target.value && event.target.value.name });
		}

	};
	handleSubmit = e => {
		NotificationManager.listNotify = [];
		e.preventDefault();


		let {
			id,
			name,
			laboratory,
			description,
			bar_code,
			indications,
			usage_tips,
			composition,
			precautions,
			category,
			sub_category,
			nested_sub_category,
			img_url,
			file
		} = this.state;
		if (this.state.nested_sub_category.id == undefined && this.state.id.length == 0) {
			return NotificationManager.error('La sous sous catégorie est obligatoire', 'Erreur');
		}
		if (name.length == 0) {
			return NotificationManager.error('Le nom est obligatoire', 'Erreur');
		}
		if (laboratory.length == 0) {
			return NotificationManager.error('Le laboratoire est obligatoire', 'Erreur');
		}
		// if (isNaN(this.state.price)) {
		//   return NotificationManager.error('Le prix est obligatoire et doit être supérieur à 0', 'Erreur');
		// }
		//.match(/^\d{13}$/)
		if (isNaN(this.state.bar_code) || this.state.bar_code.length < 13) {
			return NotificationManager.error('Code EAN incorrect', 'Erreur');
		}
		if (this.state.id.length == 0) {
			this.props
				.addProduit({
					variables: {
						name,
						file,
						laboratory,
						description,
						bar_code,

						indications,
						usage_tips,
						composition,
						precautions,
						category_id: category.id,
						sub_category_id: sub_category.id,
						nested_sub_category_id: nested_sub_category.id
					}
				})
				.then(response => {
					this.props.history.push({ pathname: '/produits', state: { refetch: true } });
				})
				.catch(error => {

				});
		} else {
			this.props
				.updateProduit({
					variables: {
						id,
						name,
						file,
						laboratory,
						description,
						bar_code,
						indications,
						usage_tips,
						composition,
						precautions
					}
				})
				.then(response => {
					this.props.history.push({ pathname: '/produits', state: { refetch: true } });
				})
				.catch(error => {
				
				});
		}
	};

	handleChangeAvatar = ({ target: { files } }) => {
		let reader = new FileReader();
		reader.onload = e => {
			this.setState({ image: e.target.result });
		};

		reader.readAsDataURL(files[0]);
	
		this.setState({ file: files.length ? files[0] : null });

	};

	render() {
		const { classes, theme } = this.props;

		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		return (
			<div>
				<div className={classes.tableWrapper}>
					<List component="nav">
						<form>
							<Typography color="textSecondary" variant="headline">
								<h3> {this.state.id.length == 0 ? ' AJOUTER UN PRODUIT' : 'MODIFIER UN PRODUIT'} </h3>
							</Typography>

							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<div>
								<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
									Image
								</InputLabel>
								<div
									style={{
										background: 'rgb(239, 239, 239)',
										cursor: 'pointer',
										padding: '1rem',
										textAlign: 'center',
										color: 'rgb(153, 153, 153)'
									}}
								>
									<p>Déposez une photo à télécharger ou cliquez pour la sélectionner.</p>
									<input
										type="file"
										accept="image/*"
										autoComplete="off"
										onChange={this.handleChangeAvatar}
										className="form-control"
									/>
									<div>
										<img
											src={this.state.url ? this.state.url : this.state.image}
											style={{
												maxWidth: '250px',
												maxHeight: '250px',
												marginTop: '10px',
												'margin-left': '-10px'
											}}
										/>
									</div>
								</div>
								<div />
								{this.state.id.length == 0 && (
									<div
										style={{
											display: 'flex',
											'flex-direction': 'column',
											'margin-bottom': 20
										}}
									>
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="age-simple">Catégorie</InputLabel>
											<Select
												value={this.state.category}
												onChange={this.handleCategorieChange}
												inputProps={{
													name: 'category',
													id: 'categorie-simple'
												}}
											>
												{this.props.getCategories &&
													this.props.getCategories.getCategories &&
													this.props.getCategories.getCategories.map(c => {
														return <MenuItem value={c}>{c.name}</MenuItem>;
													})}
											</Select>
										</FormControl>
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="age-simple">Sous catégorie</InputLabel>
											<Select
												value={this.state.sub_category}
												onChange={this.handleCategorieChange}
												inputProps={{
													name: 'sub_category',
													id: 'categorie-simple'
												}}
											>
												{this.state.listSousCategories &&
													this.state.listSousCategories.map(c => {
														return <MenuItem value={c}>{c.name}</MenuItem>;
													})}
											</Select>
										</FormControl>
										<FormControl className={classes.formControl}>
											<InputLabel htmlFor="age-simple">Sous sous catégorie</InputLabel>
											<Select
												value={this.state.nested_sub_category}
												onChange={this.handleCategorieChange}
												inputProps={{
													name: 'nested_sub_category',
													id: 'categorie-simple'
												}}
											>
												{this.state.listSousSousCategories &&
													this.state.listSousSousCategories.map(c => {
														return <MenuItem value={c}>{c.name}</MenuItem>;
													})}
											</Select>
										</FormControl>
									</div>
								)}
								<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
									Nom du produit
								</InputLabel>

								<TextField
									required
									label=""
									value={this.state.name}
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => this.setState({ name: e.target.value })}
									fullWidth={true}
								/>
							</div>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
								Code EAN
							</InputLabel>

							<TextField
								required
								label=""
								value={this.state.bar_code}
								InputLabelProps={{
									shrink: true
								}}
								style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
								onChange={e => this.setState({ bar_code: e.target.value })}
								fullWidth={true}
							/>

							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<div>
								<InputLabel required style={{ position: 'relative', lineHeight: '1px' }}>
									Laboratoire
								</InputLabel>

								<TextField
									required
									label=""
									value={this.state.laboratory}
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => this.setState({ laboratory: e.target.value })}
									fullWidth={true}
									multiline
								/>
							</div>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<InputLabel style={{ position: 'relative', lineHeight: '1px' }}>Description</InputLabel>

							<TextField
								type="text"
								value={this.state.description}
								label=""
								InputLabelProps={{
									shrink: true
								}}
								style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
								onChange={e => this.setState({ description: e.target.value })}
								fullWidth={true}
								variant="outlined"
								multiline
							/>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							<div>
								<InputLabel style={{ position: 'relative', lineHeight: '1px' }}>Indications</InputLabel>

								<TextField
									label=""
									value={this.state.indications}
									InputLabelProps={{
										shrink: true
									}}
									style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
									onChange={e => this.setState({ indications: e.target.value })}
									fullWidth={true}
									multiline
								/>
							</div>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>

							<InputLabel style={{ position: 'relative', lineHeight: '1px' }}>Usage</InputLabel>

							<TextField
								value={this.state.usage_tips}
								label=""
								InputLabelProps={{
									shrink: true
								}}
								style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
								onChange={e => this.setState({ usage_tips: e.target.value })}
								fullWidth={true}
								multiline
							/>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>

							<InputLabel style={{ position: 'relative', lineHeight: '1px' }}>Composition</InputLabel>

							<TextField
								value={this.state.composition}
								label=""
								InputLabelProps={{
									shrink: true
								}}
								style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
								onChange={e => this.setState({ composition: e.target.value })}
								fullWidth={true}
								className={classes.TextField}
								margin="normal"
								variant="outlined"
								multiline
							/>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>

							<InputLabel style={{ position: 'relative', lineHeight: '1px' }}>Précautions</InputLabel>

							<TextField
								value={this.state.precautions}
								label=""
								InputLabelProps={{
									shrink: true
								}}
								style={{ Height: '100%', marginTop: '1px', padding: '0px' }}
								onChange={e => this.setState({ precautions: e.target.value })}
								fullWidth={true}
								multiline
							/>
							<div style={{ display: 'block', marginTop: '50px' }}> </div>
							{/* <div style={{
                  display: "flex",
                  flexDirection: "column"
            }}>
            <FormControl className={classes.formControl}>
          <InputLabel htmlFor="state-simple">Etat</InputLabel>
          <Select
            value={this.state.state}
            onChange={this.handleChange}
            inputProps={{
              name: 'state',
              id: 'state-simple',
            }}
          >
            <MenuItem value={true}>Activé</MenuItem>
            <MenuItem value={false}>Désactivé</MenuItem>
          </Select>
        </FormControl>
            </div> */}

							<div style={{ display: 'block', marginTop: '50px' }}> </div>

							<div style={{ display: 'block', marginTop: '30px' }}> </div>
							<NotificationContainer />

							<div />
							<div>
								<Button
									style={{
										height: '36px',
										marginTop: 15,
										width: '300px',
										marginBottom: 15,
										backgroundColor: 'rgba(3, 180, 115)',
										backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
										color: 'rgb(255, 255, 255)',
										fontFamily: 'Montserrat, sans-serif',
										color: 'rgb(255, 255, 255)',
										marginRight: '50px',
										border: 'none',
										boxShadow:
											'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
										borderRadius: '4px',
										textTransform: 'uppercase',
										fontWeight: '500',
										outline: 'none',
										fontSize: '0.875rem'
									}}
									onClick={this.handleSubmit}
								>
									{this.state.id.length == 0 ? ' Enregistrer' : 'Mettre à jour'}
								</Button>
								<Button
									style={{
										height: '36px',
										marginTop: 15,
										width: '300px',
										marginBottom: 15,
										backgroundColor: 'rgba(3, 180, 115)',
										backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
										color: 'rgb(255, 255, 255)',
										fontFamily: 'Montserrat, sans-serif',
										color: 'rgb(255, 255, 255)',
										border: 'none',
										boxShadow:
											'0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
										borderRadius: '4px',
										textTransform: 'uppercase',
										fontWeight: '500',
										outline: 'none',
										fontSize: '0.875rem'
									}}
									onClick={() => {
										this.props.history.push({ pathname: '/produits', state: { refetch: true } });
									}}
								>
									Annuler
								</Button>
							</div>
						</form>
					</List>
				</div>
			</div>
		);
	}
}


Produit.propTypes = {
	classes: PropTypes.object.isRequired
};

export default compose(
	graphql(LOAD_CATEGORIES_QUERY, {
		name: 'getCategories'
	}),
	graphql(LOAD_PRODUIT_QUERY, {
		name: 'getProduit',
		options: props => {
			return {
				variables: {
					id: window.location.hash.substring(window.location.hash.indexOf('id=') + 3)
					// id:"5c17c74d6a701e65b4994545"
				}
			};
		}
	}),
	graphql(ADD_PRODUIT_MUTATION, {
		name: 'addProduit'
	}),
	graphql(UPDATE_PRODUIT_MUTATION, {
		name: 'updateProduit'
	})
)(withStyles(styles, { withTheme: true })(Produit));
