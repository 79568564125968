import React from "react";
import {withRouter} from "react-router-dom";
import {withStyles} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Typography from "@material-ui/core/Typography";
import {connect} from "react-redux";
import{bindActionCreators} from "redux";
import {loadCommands} from "../actions";
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Eye from '@material-ui/icons/RemoveRedEye';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Avatar from "@material-ui/core/Avatar";
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Switch from "@material-ui/core/Switch";
import {capitalizeFirstLetter} from "../tools/utils";

import Toolbar from '@material-ui/core/Toolbar';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';

import {lighten} from '@material-ui/core/styles/colorManipulator';


const style = theme => ({
    container: {
        flexGrow: 1,
    },
    card: {
        marginTop: theme.spacing.unit * 3,
        padding: theme.spacing.unit * 3,
    }
});




/************************************************************TABLE OF COMMANDS************************************************/
function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const rows = [
    { id: "", numeric: false, disablePadding: true, label: "NUMÉRO DE COMMANDE" },
    { id: "pharmacy", numeric: true, disablePadding: false, label: "PHARMACIE" },
    { id: "client", numeric: true, disablePadding: false, label: "AFFECTÉ" },
    { id: "", numeric: true, disablePadding: false, label: "LIVREUR" },
    { id :"createdAt", numeric: true, disablePadding: false , label:"DATE"},
    { id :"state", numeric: true, disablePadding: false , label:"STATUT "},
    { id :"to_pay", numeric: true, disablePadding: false , label:"PRIX TOTAL "},
    { id :"", numeric: true, disablePadding: false , label:"PAIEMENT "},
    { id :"", numeric: true, disablePadding: false , label:""},
];

class DeliverymanCommandsHead extends React.Component {
    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {order, orderBy} = this.props;

        return (
            <TableHead>
                <TableRow>
                    {rows.map((row, i) => {
                        return (
                            <TableCell
                                key={i}
                                numeric={row.numeric}
                                padding={i == 0 ? "checkbox" : (row.disablePadding ? "none" : "default")}
                                sortDirection={orderBy === row.id ? order : false}
                            >
                                <Tooltip
                                    title="Trier"
                                    placement={row.numeric ? "bottom-end" : "bottom-start"}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === row.id}
                                        direction={order}
                                        onClick={this.createSortHandler(row.id)}
                                    >
                                        {row.label}
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}

DeliverymanCommandsHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = theme => ({
    root: {
        paddingRight: theme.spacing.unit,
    },
    highlight:
        theme.palette.type === "light"
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    spacer: {
        flex: "1 1 100%",
    },
    actions: {
        color: theme.palette.text.secondary,
    },
    title: {
        flex: "0 0 auto",
    },
});
// Button Style //
const buttonstyles = theme => ({

    
    button: {
      margin: theme.spacing.unit,
    },
    leftIcon: {
        marginRight: theme.spacing.unit,
      },
      iconSmall: {
        fontSize: 20,
      },
    input: {
      display: 'none',
    },
    
  });

class DeliverymanCommandsToolbar extends React.Component {

    render() {
        const {classes} = this.props;

        return (
            <Toolbar
                className={classNames(classes.root)}
            >
                <div className={classes.title}>
                    <Typography variant="title" id="tableTitle">
                        Liste des Commandes du livreur
                    </Typography>
                </div>
                <div className={classes.spacer}/>
                <div className={classes.actions}>
                    <div style={{display: "flex", flexDirection: "row"}}>
                        <Tooltip title={this.props.isFilterShowen ? "" : "Filtrer"}>
                            <IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
                                <FilterListIcon/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Rafraîchir">
                            <IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
                                <RefreshIcon/>
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Toolbar>
        );
    }
}

DeliverymanCommandsToolbar.propTypes = {
    classes: PropTypes.object.isRequired
};

DeliverymanCommandsToolbar = withStyles(toolbarStyles)(DeliverymanCommandsToolbar);

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
    },
    table: {
        minWidth: 900,
    },
    tableWrapper: {
        overflowX: "auto",
        paddingLeft: 30,
        paddingRight: 30
    },
    colorSwitchBase: {

    },
    colorBar: {
    },
    colorChecked: {
        color: green[300],
        "&$colorChecked": {
            color: green[500],
            "& + $colorBar": {
                backgroundColor: green[500],
            },
        },
    },
    avatar: {
        width: 30,
        height: 30,
        margin: 10,
    },
    progressContainer: {
        display: "flex",
        marginTop: "30vh",
        flexGrow: 1,
        alignItems: "center",
        justifyContent: "center",
    }
});

class DeliverymanCommands extends React.Component {
    
    constructor() {
        super();
        this.state = {
            filterText: "",
            openFab: true,
            showFilter: false,
            order: "asc",
            orderBy: "name",
            page: 0,
            rowsPerPage: 5,
        };
        this.toggleFilter = this.toggleFilter.bind(this.props);
        this.handleFilterChange = this.handleFilterChange.bind(this.props);
    }

    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = "desc";

        if (this.state.orderBy === property && this.state.order === "desc") {
            order = "asc";
        }
        this.setState({order, orderBy});
    };

    handleChangePage = (event, page) => this.setState({page});

    handleChangeRowsPerPage = event => {
        this.setState({rowsPerPage: event.target.value});
    };

    toggleFilter = () => {
        if (this.state.showFilter) {
            this.setState({showFilter: !this.state.showFilter, filterText: ""});
        } else {
            this.setState({showFilter: !this.state.showFilter});
        }
    };

    handleFilterChange = ({target: {value}}) => this.setState({filterText: value});

    componentWillMount() {
        this.props.loadCommands();
        
        
    }

    componentWillUnmount() {
        window.onscroll = null;
    }

    render() {
        
        const commands = this.props.commands.data;
        const {classes, theme} = this.props;
        const {order, orderBy, rowsPerPage, page} = this.state;
        const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);

        const transitionDuration = {
            enter: theme.transitions.duration.enteringScreen,
            exit: theme.transitions.duration.leavingScreen,
        };
    
        return (
            
            <div>
                <Typography color="textSecondary" variant="headline">
                    
                </Typography>
                {this.props.commands.loading && this.props.commands.data.length == 0 &&
                <div className={classes.progressContainer}>
                    <Fade in timeout={300}>
                        <Typography variant="subheading" color="textSecondary">
                            Chargement...
                        </Typography>
                    </Fade>
                </div>
                }
                {this.props.commands.data.length > 0 &&
                <Paper className={classes.root}>

                    <DeliverymanCommandsToolbar refresh={this.props.loadCommands} toggleFilter={this.toggleFilter}
                                          isFilterShowen={this.state.showFilter}/>
                    <div className={classes.tableWrapper}>
                        {this.state.showFilter &&
                        <div style={{display: "flex", justifyContent: "flex-end"}}>
                            <Zoom in={true}
                                  timeout={200}>
                                <TextField style={{marginRight: 20}}
                                           placeholder={"Chercher..."}
                                           autoFocus
                                           value={this.state.filterText}
                                           onChange={this.handleFilterChange}
                                />
                            </Zoom>
                        </div>
                        }
                          {this.props.commands.data
                                    .sort(getSorting(order, orderBy))
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .filter(item => this.state.filterText.trim() == "" ? true : item.profile.email.indexOf(this.state.filterText) > -1)
                                    .map((command)=>{ 
                                        if( command.deliveryman.id === window.location.search.substring(7))
                                        return (
                        <Table className={classes.table} aria-labelledby="tableTitle">
                            <DeliverymanCommandsHead
                                order={order}
                                orderBy={orderBy}
                                onRequestSort={this.handleRequestSort}
                                rowCount={commands.length}
                            />
                            <TableBody>
                              
                                            <TableRow
                                                role="checkbox"
                                                tabIndex={-1}
                                                key={command.id}
                                                
                                            >
                                                <TableCell component="th" scope="row" padding="checkbox" numeric>
                                                
                                                    <div style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center"
                                                    }}>
                                                       {capitalizeFirstLetter(command.id)}
                                                    </div>
                                                </TableCell>
                                                <TableCell numeric>
                                                {capitalizeFirstLetter(command.pharmacy.name)}
                                                </TableCell>
                                               
                                                <TableCell numeric>
                                                <div  style={{
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        }}>
                                                      {capitalizeFirstLetter(command.client.first_name) +"  "+
                                                      capitalizeFirstLetter(command.client.last_name)}
                                                </div>
                                                    
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="checkbox" numeric>
                                                <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        TextAlign :"left",
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {capitalizeFirstLetter("--------")} 
                                                </div>
                                                </TableCell>
                                                <TableCell numeric>
                                                <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        TextAlign :"left",
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {(command.createdAt)} 
                                                </div>

                                                    
                                                    </TableCell>
                                                <TableCell>
                                                
                                                <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        TextAlign :"left",
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {(command.state)} 
                                                </div>
                                            </TableCell>

                                            <TableCell numeric>
                                            <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        TextAlign :"left",
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {(command.to_pay)} 
                                                </div>
                                                </TableCell>
                                                <TableCell numeric> 
                                                <div style={{
                                                        position : "relative",
                                                        verticalAlign:"middle",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        alignItems: "center",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        TextAlign :"left",
                                                        fontSize: 13,
                                                    }}
                                                    >
                                                       {(command.PAIEMENT)} 
                                                </div>
                                                 </TableCell>



                                                 <TableCell numeric> 
                                                 <IconButton className={classes.button} aria-label="AFFICHER" onClick={() => this.props.history.push(`/posts/${pharmacy._id}`)}> 
                                            
                                            <Eye viewBox="0 0 24 24"  style={{color:"rgba(3,180,115)"}}/>
                                            
                                                    <div  style={{
                                                        position: "relative",
                                                        paddingLeft: 8,
                                                        paddingRight: 16,
                                                        
                                                        verticalAlign: "middle",
                                                       
                                                        fontWeight: 500,
                                                        fontSize: 14,
                                                        
                                                        }}>
                                                        
                                                    AFFICHER
                                                    </div>
                                                 </IconButton>
                                                 </TableCell>
                                            </TableRow>
                                     
                                {emptyRows > 0 && (
                                    <TableRow style={{height: 49 * emptyRows}}>
                                        <TableCell colSpan={6}/>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                           );
                           else return <div style={{padding: "25px",
                            fontSize: "20px",
                            color: "rgb(149, 165, 166)",
                            textAlign: "center",}}>Aucun résultat trouvé</div>
                        })}
                    </div>
                    
                </Paper>
                }
                
            </div>
        );
    }
}

DeliverymanCommands.propTypes = {
    classes: PropTypes.object.isRequired,
};


const mapStateToProps = ({commands})=> ({commands});

const mapDispatchToProps = dispatch => ({
    loadCommands: bindActionCreators(loadCommands, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
export default connect(mapStateToProps, mapDispatchToProps)((withStyles(styles, { withTheme: true })(DeliverymanCommands)));