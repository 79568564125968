import React from 'react';
import Card from '@material-ui/core/Card';
import CardTitle from '@material-ui/core/Card';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { loadCommands, loadPharmacies } from '../../actions';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import DirectionsBike from '@material-ui/icons/DirectionsBike';

import { capitalizeFirstLetter } from '../../tools/utils';
import { Link } from 'react-router-dom';
import Icon from '@material-ui/core/Icon';
import Eye from '@material-ui/icons/RemoveRedEye';
import classNames from 'classnames';
import Avatar from '@material-ui/core/Avatar';
import Zoom from '@material-ui/core/Zoom';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';

import Toolbar from '@material-ui/core/Toolbar';

import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import green from '@material-ui/core/colors/green';
import teal from '@material-ui/core/colors/teal';

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import Badge from '@material-ui/core/Badge';
import Assignment from '@material-ui/icons/Assignment';
import { lighten } from '@material-ui/core/styles/colorManipulator';
import moment from 'moment';
import 'moment/locale/fr';
import NbPharmacies from './NbPharmacies';
import NbClients from './NbClients';
import NbLivreurs from './NbLivreurs';
import NbCommandesEnAttente from './NbCommandesEnAttente';
import NbCommandesEncoursPreparation from './NbCommandesEncoursPreparation';
import NbCommandesEncoursLivraison from './NbCommandesEncoursLivraison';
import NbCommandesLivree from './NbCommandesLivree';
import NbCommandesAnnule from './NbCommandesAnnule';
import NbCommandes from './NbCommandes';
import PrixCommandeValidePayPal from './PrixCommandeValidePayPal';
import PrixCommandeValideCB from './PrixCommandeValideCB';
import PrixCommandeValidee from './PrixCommandeValidee';
import PrixCommandeAnnuleePayPal from './PrixCommandeAnnuleePayPal';
import PrixCommandeAnnuleeCB from './PrixCommandeAnnuleeCB';
import PrixCommandeAnnulee from './PrixCommandeAnnulee';
import Excel from './../Excel';
import ResponsiveTable from 'material-ui-next-responsive-table';
import Hidden from '@material-ui/core/Hidden';
import LocalShipping from '@material-ui/icons/LocalShipping';
import { graphql, compose } from 'react-apollo';
import gql from 'graphql-tag';
const stylesIcon = {
	card: { borderLeft: 'solid 4px rgb(40, 172, 179)', flex: 1 },
	icon: { float: 'right', width: 64, height: 64, padding: 16, color: 'rgb(40, 172, 179)' }
};
const style = theme => ({
	container: {
		flexGrow: 1
	},
	card: {
		marginTop: theme.spacing.unit * 3,
		padding: theme.spacing.unit * 3
	}
});

function desc(a, b, orderBy) {
	if (orderBy == 'pharmacy') {
		let aPharmacy = a.pharmacy.name;
		let bPharmacy = b.pharmacy.name;
		if (bPharmacy < aPharmacy) {
			return -1;
		}
		if (bPharmacy > aPharmacy) {
			return 1;
		}
		return 0;
	} else if (orderBy == 'client') {
		let aClient = a.client.last_name + ' ' + a.client.first_name;
		let bClient = b.client.last_name + ' ' + b.client.first_name;
		if (bClient < aClient) {
			return -1;
		}
		if (bClient > aClient) {
			return 1;
		}
		return 0;
	} else if (orderBy == 'delivery_mode') {
		let aDeliveryMode =
			a.delivery_mode == 'FREE' ? 'Click & Collect' : a.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		let bDeliveryMode =
			b.delivery_mode == 'FREE' ? 'Click & Collect' : b.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		if (bDeliveryMode < aDeliveryMode) {
			return -1;
		}
		if (bDeliveryMode > aDeliveryMode) {
			return 1;
		}
		return 0;
	} else {
		if (b[orderBy] < a[orderBy]) {
			return -1;
		}
		if (b[orderBy] > a[orderBy]) {
			return 1;
		}
		return 0;
	}
}

function getSorting(order, orderBy) {
	return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}
function getCmdState(state) {
	let st = '';
	switch (state) {
		case 'PENDING':
			st = 'En Attente';
			break;
		case 'REVOKED':
			st = 'Annulée';
			break;
		case 'APPROVED':
			st = 'Acceptée';
			break;
		case 'TO_PAY':
			st = 'Attente de paiement';
			break;
		case 'PAID':
			st = 'Payée';
			break;
		case 'DELIVERING':
			st = 'En cours de livraison';
			break;
		case 'DELIVERED':
			st = 'Livrée';
			break;
		case 'CANCELED':
			st = 'Annulée';
			break;
		case 'VOIDED':
			st = '--';
			break;
	}
	return st;
}
const rows = [
	{ id: 'num', numeric: false, disablePadding: true, label: 'N° DE COMMANDE' },
	{ id: 'createdAt', numeric: false, disablePadding: false, label: 'DATE' },
	{ id: 'state', numeric: false, disablePadding: false, label: 'STATUT ' },
	{ id: 'pharmacy', numeric: false, disablePadding: false, label: 'PHARMACIE' },
	{ id: 'client', numeric: false, disablePadding: false, label: 'PATIENT' },
	{ id: '', numeric: false, disablePadding: false, label: 'MODE DE LIVRAISON' },
	{ id: '', numeric: false, disablePadding: false, label: '' }
];
const columns = props => [
	{
		label: 'prim',
		primary: 'primary',
		key: 'id',
		render: id => {
			let command = {};
			if (props.data && props.data.getCommands)
				props.data.getCommands.forEach(cmd => {
					if (cmd.id == id) {
						return (command = cmd);
					}
				});

			return (
				<span style={{ fontSize: 13 }}>
					{command.num +
						'-' +
						(command.createdAt ? moment(command.createdAt).format('LL') : '') +
						' - ' +
						(getCmdState(command.state) +
							' - ' +
							(command.delivery_mode == 'FREE'
								? 'Click & Collect'
								: command.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée'))}
				</span>
			);
		}
	},
	{ label: 'N° DE COMMANDE', key: 'num' },
	{
		label: 'DATE',
		key: 'createdAt',
		render: createdAt => {
			return createdAt ? moment(createdAt).format('LLL') : '';
		}
	},
	{
		label: 'STATUT ',
		key: 'state',
		render: state => {
			return getCmdState(state);
		}
	},
	{
		label: 'PHARMACIE',
		key: 'pharmacy',
		render: value => {
			return (
				<div
					style={{
						textTransform: 'uppercase',
						color: '#28acb3',
						fontWeight: 'bold',
						textAlign: 'left',
						fontFamily: 'Montserrat, sans-serif',
						cursor: 'pointer'
					}}
					onClick={() => props.history.push(`/pharmacies/content?id=${value.id}`)}
				>
					<a>{capitalizeFirstLetter(value.name)}</a>
				</div>
			);
		}
	},
	{
		label: 'PATIENT',
		key: 'client',
		render: value => {
			return (
				<div
					style={{
						textTransform: 'uppercase',
						color: '#28acb3',
						fontWeight: 'bold',
						textAlign: 'left',
						fontFamily: 'Montserrat, sans-serif',
						cursor: 'pointer'
					}}
					onClick={() => props.history.push(`/admins/content?id=${value.id}`)}
				>
					{capitalizeFirstLetter(value.last_name) + '  ' + capitalizeFirstLetter(value.first_name)}
				</div>
			);
		}
	},
	{
		label: 'DATE DE LIVRAISON',
		key: 'delivery_date',
		render: delivery_date => {
			return delivery_date ? moment(delivery_date).format('LLL') : '';
		}
	},
	{
		label: 'MODE DE LIVRAISON',
		key: 'delivery_mode',
		render: delivery_mode => {
			return delivery_mode == 'FREE' ? 'Click & Collect' : delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée';
		}
	},
	{
		label: '',
		key: 'id',
		render: value => {
			let command = {};
			if (props.getCommands && props.data.getCommands)
				props.data.getCommands.forEach(cmd => {
					if (cmd.id == value) {
						return (command = cmd);
					}
				});
		
			return (
				<div style={{ display: 'flex' }} className="eye">
					<Tooltip title="AFFICHER">
						<IconButton
							className={props.classes.button}
							aria-label="AFFICHER"
							onClick={() => props.history.push(`/commands/content?id=${value}`)}
						>
							<Eye style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
						</IconButton>
					</Tooltip>
					{command.tracking_url ? (
						<Tooltip title="TRACKING">
							<IconButton
								className={props.classes.button}
								aria-label="TRACKING"
								onClick={() => window.open(command.tracking_url, '_blank')}
							>
								<DirectionsBike style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
							</IconButton>
						</Tooltip>
					) : (
						''
					)}
				</div>
			);
		}
	}
];
const stylesWelcome = {
	welcome: { marginBottom: '1em', fontFamily: 'Montserrat, sans-serif' },
	button: {
		width: 300,
		margin: '1.5%',
		backgroundImage: 'linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)',
		color: 'rgb(255, 255, 255)'
	},
	flex: { display: 'flex', marginBottom: '2em' },
	leftCol: { flex: 1, marginRight: '1em', display: 'block', cursor: 'pointer' },
	rightCol: { flex: 1, marginLeft: '1em', cursor: 'pointer' },
	singleCol: { flex: 1, cursor: 'pointer' }
};
class EnhancedTableHead extends React.Component {
	createSortHandler = property => event => {
		this.props.onRequestSort(event, property);
	};

	render() {
		const { order, orderBy, classes } = this.props;

		return (
			<TableHead>
				<TableRow>
					{rows.map((row, i) => {
						return (
							<TableCell
								key={i}
								numeric={row.numeric}
								style={{ textAlign: 'left', fontFamily: 'Montserrat, sans-serif' }}
								padding={i == 0 ? 'checkbox' : row.disablePadding ? 'none' : 'default'}
								sortDirection={orderBy === row.id ? order : false}
							>
								<Tooltip title="Trier" placement={row.numeric ? 'bottom-end' : 'bottom-start'} enterDelay={300}>
									<TableSortLabel
										style={{ flexDirection: 'column', fontSize: '15px', fontWeight: 'bold' }}
										active={orderBy === row.id}
										direction={order}
										onClick={this.createSortHandler(row.id)}
									>
										<Typography variant="subheading" color="textSecondary" className={classes.tableHead}>
											{' '}
											{row.label}
										</Typography>
									</TableSortLabel>
								</Tooltip>
							</TableCell>
						);
					}, this)}
				</TableRow>
			</TableHead>
		);
	}
}

EnhancedTableHead.propTypes = {
	onRequestSort: PropTypes.func.isRequired,
	order: PropTypes.string.isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

const toolbarStyles = theme => ({
	root: {
		paddingRight: theme.spacing.unit
	},

	highlight:
		theme.palette.type === 'light'
			? {
					color: theme.palette.secondary.main,
					backgroundColor: lighten(theme.palette.secondary.light, 0.85)
				}
			: {
					color: theme.palette.text.primary,
					backgroundColor: theme.palette.secondary.dark
				},
	spacer: {
		flex: '1 1 100%'
	},
	actions: {
		color: theme.palette.text.secondary
	},
	title: {
		flex: '0 0 auto'
	}
});
// Button Style //
const buttonstyles = theme => ({
	button: {
		margin: theme.spacing.unit
	},
	leftIcon: {
		marginRight: theme.spacing.unit
	},
	iconSmall: {
		fontSize: 20
	},
	input: {
		display: 'none'
	}
});

class EnhancedTableToolbar extends React.Component {
	render() {
		const { classes, value } = this.props;
	
		return (
			<Toolbar className={classNames(classes.root)}>
				<div className={classes.title}>
					<Typography variant="subheading" color="textSecondary">
						{value == 0 ? 'Commandes Express' : value == 1 ? 'Commandes Click & Collect' : 'Commandes Fin de Journée'}
					</Typography>
				</div>
				<div className={classes.spacer} />
				<div className={classes.actions}>
					<div style={{ display: 'flex', flexDirection: 'row' }}>
						<Excel {...this.props.excelProps} />
						<Tooltip title={this.props.isFilterShowen ? '' : 'Filtrer'}>
							<IconButton aria-label="filter" onClick={() => this.props.toggleFilter()}>
								<FilterListIcon />
							</IconButton>
						</Tooltip>
						<Tooltip title="Rafraîchir">
							<IconButton aria-label="refresh" onClick={() => this.props.refresh()}>
								<RefreshIcon />
							</IconButton>
						</Tooltip>
					</div>
				</div>
			</Toolbar>
		);
	}
}

EnhancedTableToolbar.propTypes = {
	classes: PropTypes.object.isRequired
};

EnhancedTableToolbar = withStyles(toolbarStyles)(EnhancedTableToolbar);

const styles = theme => ({
	root: {
		width: '100%',
		marginTop: theme.spacing.unit * 3
	},
	firstTitle: {
		fontSize: 24,
		lineHeight: 2,
		display: 'block',
		textTransform: 'uppercase',
		fontFamily: 'Montserrat, sans-serif'
	},

	secondTitle: {
		fontSize: 16,
		lineHeight: 2,
		display: 'block',
		textTransform: 'uppercase',
		fontFamily: 'Montserrat, sans-serif'
	},

	boxFlex: {
		display: 'flex',
		marginBottom: '2em'
	},

	'@media screen and (max-width: 991px)': {
		firstTitle: {
			fontSize: 22,
			textAlign: 'center'
		},
		boxFlex: {
			display: 'flex',
			flexDirection: 'column'
		},
		boxFlex1: {
			margin: '0 !important',
			marginBottom: '2em !important'
		}
	},
	table: {
		minWidth: 900
	},
	tableWrapper: {
		overflowX: 'auto',
		paddingLeft: 30,
		paddingRight: 30
	},
	rou: {
		textAlign: 'left'
	},
	colorSwitchBase: {},
	colorBar: {},
	colorChecked: {
		color: green[300],
		'&$colorChecked': {
			color: green[500],
			'& + $colorBar': {
				backgroundColor: green[500]
			}
		}
	},
	avatar: {
		width: 30,
		height: 30,
		margin: 10
	},
	progressContainer: {
		display: 'flex',
		marginTop: '30vh',
		flexGrow: 1,
		alignItems: 'center',
		justifyContent: 'center'
	},
	badge: {
		top: -9,
		right: -11,
		color: `white`,
		fontSize: 10,
		fontWeight: 800,
		width: 17,
		height: 17,

		// The border color match the background color.
		border: `2px solid white`,
		backgroundColor: teal[400]
	},
	root2: {
		flexGrow: 1,
		backgroundColor: '#00BFA6'
	},
	tabsRoot: {
		backgroundColor: '#00BFA6',
		borderBottom: '1px solid #00BFA6'
	},
	tabsIndicator: {
		backgroundColor: 'white'
	},
	tabRoot: {
		backgroundColor: 'rgb(40, 172, 179)',
		//"backgroundImage": "linear-gradient(-34deg, rgba(24, 164, 175, 0.93) 0%, rgb(39, 200, 156) 100%)",
		textTransform: 'initial',
		minWidth: 72,
		fontWeight: theme.typography.fontWeightRegular,
		marginRight: theme.spacing.unit * 4,
		fontFamily: [
			'-apple-system',
			'BlinkMacSystemFont',
			'"Segoe UI"',
			'Roboto',
			'"Helvetica Neue"',
			'Arial',
			'sans-serif',
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"'
		]
	},
	tabSelected: {},
	typography: {
		padding: theme.spacing.unit * 3
	},
	button: {
		width: 30,
		height: 30
	},
	tableCell: {
		fontSize: '13px !important'
	},
	tableHead: {
		fontSize: '14px !important'
	},
	'@media screen and  (min-width: 991px) and (max-width: 1390px)': {
		tableCell: {
			fontSize: '12px !important'
		},
		tableHead: {
			fontSize: '12px !important'
		}
	}
});
function TabContainer(props) {
	return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
	children: PropTypes.node.isRequired
};
const LOAD_COMMANDS_QUERY = gql`
	query {
		getCommands {
			id
			num
			to_pay
			delivery_date
			address
			second_address
			pharmacy {
				id
				num
				name
				address
			}
			client {
				id
				num
				first_name
				last_name
				address
				tel
			}
			createdAt
			state
			delivery_mode
			tracking_url
		}
	}
`;
class TabsContentComp extends React.Component {
	constructor() {
		super();
		this.state = {
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: window.localStorage.getItem('admin_command_dash_rows_per_page')
				? parseInt(window.localStorage.getItem('admin_command_dash_rows_per_page'))
				: 25,
			value: 0
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
	}
	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		window.localStorage.setItem('admin_command_dash_rows_per_page', event.target.value);
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}
	handleChange = (event, value) => {
		this.setState({ value });
	};

	render() {
		let commands = this.props.data && this.props.data.getCommands ? this.props.data.getCommands : [];
		let excelProps = {
			columns: [
				{ label: 'Numéro de commande', value: 'num' },
				{ label: 'Date de commande', value: 'date' },
				{ label: 'Mode de livraison', value: 'delivery_mode' },
				{ label: 'Date de livraison', value: 'delivery_date' },
				{ label: 'Statut', value: 'state' },
				{ label: 'Num Pharmacie', value: 'pharmacy_num' },
				{ label: 'Pharmacie', value: 'pharmacy' },
				{ label: 'Num Patient', value: 'patient_num' },
				{ label: 'Patient', value: 'patient' },
				{ label: 'Reste à Charge', value: 'to_pay' }
			],
			dataSets: [],
			sheetName: 'Commandes'
		};
		const { classes, theme, value } = this.props;
		const { order, orderBy, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);
		const status = '';
		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		commands = commands
			.filter(item => {
				let fullname = item.client.last_name + ' ' + item.client.first_name;
				fullname = fullname.toLowerCase();
				let filter = this.state.filterText.toLowerCase();
				return this.state.filterText.trim() == ''
					? true
					: fullname.indexOf(filter) > -1 ||
							item.pharmacy.name.toLowerCase().indexOf(filter) > -1 ||
							item.num.indexOf(this.state.filterText) > -1;
			})
			.filter(item => moment().format('MM-DD-YYYY') == moment(item.createdAt).format('MM-DD-YYYY'))
			.filter(
				item =>
					value == 0
						? item.delivery_mode == 'EXPRESS'
						: value == 1 ? item.delivery_mode == 'FREE' : item.delivery_mode == 'IN_DAY'
			);
		commands.forEach(element => {
			let command = {
				num: element.num,
				date: moment(element.createdAt).format('LLL'),
				delivery_mode:
					element.delivery_mode == 'FREE'
						? 'Click & Collect'
						: element.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée',
				delivery_date: element.delivery_date ? moment(element.delivery_date).format('LLL') : '',
				state: getCmdState(element.state),
				pharmacy_num: element.pharmacy.num,
				pharmacy: element.pharmacy.name,
				patient_num: element.client.num,
				patient: element.client.last_name + ' ' + element.client.first_name,
				to_pay: element.to_pay ? element.to_pay : ''
			};
			excelProps.dataSets.push(command);
		});

		return (
			<div>
				<Typography color="textSecondary" variant="headline" />
				{this.props.data.loading && (
					<div className={classes.progressContainer}>
						<Fade in timeout={300}>
							<Typography variant="subheading" color="textSecondary">
								Chargement...
							</Typography>
						</Fade>
					</div>
				)}
				{
					<div>
						<EnhancedTableToolbar
							refresh={this.props.data.refetch}
							toggleFilter={this.toggleFilter}
							value={value}
							isFilterShowen={this.state.showFilter}
							excelProps={excelProps}
						/>
						<div className={classes.tableWrapper}>
							{this.state.showFilter && (
								<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
									<Zoom in={true} timeout={200}>
										<TextField
											style={{ marginRight: 20 }}
											placeholder={'Chercher...'}
											autoFocus
											value={this.state.filterText}
											onChange={this.handleFilterChange}
										/>
									</Zoom>
								</div>
							)}
							<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
								<Table className={classes.table} aria-labelledby="tableTitle">
									<EnhancedTableHead
										order={order}
										orderBy={orderBy}
										onRequestSort={this.handleRequestSort}
										rowCount={commands.length}
										classes={classes}
									/>
									<TableBody>
										{commands
											.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
											.sort(getSorting(order, orderBy))
											.map((command, i) => {
												return (
													<TableRow role="checkbox" tabIndex={-1} key={command.id}>
														<TableCell component="th" scope="row">
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	cursor: 'pointer',
																	marginLeft: -10
																}}
																onClick={() => this.props.history.push(`/commands/content?id=${command.id}`)}
																className={classes.tableCell}
															>
																{command.num}
															</div>
														</TableCell>
														<TableCell>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	paddingLeft: 8,
																	paddingRight: 16,
																	TextAlign: 'left',
																	marginLeft: -10
																}}
																className={classes.tableCell}
															>
																{moment(command.createdAt).format('LLL')}
															</div>
														</TableCell>
														<TableCell>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	paddingLeft: 8,
																	paddingRight: 16,
																	TextAlign: 'left',
																	marginLeft: -10
																}}
																className={classes.tableCell}
															>
																{getCmdState(command.state)}
															</div>
														</TableCell>

														<TableCell
															style={{ textAlign: 'left', fontFamily: 'Montserrat, sans-serif', cursor: 'pointer' }}
															className={classes.tableCell}
															style={{ marginLeft: -10 }}
														>
															<a
																onClick={() => this.props.history.push(`/pharmacies/content?id=${command.pharmacy.id}`)}
															>
																{capitalizeFirstLetter(command.pharmacy.name)}
															</a>
														</TableCell>

														<TableCell
															style={{ textAlign: 'left', fontSize: '15', fontFamily: 'Montserrat, sans-serif' }}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	cursor: 'pointer'
																}}
																onClick={() => this.props.history.push(`/admins/content?id=${command.client.id}`)}
																className={classes.tableCell}
															>
																{capitalizeFirstLetter(command.client.last_name) +
																	'  ' +
																	capitalizeFirstLetter(command.client.first_name)}
															</div>
														</TableCell>

														<TableCell style={{ textAlign: 'left' }}>
															<div
																style={{
																	position: 'relative',
																	verticalAlign: 'middle',
																	display: 'flex',
																	flexDirection: 'row',
																	alignItems: 'center',
																	paddingLeft: 8,
																	paddingRight: 16,
																	TextAlign: 'left',
																	marginLeft: -10
																}}
																className={classes.tableCell}
															>
																{command.delivery_mode == 'FREE'
																	? 'Click & Collect'
																	: command.delivery_mode == 'EXPRESS' ? 'Express' : 'Fin de Journée'}
															</div>
														</TableCell>

														<TableCell className={classes.tableCell} style={{ padding: 'unset' }}>
															<div style={{ display: 'flex' }}>
																<Tooltip title="AFFICHER">
																	<IconButton
																		className={classes.button}
																		aria-label="AFFICHER"
																		onClick={() => this.props.history.push(`/commands/content?id=${command.id}`)}
																	>
																		<Eye style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
																	</IconButton>
																</Tooltip>
																{command.tracking_url ? (
																	<Tooltip title="TRACKING">
																		<IconButton
																			className={classes.button}
																			aria-label="TRACKING"
																			onClick={() => window.open(command.tracking_url, '_blank')}
																		>
																			<DirectionsBike style={{ color: '#2cacb3' }} viewBox="0 0 24 24" />
																		</IconButton>
																	</Tooltip>
																) : (
																	''
																)}
															</div>
														</TableCell>
													</TableRow>
												);
											})}
										{emptyRows > 0 && (
											<TableRow style={{ height: 49 * emptyRows }}>
												<TableCell colSpan={6} />
											</TableRow>
										)}
									</TableBody>
								</Table>
							</Hidden>
							<Hidden only={['lg', 'xl']} implementation={'js'}>
								<ResponsiveTable
									columns={columns(this.props)}
									data={commands.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
									showPagination={true}
									count={commands.length}
									rowsPerPage={rowsPerPage}
									page={page}
									excludePrimaryFromDetails={true}
									onChangePage={this.handleChangePage}
									onChangeRowsPerPage={this.handleChangeRowsPerPage}
								/>
							</Hidden>
						</div>
						<Hidden only={['xs', 'sm', 'md']} implementation={'js'}>
							<TablePagination
								component="div"
								count={commands.length}
								rowsPerPage={rowsPerPage}
								page={page}
								backIconButtonProps={{
									'aria-label': 'Previous Page'
								}}
								nextIconButtonProps={{
									'aria-label': 'Next Page'
								}}
								onChangePage={this.handleChangePage}
								onChangeRowsPerPage={this.handleChangeRowsPerPage}
							/>
						</Hidden>
					</div>
				}
			</div>
		);
	}
}

class Dashboard extends React.Component {
	constructor() {
		super();
		this.state = {
			filterText: '',
			openFab: true,
			showFilter: false,
			order: 'asc',
			orderBy: 'name',
			page: 0,
			rowsPerPage: 5,
			value: 0
		};
		this.toggleFilter = this.toggleFilter.bind(this.props);
		this.handleFilterChange = this.handleFilterChange.bind(this.props);
	}

	handleRequestSort = (event, property) => {
		const orderBy = property;
		let order = 'desc';

		if (this.state.orderBy === property && this.state.order === 'desc') {
			order = 'asc';
		}
		this.setState({ order, orderBy });
	};

	handleChangePage = (event, page) => this.setState({ page });

	handleChangeRowsPerPage = event => {
		this.setState({ rowsPerPage: event.target.value });
	};

	toggleFilter = () => {
		if (this.state.showFilter) {
			this.setState({ showFilter: !this.state.showFilter, filterText: '' });
		} else {
			this.setState({ showFilter: !this.state.showFilter });
		}
	};

	handleFilterChange = ({ target: { value } }) => this.setState({ filterText: value });

	componentWillMount() {}

	componentWillUnmount() {
		window.onscroll = null;
	}
	handleChange = (event, value) => {
		this.setState({ value });
	
	};

	render() {

		const commands = this.props.data && this.props.data.getCommands ? this.props.data.getCommands : [];
		const { classes, theme } = this.props;
		const { order, orderBy, rowsPerPage, page } = this.state;
		const emptyRows = rowsPerPage - Math.min(rowsPerPage, [].length - page * rowsPerPage);
		const status = '';
		const transitionDuration = {
			enter: theme.transitions.duration.enteringScreen,
			exit: theme.transitions.duration.leavingScreen
		};
		const { value } = this.state;
		return (
			<div>
				<Card style={stylesWelcome.welcome} />

				<div style={{ padding: 16, position: 'relative' }}>
					<div className={classNames(classes.firstTitle)}> Statistiques du jour</div>
				</div>

				<div className={classNames(classes.boxFlex)}>
					<div
						style={stylesWelcome.leftCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => this.props.history.push('/pharmacies')}
					>
						<NbPharmacies />
					</div>
					<div
						style={stylesWelcome.singleCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => this.props.history.push('/admins')}
					>
						<NbClients />
					</div>
					<div
						style={stylesWelcome.rightCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => {
							this.props.history.push({
								pathname: '/commands',
								state: { type: 'all', toDay: true }
							});
						}}
					>
						<NbCommandes type="all" toDay={true} />
					</div>
				</div>

				<div className={classNames(classes.boxFlex)}>
					<div
						style={stylesWelcome.leftCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => {
							this.props.history.push({
								pathname: '/commands',
								state: { type: 'EXPRESS', toDay: true }
							});
						}}
					>
						<NbCommandes type="EXPRESS" toDay={true} />
					</div>
					<div
						style={stylesWelcome.singleCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => {
							this.props.history.push({
								pathname: '/commands',
								state: { type: 'FREE', toDay: true }
							});
						}}
					>
						<NbCommandes type="FREE" toDay={true} />
					</div>
					<div
						style={stylesWelcome.rightCol}
						className={classNames(classes.boxFlex1)}
						onClick={() => {
							this.props.history.push({
								pathname: '/commands',
								state: { type: 'IN_DAY', toDay: true }
							});
						}}
					>
						<NbCommandes type="IN_DAY" toDay={true} />
					</div>
				</div>

				<div>
					<Paper className={classes.root}>
						<AppBar position="static" classes={{ root: classes.tabRoot }}>
							<Tabs value={value} onChange={this.handleChange}>
								<Tab label="EXPRESS" />
								<Tab label="Click & Collect" />
								<Tab label="Fin de Journée" />
							</Tabs>
						</AppBar>
					</Paper>
					<div>
						<TabContainer>
							<TabsContentComp {...this.props} value={this.state.value} />
						</TabContainer>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = ({ commands }) => ({ commands });

const mapDispatchToProps = dispatch => ({
	loadCommands: bindActionCreators(loadCommands, dispatch)
});

/*export default connect(mapStateToProps, mapDispatchToProps)(withStyles(style)(Companies));*/
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
//   )(withRouter(withStyles(styles, { withTheme: true })(Dashboard)));

export default compose(graphql(LOAD_COMMANDS_QUERY))(withStyles(styles, { withTheme: true })(Dashboard));
